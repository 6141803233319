import React from "react";

const withHandleForm = (Component) => {
    return (props) => {
        const [form, setForm] = React.useState({});
        const handleChange = (value, name) => {
            setForm({ ...form, [name]: value });
        };
        return <Component form={form} handleChange={handleChange}  {...props} />
    }
}
export default withHandleForm