import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import users from "../redux/reducers/userSlice"
import entreesSlice from "./reducers/entreesSlice";
import categoryEntreeSlice from "./reducers/categoryEntreeSlice";
import comptableSlice from "./reducers/comptableSlice";


const persistConfig = {
    key: 'root',
    storage,
    
}


const rootReducer = combineReducers({
    users,
    entreesSlice,
    categoryEntreeSlice,
    comptableSlice
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
    reducer: persistedReducer,
    
})

export const persistor = persistStore(store)

