import React, {useState} from 'react'
import { useForm } from 'react-hook-form';
import logo_fewnu from '../../img/unnamed 1.png'
import { LOGIN_PATH } from 'utils/navigationPaths';
import { Link } from 'react-router-dom';
import imagePass from '../../img/forgetPasseword.png';

function ForgetPassword() {
  const { register, formState: { errors } } = useForm()
  const [isLoading] = useState(false);

  return (
    <div className="login-page m-0 p-0">
    <div className="">
      <div className="row justify-content-center">
        <div className="row  justify-content-center">
          <img className="image-fewnu-login" src={logo_fewnu} alt="Fewnu"/>
        </div>
        <div className="row text-center">
          <hr className="login-hr" />
          <p className="text-white font-Montserrat text-uperCase treso">
           Fewnu Trésorerie 
          </p>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className='col-md-4'>
          <img  src={imagePass} alt='imageForgetPassword'/>
        </div>
        <div className='col-md-4'>
        <form
          // onSubmit={handleSubmit(verificate)}
        >
        <p className="font-Montserrat" style={{color:'#fff', fontFamily:'700'}}>
        Mot de passe oublié?
        </p>
        <span className='' style={{textAlign:'center', color:'#fff', fontFamily:'700'}}>
        Pour réinitialiser votre mot de passe entrez votre email
        </span>
        <div className="mt-4" style={{}}>
        <input
                    {...register("phone", {
                      required: "Veuillez saisir votre email",
                    })}
                    type="tel"
                    placeholder="E-mail ou télephone"
                    className={"login-input form-control mt-1 px-2"}
                  />
                  {errors.email && (
                    <p className="custom-color-danger">
                      {/* <FiAlertTriangle /> {errors.email.message} */}
                    </p>
                  )}
    </div>
  
    <p className="text-end mt-3">
                    <Link
                      className="text-end text-white custom-mouse-clickable text-decoration-none"
                      to={LOGIN_PATH}
                    >
                      connectez-vous
                    </Link>
                  </p>
            <button
              disabled={isLoading}
              type="submit"
              className="login-btn row m-0 p-0 mt-3 align-items-center font-Montserrat"
            >
              <span className="col-2"></span>
              <span className="col-8">Envoyer</span>
              <span className="col-2">
                <div
                  style={{ width: "20px", height: "20px" }}
                  className={
                    isLoading ? "spinner-border fs-6 mt-1" : "d-none"
                  }
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              </span>
            </button>
        </form>
        </div>
        {/* <p className='text-white d-block d-md-none mt-2'>
                      Pas de compte? <Link className='text-white fw-bold text-decoration-none' to="/register" >Inscrivez-vous!</Link>
                  </p> */}
      </div>
    </div>
  </div>
  )
}

export default ForgetPassword