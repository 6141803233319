import React, { useState } from 'react'
import AddCatEntree from './AddCatEntree'
import { AiFillPlusCircle } from 'react-icons/ai';
import { useEffect } from 'react';
import ApiController from 'redux/apiController';
import { useDispatch, useSelector } from 'react-redux';
import CustomBootstrapTable from 'components/tables/CustomBootstrapTable';
import { useForm } from 'react-hook-form';
import { setCurrentCategoryEntreeSlice } from 'redux/reducers/categoryEntreeSlice';
import { toast } from 'react-toastify';
import { RiDeleteBinLine } from 'react-icons/ri';
import DeleteCategoryEntree from './DeleteCategoryEntree';

const CategoryEntree = () => {
  // const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch()
  const currentCatEntree = useSelector(state => state?.categoryEntreeSlice?.currentCategoryEntree)

  const { register, handleSubmit, setValue } = useForm();
  const currentUser = useSelector(state => state.users?.currentUser)
  const [showDeleteModal, setShowDeleteModal] = useState(false)


  const listCategoryEntree = useSelector(state => state?.categoryEntreeSlice?.categoryEntreesList);


  // modification //////////////////////////////////////////////
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedColumn, setSelectedColumn] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const openRowForm = (row, column) => {

    setSelectedRow(row);
    setSelectedColumn(column);
    setValue('name', row.name);
    setValue('type_transaction', row.type_transaction);
    setValue('description', row.description);
    dispatch(setCurrentCategoryEntreeSlice(row))

    setEditMode(true);

    // console.log(row)
  };


  useEffect(() => {
    ApiController.categoryEntreesController.getCategoryEntrees(currentUser?.id)
      .then(() => { })
    .catch((e) => console.log(e))
  }, [])

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const openDeleteModal = (item) => {
    dispatch(setCurrentCategoryEntreeSlice(item))
    setShowDeleteModal(true)
  }

  const handleUpdate = (data) => {
    ApiController.categoryEntreesController.updateCategoryEntree(currentCatEntree?.id, data, currentUser.id).then(() => {
      // console.log(res)
      toast.success("modification reuissi !")
    }).catch((e) => console.log(e))
  }

  const refreshCategorieList = () => {
    ApiController.categoryEntreesController.getCategoryEntrees()
      .then(() => {
        // console.log(res);
      })
      .catch((err) => console.log(err));
  };

  const type_transaction = [
    { id: "entrees", value: "entrees" },
    { id: "sorties", value: "sorties" }
  ];

  const columns = [
    {
      dataField: "name",
      text: "Nom category",
      headerClasses: 'custom-table-column',
      formatter: (cell, row) => {
        return (
          <div>

            {
              selectedRow === row && selectedColumn === "name" && editMode ? (
                <form onSubmit={handleSubmit(handleUpdate)}>
                  <input
                    type="text"
                    {...register("name")}

                  />
                  
                  {/* <button type="submit">Enregistrer</button> */}
                </form>
              ) : <span onClick={() => openRowForm(row, 'name')} style={{ cursor: 'pointer' }}>{row.name}</span>
            }
          </div>
        );
      }
    },
    {
      dataField: "type_transaction",
      text: "Type",
      headerClasses: 'custom-table-column',
      formatter: (cell, row) => {
        return (
          <div>
            {selectedRow === row && selectedColumn === "type_transaction" && editMode ? (
              <form onSubmit={handleSubmit(handleUpdate)}>
                <select {...register("type_transaction")} onBlur={handleSubmit(handleUpdate)}>
                {type_transaction && type_transaction.length > 0 ? (
                  type_transaction.map((option) => (
                    <option key={option.id} value={option.id}>{option.value}</option>
                  ))
                ) : (
                  <option value="">Aucune option de transactions disponible</option>
                )}

                </select>
                {/* <button type="submit">Enregistrer</button> */}
              </form>
            ) : (
              <span onClick={() => openRowForm(row, "type_transaction" )} style={{ cursor: 'pointer'}}>{row.type_transaction}</span>
            )}
          </div>

        );
      }

    },
    // {
    //   dataField: "description",
    //   text: "Description",
    //   headerClasses: 'custom-table-column',
    //   formatter: (cell, row) => {
    //     return (
    //       <div>
    //         {
    //           selectedRow === row && selectedColumn === "description" && editMode ? (
    //             <form onBlur={handleSubmit(handleUpdate)}>

    //               <textarea
    //                 {...register("description")}
    //               ></textarea>
    //               {/* <button type="submit">Enregistrer</button> */}
    //             </form>
    //           ) : <span onClick={() => openRowForm(row, 'description')} style={{ cursor: 'pointer' }}> {row.description ? row.description : "Pas de description pour ce category"}</span>

    //         }
    //       </div>
    //     )
    //   }
    // },

    {
      dataField: "action",
      text: "Actions",
      headerClasses: 'custom-table-column col-1',
      formatter: (cell, row) => {
        return (
          <>
            <div className="d-flex align-items-center">


              <RiDeleteBinLine className="" size={17}
                style={{ cursor: 'pointer' }}
                onClick={() => openDeleteModal(row)}
              /> {/* Icône de suppression */}

            </div>
          </>
        );
      },
    },
  ];

  return (
    <>

      <AddCatEntree showModal={showModal} closeModal={closeModal} refreshCategorieList={refreshCategorieList}/>
      <DeleteCategoryEntree showModal={showDeleteModal} setShowModal={setShowDeleteModal} />
      <div className="row rowEntree">
        <div className="col-12">
          <h4 className='entreeTitle'>Catégories </h4>
          <p className='entreeDescription'>les categories de transactions</p>
        </div>
      </div>
      <div className="btns-entree" style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <div className="mt-2">
          <button
            className="ajoutEntree btn text-light"
            onClick={openModal}
          >
            <AiFillPlusCircle className="fs-4" /> Ajouter une catégorie
          </button>
        </div>
      </div>

      <div className="mt-5">
        {
          listCategoryEntree?.length ? (
            <>
              <CustomBootstrapTable
                data={listCategoryEntree}
                columns={columns}
                showPaginator={true}
              />
            </>
          ) : <p>Pas de category entree sortie disponible</p>
        }
      </div>
    </>
  )
}

export default CategoryEntree