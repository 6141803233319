import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

const LinkItem = styled(NavLink)`
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    margin-bottom: 5px;
    height: 40px;
    overflow: hidden;
    textDecoration: none;
    color: ${({ color }) => color};
    &:hover{
        color: ${({ coloronhover  }) => coloronhover };
    }
    &.active{
        color: white;
        border-radius: ${({ border }) => border};
        background-color: ${({ coloronactive }) => coloronactive}


    };
`

const SidebarItem = ({ linkItem }) => {
    const { path, label } = linkItem
    return (
        <LinkItem
            color={'rgba(0,0,0,0.5)'}
            coloronhover={'rgba(0, 190, 201, 1)'}
            border={'5.85429px'}
            coloronactive={'#00BEC9'}
            to={path}
        >
            <div
                style={{
                    height: 35,
                    width: 40,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: '5.85429px',
                }}
            >
                <linkItem.icon
                    size={22}
                    className="icon"
                />
            </div>
            <p
                style={{
                    position: "absolute",
                    left: 45,
                    fontSize: 15,
                    width: "100%",
                    marginTop:"15px",
                    fontWeight: 600
                }}
            >
                {label}
            </p>
        </LinkItem>
    )
}

export default SidebarItem