import React from "react";
import SidebarItem from "../../components/SidebarItem";
import { links } from "../../data/links";
import ResourceDataMap from "../../components/ResourceDataMap";
import './side.css'

const SidebarComponent = ({ userStatus  }) => {

   // Filtrer les liens en fonction du statut de l'utilisateur
  const filteredLinks = links.filter((link) => link.status.includes(userStatus));
  

  return (
    <div className="side" >
      <ResourceDataMap
        resourceData={filteredLinks}
        resourceItem={SidebarItem}
        resourceName="linkItem"
        
      />
      
    </div>
  );
};

export default SidebarComponent;
