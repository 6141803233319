import React from "react";
import LoginPage from "pages/auth/LoginPage";
import RegisterPage from "pages/auth/RegisterPage";
import HomePage from "../pages/home/HomePage";
import { ACCEUIL_PATH, ENTREE_PATH, LOGIN_PATH, REGISTER_PATH, SORTIE_PATH, DETAIL_ENTREE_PATH, STATISTIQUE_PATH, CATEGORY_ENTREE_PATH, SETTING_PATH, ACCEUIL_ADMIN_PATH, DETAIL_SORTIE_PATH, PROFIL_PATH, FORGETPASSWORD, ACTIVATE_COMPTABLE_PATH, LISTE_TRANSACTION_COMPTABLES_PATH, HISTORIQUE_PATH, HISTORIQUE_SORTIE_PATH } from "../utils/navigationPaths";
import EntreePage from "pages/entrees/EntreePage";
import SortiePage from "pages/sorties/SortiePage";
import { Navigate } from "react-router-dom";
import Statistique from "pages/statistiques/statistique";
import CategoryEntree from "pages/categories/CategoryEntree";
import UsersPage from "pages/admin/ajoutComptable/UsersPage";
import HomeAdminPage from "pages/admin/home/homeAdmin";
import DetailSortie from "pages/sorties/detailSortie";
import DetailEntree from "pages/entrees/detailEntree";
import Profil from "pages/paramettreProfil/profil";
import ForgetPassword from "pages/auth/ForgetPassword";
import OtpValidate from "pages/auth/validateOtp";
import ListeTransComptable from "pages/admin/ajoutComptable/listeTransComptable";
import Historique from "pages/entrees/historique";
import HistoriqueSortie from "pages/sorties/historique";


export const publicRoutes = [
  {
    path: "*",
    element: <Navigate to={LOGIN_PATH} />,
  },
  {
    path: LOGIN_PATH,
    element: <LoginPage />,
  },
  {
    path: REGISTER_PATH,
    element: <RegisterPage />
  },
  {
    path: FORGETPASSWORD,
    element: <ForgetPassword />,
  },
  {
    path: ACTIVATE_COMPTABLE_PATH,
    element: <OtpValidate />,
  }
];

export const privateRoutes = [
  // {
  //   path: "/",
  //   element: <Navigate to={LOGIN_PATH} />,
  // },
  {
    path: ACCEUIL_PATH,
    element: <HomePage />,
  },
  {
    path: ENTREE_PATH,
    element: <EntreePage />,
  },
  {
    path: CATEGORY_ENTREE_PATH,
    element: <CategoryEntree />,
  },
  {
    path: SORTIE_PATH,
    element: <SortiePage />,
  },
  {
    path: DETAIL_ENTREE_PATH,
    element: <DetailEntree />,
  },
  {
    path: DETAIL_SORTIE_PATH,
    element: <DetailSortie />,
  },
  {
    path: STATISTIQUE_PATH,
    element: <Statistique />,
  },
  {
    path: SETTING_PATH,
    element: <UsersPage />,
  },
  {
    path: LISTE_TRANSACTION_COMPTABLES_PATH,
    element: <ListeTransComptable />,
  },
  {
    path: ACCEUIL_ADMIN_PATH,
    element: <HomeAdminPage />,
  },
  {
    path: PROFIL_PATH,
    element: <Profil />,
  },
  {
    path: HISTORIQUE_PATH,
    element: <Historique />,
  },
  {
    path: HISTORIQUE_SORTIE_PATH,
    element: <HistoriqueSortie />,
  },
];

