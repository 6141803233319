export const LOGIN_PATH = "/login";
export const REGISTER_PATH = "/register";
export const FORGETPASSWORD = "/auth/forgetPassword";
export const RESETPASSWORD = "/aut/resetPassword";

export const NOTFOUND = "404";

export const ACCEUIL_PATH = "/accueil";
export const ENTREE_PATH = "/entrees";
export const SORTIE_PATH = "/sorties";
export const SETTING_PATH = "/comptables";
export const CATEGORY_ENTREE_PATH = "/categories-entree";
export const CATEGORY_SORTIE_PATH = "/categories-sortie";
export const STATISTIQUE_PATH = "/statistique";

export const DETAIL_SORTIE_PATH = "/detailSortie/:id";
export const DETAIL_ENTREE_PATH = "/detailEntree/:id";

export const UPDATE_ENTREE_SORTIES_PATH = "/UpdateTransaction/:id";

// profil page
export const PROFIL_PATH = "/profil/:id";

// admin 
export const ACCEUIL_ADMIN_PATH = "/dashboardAdmin";
export const LISTE_TRANSACTION_COMPTABLES_PATH = "/lsiteTransactions"
// export const COMPTERENDU = "/compteRendu";

// activer un comptable
export const ACTIVATE_COMPTABLE_PATH = "/otp";

// Historique d'une transaction
export const HISTORIQUE_PATH = "/hitorique/:id"

export const HISTORIQUE_SORTIE_PATH = "/hitoriqueSortie/:id"
