import React, {useState, useEffect} from 'react';
import './home.css';
import SearchBar from 'components/SearchBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from "react-redux";
import CustomBootstrapTable from "../../components/tables/CustomBootstrapTable";
import ApiController from "../../redux/apiController";
import { setEntreesList,setCurrentEntree, filterEntreeBySearch } from "../../redux/reducers/entreesSlice";
import moment from 'moment';
import { BsEyeFill } from 'react-icons/bs';
import { RiDeleteBinLine } from 'react-icons/ri';
import fichier from '../../img/Featured icon.png'
import { DETAIL_ENTREE_PATH, DETAIL_SORTIE_PATH, ENTREE_PATH, SORTIE_PATH } from 'utils/navigationPaths';
import { Link, useNavigate } from "react-router-dom";
import DeleteEntree from 'pages/entrees/DeleteEntree';
import { toast,ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import soldes from '../../img/span.png';
import sorties from '../../img/span (1).png';
import entrees from '../../img/span1.png';
import transacrions from '../../img/span (2).png';
import caisse from '../../img/caisse.png';
import wave from '../../img/wave.png';
import om from '../../img/OM.png';
import banque from '../../img/banque.png';
import { useForm } from "react-hook-form";
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { FaHistory } from 'react-icons/fa';



const HomePage = () => {
  // const redirectTo = './accueil'
  const currentUser = useSelector(state => state.users?.currentUser)
  const { register, handleSubmit, setValue } = useForm();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const listTransactions = useSelector(state => state?.entreesSlice?.listEntrees?.data);
  const currentEntree = useSelector(state => (state?.entreesSlice?.currentEntree));
  // montant total entree sortie solde
  // console.log(listTransactions.length)
  const solde = useSelector(state => state?.entreesSlice?.listEntrees?.solde?.toLocaleString());
  const montantEntree = useSelector(state => state?.entreesSlice?.listEntrees?.totalEntrees?.toLocaleString());
  const montantSortie = useSelector(state => state?.entreesSlice?.listEntrees?.totalSorties?.toLocaleString());
  const montantWave = useSelector(state => state?.entreesSlice?.listEntrees?.wave?.toLocaleString());
  const montantOM = useSelector(state => state?.entreesSlice?.listEntrees?.orange_money?.toLocaleString());
  const montantCaisse = useSelector(state => state?.entreesSlice?.listEntrees?.caisse?.toLocaleString());
  const montantBanque = useSelector(state => state?.entreesSlice?.listEntrees?.banque?.toLocaleString());
  const nombreTransactions = listTransactions?.length
  
// card show
  const [showAllCards] = useState(false); // État pour afficher toutes les cartes ou non


// modification //////////////////////////////////////////////
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedColumn, setSelectedColumn] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const openRowForm = (row, column) => {   

    setSelectedRow(row);
    setSelectedColumn(column);
    setValue('intituele', row.intituele);
    setValue('date', row.date);
    setValue('categorie_entree_sortie', row.categorie_entree_sortie);
    setValue('montant', row.montant);
    setValue('lieux', row.lieux);
    dispatch(setCurrentEntree(row))

    setEditMode(true);
  
    // console.log(row)
  };
  const handleUpdate = (data) =>{
    ApiController.entreesApi.updateEntree(data,currentEntree?.id, currentUser.id, dispatch)
      .then(() => {
      // console.log('res', res)
      toast.success("modification reuissi !")
    }
    ).catch((er) => console.log(er))
  }

  // option pour les modification
  const source_paiement = [
    { id: "wave", value: "wave" },
    { id: "orange_money", value: "orange_money" },
    { id: "banque", value: "banque" }
  ];
  const lieux = [
    { id: "bakeli_Mbour", value: "bakeli_Mbour" },
    { id: "bakeli_Thies", value: "bakeli_Thies" },
    { id: "bakeli_Dakar", value: "bakeli_Dakar" }
  ];
  
  // fin modification

  useEffect(() => {
    ApiController.entreesApi.getAllTransaction(currentUser?.id).then((res) => {
      dispatch(setEntreesList(res));
    });
  }, [dispatch,currentUser?.id]);
  
  const handleSearch = (searchValue) => {
    dispatch(filterEntreeBySearch(searchValue))
  };
  
  // detail 
  const detailEntree = (item) => {
    dispatch(setCurrentEntree(item))
    const transactionId = item.id; // ou tout autre moyen d'obtenir l'ID de la commande
    const detailEntree = DETAIL_ENTREE_PATH.replace(":id", transactionId);
    const detailSortie = DETAIL_SORTIE_PATH.replace(":id", transactionId);
    // redirection de la page detailEntree ou detailSortie en fonction de la type de transaction 
    if (item.categorieEntreeSortie?.type_transaction === "entrees"){
      navigate(detailEntree)
    }else{
      navigate(detailSortie);
    }
    
  }

  // delete
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const openDeleteModal = (item) => {
    dispatch(setCurrentEntree(item))
    setShowDeleteModal(true)
  }

  const columns = [
    {
      dataField: "intituele",
      text: "Intitulé ",
      headerClasses: 'custom-table-column',
      filter: textFilter(),
      formatter: (cell, row) => {
        return (
          <div>
            <img className='mx-1' style={{width:"34px", height:"34px"}} src={fichier} alt={row.intituele} width="50" height="50" />
            {
            selectedRow === row && selectedColumn === "intituele" && editMode ? (
              <form onSubmit={handleSubmit(handleUpdate)}>
              <input
            {...register("intituele")}
              />
              {/* <button type="submit">Enregistrer</button> */}
            </form>
            ):      <span onClick={() => openRowForm(row, "intituele")}> {row.intituele ? row.intituele : "Pas de description pour ce category"}</span>

           }

          </div>
        );
      }
    },
    {
      dataField: "date",
      text: "Date",
      headerClasses: 'custom-table-column',
      formatter: (cell, row) => {
        const formattedDate = row.date ? moment(row.date).format("DD/MM/YYYY") : "";

        return (
          <div>
            {
              selectedRow === row && selectedColumn === "date" && editMode ? (
              <form onBlur={handleSubmit(handleUpdate)}>
              <input
              type='date'
            {...register("date")}
              />
              {/* <button type="submit">Enregistrer</button> */}
            </form>
            ):      <span onClick={() => openRowForm(row, "date")}> {formattedDate ? formattedDate : "Pas de description pour ce category"}</span>

           }
          </div>
        )
      }
    },
    {

        dataField: "categorie_entree_sortie",
        text: "Catégorie",
        formatter: (cell, row) => {
          if (row.categorieEntreeSortie) {
            return row.categorieEntreeSortie.name;
          } else {
            return "non defini";
          }
        },

      },
    {
      dataField: "source_paiement",
      text: "Moyen de paiement",
      headerClasses: 'custom-table-column col-2',
      formatter: (cell, row) => {
        return (
          <div>
            {selectedRow === row && selectedColumn === "source_paiement" && editMode ? (
              <form onSubmit={handleSubmit(handleUpdate)}>
                <select {...register("source_paiement")} onBlur={handleSubmit(handleUpdate)}>
                {source_paiement && source_paiement.length > 0 ? (
                  source_paiement.map((option) => (
                    <option key={option.id} value={option.id}>{option.value}</option>
                  ))
                ) : (
                  <option value="">Aucune option de transactions disponible</option>
                )}

                </select>
                {/* <button type="submit">Enregistrer</button> */}
              </form>
            ) : (
              <span onClick={() => openRowForm(row, "source_paiement" )} style={{ cursor: 'pointer'}}>{row.source_paiement}</span>
            )}
          </div>

        );
      } 
    },
    {
      dataField: "categorie_entree_sortie",
      text: "Type",
      headerClasses: 'custom-table-column col-2',
      formatter: (cell, row) => {
        const commande = row;
        if (commande.categorieEntreeSortie?.type_transaction === 'entrees') {
          return <p style={{color:'rgba(34, 253, 150, 1)'}}>credité</p>;
        }else if (commande.categorieEntreeSortie?.type_transaction === 'sorties') {
          return <p style={{color:'rgba(221, 27, 27, 1)'}}>debité</p>;
        }
        return null;
      }
      
    },
    {
      dataField: "montant",
      text: "Montant",
      headerClasses: 'custom-table-column col-2', 
      formatter: (cell, row) => {
        const commande = row;
        if (commande.categorieEntreeSortie?.type_transaction === 'entrees') {
          if (selectedRow === commande && selectedColumn === "montant" && editMode) {
            return (
              <form onSubmit={handleSubmit(handleUpdate)}>
                <input {...register("montant")} />
                {/* <button type="submit">Enregistrer</button> */}
              </form>
            );
          } else {
            return (
              <span onClick={() => openRowForm(commande, "montant")} style={{color:'rgba(34, 253, 150, 1)'}}>
                {commande.montant.toLocaleString() ? commande.montant.toLocaleString()  : "Pas de description"} fcfa
              </span>
            );
          }
      }
      else {
        if (selectedRow === commande && selectedColumn === "montant" && editMode) {
          return (
            <form onSubmit={handleSubmit(handleUpdate)}>
              <input {...register("montant")} />
              {/* <button type="submit">Enregistrer</button> */}
            </form>
          );
        } else {
          return (
            <span onClick={() => openRowForm(commande, "montant")} style={{color:'rgba(221, 27, 27, 1)'}}>
              {commande.montant.toLocaleString() ? commande.montant.toLocaleString()  : "Pas de description"} fcfa
            </span>
          );
        }
    }
        // return null
    }
    },
    // {
    //   dataField: "crm",
    //   text: "CRM",
    //   headerClasses: 'custom-table-column col-2',
    // },
    // return <p style={{color:'rgba(221, 27, 27, 1)'}}> {commande.montant?.toLocaleString()} fcfa</p>;
    {
      dataField: "createdBy",
      text: "Par",
      headerClasses: 'custom-table-column col-1',
      formatter: (cell, row) => {
        if (row.user) {
          return row.user.fullName;
        } else {
          return "non defini";
        }
      },
    },
    {
      dataField: "lieux",
      text: "Lieu",
      headerClasses: 'custom-table-column col-2',
      formatter: (cell, row) => {
        return (
          <div>
            {selectedRow === row && selectedColumn === "lieux" && editMode ? (
              <form onSubmit={handleSubmit(handleUpdate)}>
                <select {...register("lieux")} onBlur={handleSubmit(handleUpdate)}>
                {lieux && lieux.length > 0 ? (
                  lieux.map((option) => (
                    <option key={option.id} value={option.id}>{option.value}</option>
                  ))
                ) : (
                  <option value="">Aucune option de transactions disponible</option>
                )}

                </select>
              </form>
            ) : (
              <span onClick={() => openRowForm(row, "lieux" )} style={{ cursor: 'pointer'}}>{row.lieux}</span>
            )}
          </div>

        );
      }
    },
    {
        dataField: "action",
        text: "Actions",
        headerClasses: 'custom-table-column col-1',
        formatter: (cell, row) => {
          return (
            <>
              <div className="d-flex align-items-center">
                <BsEyeFill className=" btn-icon   me-2" size={17}
                   onClick={() => detailEntree(row)}
                   style={{ cursor: 'pointer' }} 
                  //  redirectTo={redirectTo}
                   />
              
                  <RiDeleteBinLine className="" size={17}
                   onClick={() => openDeleteModal(row)}
                   style={{ cursor: 'pointer' }}/> {/* Icône de suppression */}

                   <FaHistory  className=" mx-2" size={17}
                   style={{ cursor: 'pointer' }}
                   />
               
              </div>
            </>
          );
        },
    },
  ];
 
  // card data
  // Tableau de données des cartes
  const cardPadding= true
const cardsData = [
  {
    title: 'Solde total disponible',
    amount: solde,
    image: soldes,
    showCurrency: true,
    padding: cardPadding
  },
  {
    title: 'Entrées',
    amount: montantEntree,
    image: entrees,
    showCurrency: true,
    padding: cardPadding,
    path: ENTREE_PATH
  },
  {
    title: 'Sorties',
    amount: montantSortie,
    image: sorties,
    showCurrency: true,
    padding: cardPadding,
    path:SORTIE_PATH


  },
  {
    title: 'Nombre de transactions',
    amount: nombreTransactions,
    image: transacrions,
    padding: cardPadding
  },
  {
    title: 'Caisse',
    amount: montantCaisse,
    image: caisse,
    showCurrency: true,
    bgColor:'rgba(40, 199, 111, 1)',
    flexBetween: true,
    flexReverse:true
  },
  {
    title: 'Banque',
    amount: montantBanque,
    image: banque,
    showCurrency: true,
    bgColor:'rgba(27, 40, 80, 1)',
    flexBetween: true,
    flexReverse:true
    
  },
  {
    title: 'Wave',
    amount: montantWave,
    image: wave,
    showCurrency: true,
    bgColor:'rgba(29, 200, 255, 1)',
    flexBetween: true,
    flexReverse:true
  },
  {
    title: 'Orange Money',
    amount: montantOM,
    image: om,
    showCurrency: true,
    bgColor:'rgba(255, 159, 67, 1)',
    flexBetween: true,
    flexReverse:true
  },
  // Ajoutez d'autres objets de carte ici
];
  // couleur des dernier card 

const visibleCards = showAllCards ? cardsData : cardsData.slice(0, 10); // Affiche toutes les cartes ou seulement les 4 premières
  
 
  return (
    <div className='container-fluid'>
      <ToastContainer className='mt-5'/>
      <DeleteEntree showModal={showDeleteModal} setShowModal={setShowDeleteModal}/>
      <h4 className='dash m-0'>Dashboard</h4>
      {/* <p className='lorem'>Lorem ipsum dolor sit amet consectetur</p> */}
    <div className="col-md-12 top-5">
    <div className="row">
        {visibleCards.map((card, index) => (
          <div style={{height:'90px'}} className="col-md-3 col-sm-6 p-2" key={index}>
           <Link to={card.path} className='text-decoration-none'>
            <div style={{height:'100%'}} className="card cardcss bg-cherry">
              <div 
              className="card-body p-2 cardBody" 
              style={ {
                height:'100%',
                display: 'flex',
                flexDirection:card.flexReverse ? 'row-reverse' : 'row',
                justifyContent: card.flexBetween? 'space-between': 'flex-end',
                alignItems:'center', backgroundColor: card.bgColor,borderRadius: '10px' }
                }>
                <div className={`text-end ${card.padding ? 'pe-2' : null}`} >
                  <span className=" mb-0 montant-card" style={index >= cardsData.length - 4 ? { color:'#FFF' } : {}}>{card.amount}</span>
                    {card.showCurrency && <span className="currency montant-card" style={index >= cardsData.length - 4 ? { color:'#FFF' } : {}}> F</span>}
                  <div className="">
                    <span className="titre-card" style={index >= cardsData.length - 4 ? { color:'#FFF' } : {}}>{card.title}</span>
                  </div>
                </div>
                  <div className="">
                    <img className='mx-1' src={card.image} alt={card.title} />
                  </div>
              </div>
            </div>
            </Link>
          </div>
        ))}
      </div>
    <div className="col-12 mt-3">
      <div className='searcch'>
          <div className=''>
          <SearchBar placeholder="Recherche par nom, montant..." onSearch={handleSearch} />
          </div>
          <div className='buton'>
          <button className='buton'>
            <FontAwesomeIcon icon={faFilter} className="filter-icon" />
            <span className='filtre'>Filtre</span>
          </button>
        </div>
      </div>
      {/* table--------------- */}
      {
          listTransactions?.length ? (
            <>
                <CustomBootstrapTable 
                keyField="id"
                data={listTransactions} 
                columns={columns} 
                showPaginator={true}
                filter={filterFactory()}
                />
        </>
          ) : <p>Pas de transactions disponible</p>
        }
    </div>
    </div>
    </div>
  );
};


export default HomePage;
