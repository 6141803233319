import moment from 'moment';
import 'react-tabs/style/react-tabs.css';
import 'moment/locale/fr';

export const generateChartDataPeriode = (listTransactions, startDate, endDate) => {
    const data = {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: [],
        },
      ],
    };
  
    // Filtrer les transactions selon la période spécifiée par l'utilisateur
    const filteredTransactions = listTransactions.filter((transaction) => {
      const transactionDate = moment(transaction.date);
      return transactionDate.isBetween(moment(startDate), moment(endDate), null, '[]');
    });
  
    // Regrouper les transactions par catégorie
    const groupedCategories = {};
    filteredTransactions.forEach((transaction) => {
      const categoryName = transaction.categorieEntreeSortie?.name;
      if (!groupedCategories[categoryName]) {
        groupedCategories[categoryName] = 0;
      }
      if (transaction.categorieEntreeSortie?.type_transaction === 'entrees') {
        groupedCategories[categoryName] += transaction.montant;
      } else if (transaction.categorieEntreeSortie?.type_transaction === 'sorties') {
        groupedCategories[categoryName] -= transaction.montant;
      }
    });
  
    // Extraire les catégories et les montants groupés
    const categories = Object.keys(groupedCategories);
    const montants = Object.values(groupedCategories);
  
    // Ajouter les catégories et les montants à l'objet data
    data.labels = categories;
    data.datasets[0].data = montants;
  
    // Générer une couleur aléatoire pour chaque catégorie
    const randomColors = categories.map(() => getRandomColor());
    data.datasets[0].backgroundColor = randomColors;
  
    return data;
  };
  
  // Fonction pour générer une couleur aléatoire en format rgba
  const getRandomColor = () => {
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);
    return `rgba(${r}, ${g}, ${b}, 1)`;
  };
  

