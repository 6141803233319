import moment from 'moment';
import 'moment/locale/fr';

export const generateChartDataParCategorieSortie = (listTransactions, selectedYear) => {
  const data = {
    labels: [],
    datasets: [
      {
        label: 'Sorties',
        data: [],
        backgroundColor: 'rgba(221, 27, 27, 1)',
        borderColor: 'rgba(221, 27, 27, 1)',
        fill: false,
        tension: 0.1,
        borderRadius: 10,
        barThickness: 15,
      },
    ],
  };

  const groupedTransactions = {
    sorties: {},
  };

  // Filtrer les transactions par année sélectionnée
  const filteredTransactions = listTransactions.filter((transaction) => {
    const transactionYear = moment(transaction.date).format('YYYY');
    return transactionYear === selectedYear;
  });

  
  filteredTransactions.forEach((transaction) => {
    const categorie = transaction.categorieEntreeSortie?.name;
    const montant = transaction.montant;
    const typeTransaction = transaction.categorieEntreeSortie?.type_transaction;
    const transactionDate = moment(transaction.date);
    const year = transactionDate.format('YYYY');

    if (typeTransaction === 'sorties') {
      if (!groupedTransactions.sorties[categorie]) {
        groupedTransactions.sorties[categorie] = {};
      }

      if (!groupedTransactions.sorties[categorie][year]) {
        groupedTransactions.sorties[categorie][year] = 0;
      }

      groupedTransactions.sorties[categorie][year] += montant;
    }
  });

  moment.locale('fr');

  const categories = Object.keys(groupedTransactions.sorties);

  categories.forEach((categorie) => {
    const years = Object.keys(groupedTransactions.sorties[categorie]);

    years.forEach((year) => {
      const sorties = groupedTransactions.sorties[categorie][year] || 0;

      const label = `${categorie} (${year})`;
      data.labels.push(label);
      data.datasets[0].data.push(sorties);
    });
  });

  return data;
};


  
  // Fonction utilitaire pour générer une couleur aléatoire
//   function getRandomColor() {
//     const letters = '0123456789ABCDEF';
//     let color = '#';
//     for (let i = 0; i < 6; i++) {
//       color += letters[Math.floor(Math.random() * 16)];
//     }
//     return color;
//   }
  
