import DeleteModal from 'components/modals/DeleteModal';
import React from 'react'
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import ApiController from 'redux/apiController';

const DeleteCategoryEntree = ({ showModal, setShowModal }) => {

    // const dispatch = useDispatch();
    const currentUser = useSelector(state => state.users?.currentUser)
    const currentCatEntree = useSelector(state => state?.categoryEntreeSlice?.currentCategoryEntree)


    function closeModal() {
        setShowModal(false);
    }


    const handeleDeleteEntree = () => {
        let categoryId = currentCatEntree.id
        ApiController.categoryEntreesController.deleteCategoryEntree(categoryId, currentUser?.id)
            .then(() => {
                closeModal()
                toast.success('Category supprimée')
            })
            .catch((err) => {
                console.log(err)
                toast.error('Category non supprimé')
            })
    }

    return (
        <>
            <DeleteModal
                showModal={showModal}
                setShowModal={setShowModal}
                onClose={closeModal}
                titleIconClass='white-users-icon'
                className="mt-5"
            >

                <div className="row m-0 p-0">
                    <p className="text-center py-3">
                        Voulez vous vraiment supprimer cette category?
                    </p>
                    <p className="text-center">
                        <button
                            type="submit"
                            className=" btn btn-secondary text-white   py-2 px-3 mx-3 mt-3"
                            onClick={closeModal}
                        >
                            Annuler
                        </button>
                        <button
                            type="submit"
                            className="btn btn-danger py-2 px-3 mx-3 mt-3"
                            onClick={handeleDeleteEntree}
                        >
                            Supprimer
                        </button>
                    </p>
                </div>
            </DeleteModal>
        </>
    )
}

export default DeleteCategoryEntree