import moment from 'moment';
import 'moment/locale/fr';

export const generateChartDataParCategorieEntree = (listTransactions, selectedYear) => {
  const data = {
    labels: [],
    datasets: [
      {
        label: 'Entrées',
        data: [],
        backgroundColor: 'rgba(34, 253, 150, 1)',
        borderColor: 'rgba(34, 253, 150, 1)',
        fill: false,
        tension: 0.1,
        borderRadius: 10,
        barThickness: 15,
      },
    ],
  };

  const groupedTransactions = {
    entrees: {},
  };

  // Filtrer les transactions par année sélectionnée
  const filteredTransactions = listTransactions.filter((transaction) => {
    const transactionYear = moment(transaction.date).format('YYYY');
    return transactionYear === selectedYear;
  });

  filteredTransactions.forEach((transaction) => {
    const categorie = transaction.categorieEntreeSortie?.name;
    const montant = transaction.montant;
    const typeTransaction = transaction.categorieEntreeSortie?.type_transaction;
    const transactionDate = moment(transaction.date);
    const year = transactionDate.format('YYYY');

    if (typeTransaction === 'entrees') {
      if (!groupedTransactions.entrees[categorie]) {
        groupedTransactions.entrees[categorie] = {};
      }

      if (!groupedTransactions.entrees[categorie][year]) {
        groupedTransactions.entrees[categorie][year] = 0;
      }

      groupedTransactions.entrees[categorie][year] += montant;
    }
  });

  moment.locale('fr');

  const categories = Object.keys(groupedTransactions.entrees);

  categories.forEach((categorie) => {
    const years = Object.keys(groupedTransactions.entrees[categorie]);

    years.forEach((year) => {
      const entrees = groupedTransactions.entrees[categorie][year] || 0;

      const label = `${categorie} (${year})`;
      data.labels.push(label);
      data.datasets[0].data.push(entrees);
    });
  });

  return data;
};

  

  