import React from 'react';

const TablePaginator = ({ paginationIndex, setPaginationIndex, dataLength = 10 }) => {
  const pageCount = Math.ceil(dataLength / 10);

  return (
    <div className="paginator">
      {paginationIndex === 0 ? (
        <>
          <button className="prev" disabled={paginationIndex === 0} onClick={() => setPaginationIndex(paginationIndex - 1)}>
            Précédente
          </button>

          <button className="num mx-1 actif">{paginationIndex + 1}</button>
          {pageCount > 1 && (
            <button
              className="num mx-1"
              disabled={dataLength < 10}
              onClick={() => setPaginationIndex(paginationIndex + 1)}
            >
              {paginationIndex + 2}
            </button>
          )}
          {pageCount > 2 && (
            <button
              className="num mx-1"
              disabled={dataLength < 30}
              onClick={() => setPaginationIndex(paginationIndex + 2)}
            >
              {paginationIndex + 3}
            </button>
          )}

          <button
            className="next"
            disabled={dataLength < 20}
            onClick={() => setPaginationIndex(paginationIndex + 1)}
          >
            Suivante
          </button>
        </>
      ) : (
        <>
          <button className="prev" onClick={() => setPaginationIndex(paginationIndex - 1)}>
            Précédente
          </button>

          {paginationIndex > 1 && (
            <button className="num mx-1" onClick={() => setPaginationIndex(paginationIndex - 2)}>
              {paginationIndex - 1}
            </button>
          )}
          <button className="num mx-1" onClick={() => setPaginationIndex(paginationIndex - 1)}>
            {paginationIndex}
          </button>
          <button className="num mx-1 actif">{paginationIndex + 1}</button>
          {pageCount > paginationIndex + 2 && (
            <button
              className="num mx-1"
              disabled={dataLength < ((paginationIndex + 1) * 10) + 10}
              onClick={() => setPaginationIndex(paginationIndex + 1)}
            >
              {paginationIndex + 2}
            </button>
          )}

          <button
            className="next"
            disabled={dataLength < 20}
            onClick={() => setPaginationIndex(paginationIndex + 1)}
          >
            Suivante
          </button>
        </>
      )}
    </div>
  );
};

export default TablePaginator;
