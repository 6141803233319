import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import RequireAuth from "../components/RequireAuth";
import { NOTFOUND } from "../utils/navigationPaths";
import { privateRoutes, publicRoutes } from "./navigationRoutes";
import { LOGIN_PATH } from "utils/navigationPaths";

function Roots() {
  return (
      <Routes>
        {publicRoutes?.map((item, key) => (
          <Route path={item?.path} element={item.element} key={key} end />
        ))}

        <Route element={<RequireAuth />}>
          {privateRoutes?.map((item, key) => (
            <Route path={item?.path} element={item.element} key={key} end />
          ))}
          <Route path={NOTFOUND} element={<div>Page Not Found</div>} />
        </Route>

        <Route path="*" element={<Navigate to={LOGIN_PATH} />} />
      </Routes>
  );
}

export default Roots;
