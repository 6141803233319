import React, { useState } from 'react';
import DeleteEntree from '../../pages/entrees/DeleteEntree';
import moment from 'moment';
import { setCurrentEntree } from '../../redux/reducers/entreesSlice';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
// import { FiArrowLeft } from 'react-icons/fi';
import './entree.css'
import { ENTREE_PATH } from 'utils/navigationPaths';


const DetailEntree = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentEntree = useSelector((state) => state?.entreesSlice?.currentEntree);
  // const [isLoading, setIsLoading] = useState(false);
  const date = moment(currentEntree?.date).format('YYYY-MM-DD');
  const heure = moment(currentEntree?.date).format('HH:mm');
  
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  
  const openDeleteModal = (item) => {
    dispatch(setCurrentEntree(item));
    setShowDeleteModal(true);
    // console.log(item)
    // console.log(item);
  };

  const handleDeleteSuccess = () => {
    navigate(ENTREE_PATH); // Rediriger vers la page d'entrée après suppression
  };
  
  return (
    <div className="container">
      <ToastContainer className="mt-5" />
      {showDeleteModal && (
        <DeleteEntree 
        showModal={showDeleteModal} 
        setShowModal={setShowDeleteModal}
        onDeleteSuccess={handleDeleteSuccess} // Passer la fonction de rappel comme prop
        />
      )}
      <div className="row rowEntree">
          <div className="col-12">
                <h4 className='entreeTitle'>Entrées / Detail</h4>
                <p className='entreeDescription'>Visualisez, modifiez ou supprimer une transaction</p>
          </div>
      </div>
      <div className='m-3'>
          <div className='row mt-3 mb-4'>
          <div className='d-flex'>
          <h6>Date de la transaction</h6>
          <span className='mx-5' style={{fontWeight:'700'}}>{date}</span>
        </div>
        <div className='d-flex'>
          <h6>Heure de la transaction</h6>
          <span className='mx-5' style={{fontWeight:'700'}}>{heure}</span>
        </div>
        <hr className='hr'/>
          </div>
          <div className='row  pb-4'>
            <div className='col-md-4 oooo col-sm-6'>
            <span className='nomme'>intitulé</span>
            <span className='donnee'>{currentEntree?.intituele}</span>
            </div>
            <div className='col-md-4 col-sm-6'>
            <span className='nomme'>Categorie</span><br />
            <span className='donnee'>{currentEntree?.categorieEntreeSortie?.name}</span>
            </div>
            <div className='col-md-4 col-sm-6'>
            <span className='nomme'>Type</span><br />
            <span className='donnee'>{currentEntree?.categorieEntreeSortie?.type_transaction}</span>
            </div>
          </div>
          <div className='row pb-4 '>
          <div className='col-md-4 col-sm-6'>
            <span className='nomme'>Montant</span><br />
            <span className='donnee'>{currentEntree?.montant}</span>
            </div>
            <div className='col-md-4 col-sm-6'>
            <span className='nomme pb-3'>Moyen de paiement</span><br />
            <span className='donnee'>{currentEntree?.source_paiement}</span>
            </div>
            <div className='col-md-4 col-sm-6'>
            <span className='nomme'>Lieux</span><br />
            <span className='donnee'>{currentEntree?.lieux}</span>
            </div>
          </div>
          <div className='row'>
          <div className='col-md-4 col-sm-6'>
            <span className='nomme'>Compte comptable</span><br />
            <span className='donnee'>{currentEntree?.compte_comptable}</span>
            </div>
            <div className='col-md-4 col-sm-6'>
            <span className='nomme'>transaction effectué par</span><br />
            <span className='donnee'>{currentEntree?.user?.fullName}</span>
            </div>
          </div>
          <p className='paragrap'>Cette transaction peut contenir des erreurs. Vous pouvez directement modifier un champ ou la supprimer.</p>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      {/* Your other content */}
      <button
        style={{
          backgroundColor: '#F0443A',
          color: '#FFFFFF',
          borderRadius: '5px',
          fontWeight: '600',
          fontSize: '14px',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          padding: '10px 50px',
          border: 'none',
          fontStyle: 'normal',
          fontFamily: 'Montserrat',
        }}
        onClick={() => currentEntree && openDeleteModal(currentEntree)}
      >
        Supprimer
      </button>
          </div>
          </div>

    </div>
  );
};

export default DetailEntree;
