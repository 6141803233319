import React from "react";
import { ToastContainer } from "react-toastify";
import "./App.css";
import Roots from "./routes/Routes";

function App() {
  return (
    <div>
      <Roots />
      <ToastContainer className='mt-5'/>

    </div>
  );
}

export default App;
