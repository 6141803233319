import moment from 'moment';
import 'moment/locale/fr';

export const generateChartDataSemaine = (listTransactions, selectedYear) => {
  const data = {
    labels: [],
    datasets: [
      {
        label: 'Entrées',
        data: [],
        backgroundColor: 'rgba(34, 253, 150, 1)',
        borderColor: 'rgba(34, 253, 150, 1)',
        fill: false,
        tension: 0.1,
        borderRadius: 10,
        barThickness: 15,
      },
      {
        label: 'Sorties',
        data: [],
        backgroundColor: 'rgba(221, 27, 27, 1)',
        borderColor: 'rgba(221, 27, 27, 1)',
        fill: false,
        tension: 0.1,
        borderRadius: 10,
        barThickness: 15,
      },
    ],
  };

  // Filtrer les transactions par année sélectionnée
  const filteredTransactions = listTransactions.filter((transaction) => {
    const transactionYear = moment(transaction.date).format('YYYY');
    return transactionYear === selectedYear;
  });

  const groupedTransactions = {};
  filteredTransactions.forEach((transaction) => {
    const week = moment(transaction.date).format('W YYYY');
    if (!groupedTransactions[week]) {
      groupedTransactions[week] = {
        entrees: 0,
        sorties: 0,
      };
    }
    if (transaction.categorieEntreeSortie?.type_transaction === 'entrees') {
      groupedTransactions[week].entrees += transaction.montant;
    } else if (transaction.categorieEntreeSortie?.type_transaction === 'sorties') {
      groupedTransactions[week].sorties += transaction.montant;
    }
  });

  moment.locale('fr');

  const sortedWeeks = Object.keys(groupedTransactions).sort((a, b) => {
    const [weekA, yearA] = a.split(' ');
    const [weekB, yearB] = b.split(' ');
    const dateA = moment().year(yearA).isoWeek(weekA);
    const dateB = moment().year(yearB).isoWeek(weekB);
    return dateA - dateB;
  });

  sortedWeeks.forEach((week) => {
    const [weekNumber, year] = week.split(' ');
    const formattedWeek = moment().year(year).isoWeek(weekNumber).format('WW, YYYY');
    data.labels.push(formattedWeek);
    data.datasets[0].data.push(groupedTransactions[week].entrees);
    data.datasets[1].data.push(groupedTransactions[week].sorties);
  });

  return data;
};
