import moment from 'moment';
import 'moment/locale/fr';

export const generateChartDataSemestre = (listTransactions, selectedYear) => {
  const data = {
    labels: [],
    datasets: [
      {
        label: 'Entrées',
        data: [],
        backgroundColor: 'rgba(34, 253, 150, 1)',
        borderColor: 'rgba(34, 253, 150, 1)',
        fill: false,
        tension: 0.1,
        borderRadius: 10,
        barThickness: 15,
      },
      {
        label: 'Sorties',
        data: [],
        backgroundColor: 'rgba(221, 27, 27, 1)',
        borderColor: 'rgba(221, 27, 27, 1)',
        fill: false,
        tension: 0.1,
        borderRadius: 10,
        barThickness: 15,
      },
    ],
  };

  // Filtrer les transactions par année sélectionnée
  const filteredTransactions = listTransactions.filter((transaction) => {
    const transactionYear = moment(transaction.date).format('YYYY');
    return transactionYear === selectedYear;
  });

  // Regrouper les transactions par semestre
  const groupedTransactions = {};
  filteredTransactions.forEach((transaction) => {
    const semester = moment(transaction.date).format('YYYY-S');
    if (!groupedTransactions[semester]) {
      groupedTransactions[semester] = {
        entrees: 0,
        sorties: 0,
      };
    }
    if (transaction.categorieEntreeSortie?.type_transaction === 'entrees') {
      groupedTransactions[semester].entrees += transaction.montant;
    } else if (transaction.categorieEntreeSortie?.type_transaction === 'sorties') {
      groupedTransactions[semester].sorties += transaction.montant;
    }
  });

  moment.locale('fr');

  const sortedSemesters = Object.keys(groupedTransactions).sort((a, b) => {
    const [semesterA, yearA] = a.split('-');
    const [semesterB, yearB] = b.split('-');
    return moment(`${yearA}-${semesterA}`, 'YYYY-S').toDate() - moment(`${yearB}-${semesterB}`, 'YYYY-S').toDate();
  });

  sortedSemesters.forEach((semester) => {
    const [semesterValue, yearValue] = semester.split('-');
    const formattedSemester = `S${semesterValue} ${yearValue}`;
    data.labels.push(formattedSemester);
    data.datasets[0].data.push(groupedTransactions[semester].entrees);
    data.datasets[1].data.push(groupedTransactions[semester].sorties);
  });

  return data;
};
