import React from 'react'
import DeleteModal from '../../components/modals/DeleteModal';
import ApiController from '../../redux/apiController';
import { toast } from 'react-toastify';
// import { useEffect } from 'react'; 
import { useSelector } from "react-redux";
// import { useNavigate } from 'react-router-dom';

const DeleteEntree = ({ showModal, setShowModal, onDeleteSuccess  }) => {
  // const navigate = useNavigate();
  const currentEntree = useSelector(state => state.entreesSlice?.currentEntree)
  // console.log(currentEntree);
  const currentUser = useSelector(state => state.users?.currentUser)
  // const dispatch = useDispatch();


  function closeModal() {
    setShowModal(false);
  }

  const handeleDeleteEntree = () => {
    let entreeId = currentEntree.id
    ApiController.entreesApi.deleteEntree(entreeId, currentUser?.id)
      .then(() => {
        closeModal()
        toast.success('Transaction supprimée')
        onDeleteSuccess(); 
      })
      .catch((err) => {
        console.log(err)
        toast.error('Transaction non supprimé')
      })


  }
  return (
    <DeleteModal

      showModal={showModal}
      setShowModal={setShowModal}
      onClose={closeModal}
      title='Suppression Transaction'
      titleIconClass='white-users-icon'
      className="mt-5"
    >

      <div className="row m-0 p-0">
        <p className="text-center py-3">
          Voulez vous vraiment supprimer cette Transaction?
        </p>
        <p className="text-center">
          <button
            type="submit"
            className=" btn btn-secondary text-white   py-2 px-3 mx-3 mt-3"
            onClick={closeModal}
          >
            Annuler
          </button>
          <button
            type="submit"
            className="btn btn-danger py-2 px-3 mx-3 mt-3"
            onClick={handeleDeleteEntree}
          >
            Supprimer
          </button>
        </p>
      </div>
    </DeleteModal>
  )
}

export default DeleteEntree