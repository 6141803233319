import React, { useState, useEffect } from 'react';
import './filterEntreeDeuxDates.css'

const DateFilter = ({ onDateChange }) => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  useEffect(() => {
    // Lorsque les dates changent, appelez la fonction onDateChange avec les nouvelles dates.
    onDateChange(startDate, endDate);
  }, [startDate, endDate]);

  return (
    <div className='d-flex'>
      <div>
      <label>Date debut</label><br/>
      <input
        type="date"
        value={startDate}
        onChange={(e) => setStartDate(e.target.value)}
        style={{
          border: '1px solid #ccc',
          padding: '6px',
          borderRadius: '4px',
          fontSize: '12px',
          marginRight:'5px'
        }}
      />
      </div>
      <div>
      <label>Date fin</label><br/>
      <input
        type="date"
        value={endDate}
        onChange={(e) => setEndDate(e.target.value)}
        style={{
          border: '1px solid #ccc',
          padding: '6px',
          borderRadius: '4px',
          fontSize: '12px',
        }}
      />
      </div>
    </div>
  );
};

export default DateFilter;
