import React, { useState } from "react";
// import styles from "assets/styles.module.css";
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import ApiController from 'redux/apiController';
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import logo_fewnu from '../../img/unnamed 1.png'
import codeImage from '../../img/code.png';
import './login.css';
import SixDigitCodeInput from "components/digitalCode";


function OtpValidate() {

  const { handleSubmit, formState: { errors } } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [code, setCode] = useState(['', '', '', '', '', '']); // Initialize the state here
  // const dispatch = useDispatch();
    const navigate = useNavigate()
    const currentUser = useSelector(state => state?.users?.currentUser)

  const submit = () => {
      const otpCode = code.join(''); // Rassembler les chiffres pour obtenir le code complet

      setIsLoading(true);
      ApiController.comptablesController.validateOTPAndActivate(currentUser.id, { otp_code: otpCode })
        .then(res => {
            console.log(res);
            toast.success('Connexion reussie');
            navigate("/accueil");// Remplacez par le chemin de votre page d'accueil
    })
          .catch(err => {
            console.log(err.response); // Affiche la réponse d'erreur du serveur
            const errorMessage = err.response?.data?.message || 'Une erreur s\'est produite';
            toast.error(errorMessage);
          })
          .finally(() => {
            setIsLoading(false); // Désactiver la loader
          });
    }
  return (
  <div className="login-page m-0 p-0">
    <div className="">
    <div className="block-align">
        <div className="">
          <img className="image-fewnu-login" src={logo_fewnu} alt="Fewnu"/>
        </div>
        <div className="">
          <hr className="otp-hr" />
          <p className="text-white font-Montserrat text-uperCase treso">
           Fewnu Trésorerie 
          </p>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className='col-md-5'>
          <img  src={codeImage} alt='imageForgetPassword' style={{height:'400px'}}/>
        </div>
        <div className='col-md-4 mt-5'>
        <form
          onSubmit={handleSubmit(submit)}
        >
        <span className='' style={{textAlign:'center', color:'#fff', fontFamily:'700'}}>
         Veuillez entrer votre code de validiation pour activer votre compte
        </span>
       
      <SixDigitCodeInput
        code={code}
        onChange={setCode}
        error={errors.otp_code && errors.otp_code.message}
      />
  
            <button
            
              type="submit"
              className="login-btn row m-0 p-0 mt-3 align-items-center font-Montserrat"
            
              disabled={code.some(digit => digit === '') || isLoading}
            
            >
              <span className="col-2"></span>
              <span className="col-8">Valider</span>
              <span className="col-2">
                <div
                  style={{ width: "20px", height: "20px" }}
                  className={
                    isLoading ? "spinner-border fs-6 mt-1" : "d-none"
                  }
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              </span>
            </button>
        </form>
        </div>
      </div>
    </div>
  </div>
  );
}

export default OtpValidate;
