import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FiEye, FiEyeOff } from "react-icons/fi";
import { useNavigate, Link } from 'react-router-dom';
import ApiController from '../../redux/apiController';
import { isValidMobilePhone } from '../../utils/form_control';
import { FORGETPASSWORD, NOTFOUND } from '../../utils/navigationPaths';
import { useDispatch } from 'react-redux';
import { setCurrentUser } from '../../redux/reducers/userSlice';
import "./login.css";
import logo_fewnu from '../../img/unnamed 1.png'
import { toast } from 'react-toastify';

const LoginPage = () => {
    const [isLoading, setIsLoading] = useState(false);
  const [isUserActive] = useState(true); // Ajout de l'état pour vérifier si l'utilisateur est actif

    const {register,handleSubmit,formState:{errors},setError} = useForm()
    const navigate = useNavigate()
    const dispatch = useDispatch()
  const [visiblePassword, setVisiblePassword] = useState(false);
    const submit =(data)=>{
      setIsLoading(true);
      ApiController.users.loginUser(data, dispatch)
      .then(user =>{
          // console.log(user)
          dispatch(setCurrentUser(user))
          // navigate(ACCEUIL_PATH)
          // Redirection en fonction du statut de l'utilisateur
          if (user.status === "PROPRIETAIRE") {
            navigate("/dashboardAdmin");
          } else if (user.status === "COMPTABLE") {
            navigate("/accueil");
          } else {
            navigate(NOTFOUND);
          }
      })
      .catch(err=>{
          console.log(err);
          setError('password',{message:'Numéro ou mot de passe incorrect'})
          toast.error("Numéro ou mot de passe incorrect");
      }).finally(() => {
          setIsLoading(false); // Désactiver la loader
      });
  }
  const verificate = (data)=>{
      let errorFields = []

      if(!isValidMobilePhone(data.phone)){
          errorFields.push({field:'phone',message:'Numéro invalid'})
      }
      if(data.password.length < 4){
          errorFields.push({field:'password',message:'Mot de passe trop court'})
      }

      if(errorFields.length ){
        errorFields.map((err) => {
              let {field,message} = err
          return setError(field, { message })
          })
      }else{
          submit(data)
      }

  }

    return (
        <div className="login-page m-0 p-0">
          <div className="row m-0 p-0 justify-content-center">
            <div className="row justify-content-center">
              <div className="row  justify-content-center">
                <img className="image-fewnu-login" src={logo_fewnu} alt="Fewnu"/>
              </div>
              <div className="row text-center">
                <hr className="login-hr" />
                <p className="text-white font-Montserrat text-uperCase treso">
                 Fewnu Trésorerie 
                </p>
              </div>
            </div>
            <div className="row mt-4 justify-content-center text-center">
            {/* <p className="font-Montserrat business">
              Connectez-vous et gérez votre business!
              </p> */}
              <form
                onSubmit={handleSubmit(verificate)}
                className="row m-0 p-0 justify-content-center"
              >
                <div className="col-10 col-sm-8 col-md-6 col-lg-3">
                  <input
                    {...register("phone", {
                      required: "Veuillez saisir votre email",
                    })}
                    type="tel"
                    placeholder="E-mail ou télephone"
                    className={"login-input form-control mt-1 px-2"}
                  />
                  {errors.email && (
                    <p className="custom-color-danger">
                      {/* <FiAlertTriangle /> {errors.email.message} */}
                    </p>
                  )}
    
              <div className="mt-4" style={{}}>
            <div style={{ position: "relative", width: "100%" }}>
              <input
                {...register("password", {
                  required: "Veuillez saisir un mot de passe",
                })}
                type={visiblePassword ? "text" : "password"}
                placeholder="Mot de passe"
                className="login-input form-control px-2"
                style={{ paddingRight: 50 }} // Ajustement pour tenir compte de la largeur du bouton de visibilité
              />
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  right: 5,
                  transform: "translateY(-50%)",
                  height: 41.5,
                  width: 50,
                  backgroundColor: "transparent",
                  display: "grid",
                  placeItems: "center",
                  cursor: "pointer",
                }}
                className="login-input form-control px-2"
                onClick={() => setVisiblePassword((v) => !v)}
              >
                {visiblePassword ? (
                  <FiEye color="#FFF" />
                ) : (
                  <FiEyeOff color="#FFF" />
                )}
              </div>
            </div>
          </div>
          {errors.password && (
            <p className="custom-color-danger">
              {/* <FiAlertTriangle /> {errors.password.message} */}
            </p>
          )}

                <p className="text-end mt-3">
                    <Link
                      className="text-end text-white custom-mouse-clickable text-decoration-none"
                      to={FORGETPASSWORD}
                    >
                      Mot de passe oublié ?
                    </Link>
                  </p>
                  {isUserActive ? null : (
                    <p className="custom-color-danger mt-2">
                      L'utilisateur est désactivé. Veuillez contacter l'administrateur.
                    </p>
                  )}
                  <button
                    disabled={isLoading}
                    type="submit"
                    className="login-btn row m-0 p-0 mt-3 align-items-center font-Montserrat"
                  >
                    <span className="col-2"></span>
                    <span className="col-8">Connexion</span>
                    <span className="col-2">
                      <div
                        style={{ width: "20px", height: "20px" }}
                        className={
                          isLoading ? "spinner-border fs-6 mt-1" : "d-none"
                        }
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </span>
                  </button>
                </div>
              </form>
    
              {/* <p className='text-white d-block d-md-none mt-2'>
                            Pas de compte? <Link className='text-white fw-bold text-decoration-none' to="/register" >Inscrivez-vous!</Link>
                        </p> */}
            </div>
          </div>
        </div>
      );
};

export default LoginPage;

