import React from 'react';
import './deleteModal.css'

const DeleteModal = ({ showModal = false, setShowModal, titleIconClass = '', children, onClose }) => {

    const closeModal = () => {
        try{
            onClose()
            setShowModal(false)
        }catch{
            setShowModal(false)
        }
    }

    if(showModal){
        return(
            <>
                <div className='custom-modal-overlay-delete' onClick={closeModal}>
                </div>
                <div className="custom-modal-delete custom-bg-light">
                    <header>
                        <canvas className={'icon ms-1 me-1 '+titleIconClass}></canvas>
                        {/* <span>{title}</span> */}
                        <canvas onClick={closeModal} className='icon white-close-icon me-3 ms-auto'></canvas>
                    </header>
                    <body className='custom-bg-light body'>
                        {
                            children
                        }
                    </body>
                </div>
            </>
        )
    }
};

export default DeleteModal;