import { createSlice } from "@reduxjs/toolkit/";

let initialState = {
    currentEntree: {},
    listEntrees: [],
    listEntreesAll: [], // Initialize as an empty array
  };

const entreeSlice = createSlice({
  name: "entrees",
  initialState,
  reducers: {
    addNewEntree: (state, { payload }) => {
      state.listEntrees.data = [...state.listEntreesAll.data, payload].reverse();
      state.listEntreesAll.data = [...state.listEntrees.data, payload].reverse();
    },
    updateEntreeSlice: (state, { payload }) => {
      let entrees = state.listEntreesAll.data.filter(entrees => entrees.id !==
          payload.id)
          entrees = [...entrees, payload].reverse()
      state.listEntreesAll.data = entrees
      state.listEntrees.data = entrees
  },
    setCurrentEntree: (state, { payload }) => {
      state.currentEntree = payload;
    },
    setEntreesList: (state, { payload }) => {
      state.listEntrees = payload;
      state.listEntreesAll = payload;
    },
    resetCurrentEntree: (state) => {
      state.currentEntree = {};
    },
    archiveEntree:(state, {payload}) =>{
      let entree = state.listEntreesAll.data.filter(item => item.id != payload.id)

      state.listEntrees = entree
      state.listEntreesAll = entree
    },

    filterEntreeBySearch: (state, { payload }) => {
      state.listEntrees.data = state.listEntreesAll.data.filter(item => {
        let entreeText = item.intituele?.toLowerCase() ?? "";
        let categories = item.categorieEntreeSortie?.name?.toLowerCase() ?? "";
        let typeTransaction = item.categorieEntreeSortie?.type_transaction?.toLowerCase() ?? "";
        let sourcePaiement = item.source_paiement?.toLowerCase() ?? "";
        let date = item.date?.toLowerCase() ?? "";
    
        // console.log(entreeText);
        return entreeText.includes(payload.toLowerCase()) ||
          categories.includes(payload.toLowerCase()) ||
          sourcePaiement.includes(payload.toLowerCase()) ||
          typeTransaction.includes(payload.toLowerCase()) ||
          date.includes(payload.toLowerCase());
      });
    },
    


  },
});
export const setEntreesListFromLocalStorage = (data) => {
  return {
    type: 'entrees/setEntreesListFromLocalStorage',
    payload: data,
  };
};


export const {
  addNewEntree,
  updateEntreeSlice,
  setCurrentEntree,
  setEntreesList,
  resetCurrentEntree,
  filterEntreeBySearch,
  archiveEntree
} = entreeSlice.actions;

export default entreeSlice.reducer;
