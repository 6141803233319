

export const DEFAULT_API = process.env.REACT_APP_DEFAULT_API;



export const LOGIN_API_ROUTE = DEFAULT_API +'login/'
// transaction 
export const TRANSACTIONS_API_ROUTE = DEFAULT_API +'transactions/user/'
export const ADD_TRANSACTIONS_API_ROUTE = DEFAULT_API +'transactions'
export const KPI_TRANSACTIONS_BY_PROPRIO_API_ROUTE = DEFAULT_API +'transactionsProprio/user/'
export const TRANSACTIONS_BY_ID_API_ROUTE = DEFAULT_API +'transactionsProprietaire/user/'

export const ADD_TRANSACTIONS_EXCEL_API_ROUTE = DEFAULT_API +'transactionsExcel'
export const DELETE_ENTREE_API_ROUTE = DEFAULT_API +'transactions/'
export const UPDATE_ENTREE_API_ROUTE = DEFAULT_API +'transactions/'

export const CATEGORY_ENTREE_API_ROUTE = DEFAULT_API +'category-entree-sortie'
export const CATEGORY_ENTREE_BY_ID_API_ROUTE = DEFAULT_API +'category-entree-sortie/'
export const CATEGORY_ENTREE_BY_USER_API_ROUTE = DEFAULT_API +'category-entree-sortie/user/'

export const COPMTABEL_API_ROUTE = DEFAULT_API +'comptable/user/'
export const ADD_COPMTABEL_API_ROUTE = DEFAULT_API +'comptable'
export const UPDATE_USER_API_ROUTE = DEFAULT_API +'comptable/'
export const COMPTABLE_BY_ID_API_ROUTE = DEFAULT_API +'comptable/'

export const ACTIVE_API_ROUTE = DEFAULT_API + 'active-comptables/'
export const DESACTIVE_API_ROUTE = DEFAULT_API + 'deactive-comptables/'

export const ACTIVATE_COMPTE_API_ROUTE = DEFAULT_API + 'register-activate-compte/'
