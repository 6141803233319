import moment from 'moment';
import 'moment/locale/fr';


export const generateChartDatacategoriePourcentage = (listTransactions, selectedYear, chartType, selectedMonth) => {
  const data = {
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [],
        borderColor: 'rgba(34, 253, 150, 1)',
        fill: false,
        tension: 0.1,
        borderRadius: 10,
        barThickness: 15,
        label: chartType === 'sorties' ? 'Sorties' : 'Entrées', // Add the legend value based on the chartType

      },
    ],
  };

  // Fonction pour générer une couleur aléatoire
  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  // Filtrer les transactions par année sélectionnée
  const filteredTransactions = listTransactions.filter((transaction) => {
    const transactionYear = moment(transaction.date).format('YYYY');
    const transactionMonth = moment(transaction.date).format('MMMM');
    return (
      transactionYear === selectedYear &&
      (!chartType || (chartType === 'sorties' && transaction.categorieEntreeSortie?.type_transaction === 'sorties') ||
        (chartType === 'entrees' && transaction.categorieEntreeSortie?.type_transaction === 'entrees')
      ) &&
      (!selectedMonth || transactionMonth === selectedMonth)
    );
  });
  

  // Regrouper les transactions par catégorie d'entrée en filtrant par année sélectionnée
  const groupedTransactions = {};
  filteredTransactions.forEach((transaction) => {
    const categorieEntree = transaction.categorieEntreeSortie?.name;
    if (
      (chartType === 'sorties' && transaction.categorieEntreeSortie?.type_transaction === 'sorties' && categorieEntree) ||
      (chartType === 'entrees' && transaction.categorieEntreeSortie?.type_transaction === 'entrees' && categorieEntree)
    ) {
      if (!groupedTransactions[categorieEntree]) {
        groupedTransactions[categorieEntree] = {
          entrees: 0,
        };
      }
      groupedTransactions[categorieEntree].entrees += transaction.montant;
    }
  });

  moment.locale('fr');

  // Calculer le total des montants d'entrée pour chaque catégorie
  const totalEntreesByCategorie = Object.values(groupedTransactions).reduce((total, categorie) => {
    return total + categorie.entrees;
  }, 0);

  // Calculer les pourcentages des catégories d'entrées pour chaque mois
  Object.keys(groupedTransactions).forEach((categorie) => {
    const totalEntrees = groupedTransactions[categorie].entrees;
    const percentageEntrees = (totalEntrees / totalEntreesByCategorie) * 100;

    // data.labels = categorie;
    data.labels.push(categorie);
    data.datasets[0].data.push(Math.round(percentageEntrees)); // Arrondir les pourcentages
    data.datasets[0].backgroundColor.push(getRandomColor()); // Couleurs aléatoires pour les barres
  });

  return data;
};






  
