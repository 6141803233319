import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    currentCategoryEntree: {},
    categoryEntreesList: [],
    allCategoryEntreesList: [],
}

/**
* !Some CategoryEntreeslice reducers are used in CategoryEntreeApi.js located in
redux/apiContoller/
*/

const categoryEntreeSlice = createSlice({
    name: "categoryEntrees",
    initialState,
    reducers: {
        setCurrentCategoryEntreeSlice: (state, { payload }) => {
            state.currentCategoryEntree = payload
        },
        setCategoryEntreesListSlice: (state, { payload }) => {
            // console.log({payload});
            
            return{
                ...state,
                categoryEntreesList: payload,
                allCategoryEntreesList: payload
            }
        },
        addNewCategoryEntreeSlice: (state, { payload }) => {
            state.allCategoryEntreesList = [...state.allCategoryEntreesList, payload].reverse()
            state.categoryEntreesList = [...state.allCategoryEntreesList, payload].reverse()
        },
        updateCategoryEntreeSlice: (state, { payload }) => {
            let categoryEntrees = state.allCategoryEntreesList.filter(categoryEntree => categoryEntree.id !==
                payload.id)
            categoryEntrees = [...categoryEntrees, payload].reverse()
            state.allCategoryEntreesList = categoryEntrees
            state.categoryEntreesList = categoryEntrees
        },
        archiveCategoryEntreeSlice: (state, { payload }) => {
            let categoryEntrees = state.allCategoryEntreesList.filter(categoryEntree => categoryEntree.id !==
                payload.id)
            state.categoryEntreesList = categoryEntrees
            state.allCategoryEntreesList = categoryEntrees
        },
        filterFournisseuList: (state, { payload }) => {
            /**
            * @param payload {string}
            */
            state.categoryEntreesList = state.allCategoryEntreesList.filter((item) => {
                let data = [item].join(' ').toLowerCase()
                return data.includes(payload.toLowerCase())
            })
        }
    }
}
)


export const {  
    setCategoryEntreesListSlice, 
    addNewCategoryEntreeSlice, 
    setCurrentCategoryEntreeSlice, 
    updateCategoryEntreeSlice, 
    archiveCategoryEntreeSlice, filterFournisseuList } = categoryEntreeSlice.actions

export default categoryEntreeSlice.reducer