import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    currentComptable: {},
    comptablesList: [],
    allComptablesList: [],
}

/**
* !Some Comptableslice reducers are used in ComptableApi.js located in
redux/apiContoller/
*/

const comptableSlice = createSlice({
    name: "comptables",
    initialState,
    reducers: {
        setCurrentComptableSlice: (state, { payload }) => {
            state.currentComptable = payload
        },
        setComptablesListSlice: (state, { payload }) => {
            state.comptablesList = payload
            state.allComptablesList = payload
        },
        addNewComptableSlice: (state, { payload }) => {
            state.allComptablesList = [...state.allComptablesList, payload].reverse()
            state.comptablesList = [...state.allComptablesList, payload].reverse()
        },
        updateComptableSlice: (state, { payload }) => {
            let comptables = state.allComptablesList.filter(comptable => comptable.id !==
                payload.id)
            comptables = [...comptables, payload].reverse()
            state.allComptablesList = comptables
            state.comptablesList = comptables
        },
        archiveComptableSlice: (state, { payload }) => {
            let comptables = state.allComptablesList.filter(comptable => comptable.id !==
                payload.id)
            state.comptablesList = comptables
            state.allComptablesList = comptables
        },
        filterFournisseuList: (state, { payload }) => {
            /**
            * @param payload {string}
            */
            state.comptablesList = state.allComptablesList.filter((item) => {
                let data = [item].join(' ').toLowerCase()
                return data.includes(payload.toLowerCase())
            })
        }
    }
}
)


export const { setComptablesListSlice, addNewComptableSlice, setCurrentComptableSlice, updateComptableSlice, archiveComptableSlice, filterFournisseuList } = comptableSlice.actions

export default comptableSlice.reducer