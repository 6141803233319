import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { Modal } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import ApiController from 'redux/apiController';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import InputLabel from 'components/InputLabel';


const AddCatEntree = ({ showModal, closeModal, refreshCategorieList }) => {
  const [isLoading] = useState(false);

    const { register, handleSubmit, setError, formState: { errors }, reset } = useForm();
    const currentUser = useSelector(state => state.users?.currentUser)

    const handleSave = (data) =>{
        const newData = {...data, createdBy:currentUser?.id}
        ApiController
        .categoryEntreesController
        .addNewCategoryEntree(newData)
          .then(() => {
            closeModal()
            toast.success("Ajout reuissi ! ")
            refreshCategorieList()
            reset()
        })
        .catch((err) => 
       ( 
        toast.success("Erreur lors de l'ajout ! "),
        console.log(err) )
        )
    }

    //verification
    const onSubmit = (data) => {
      // Validation du montant minimum
      if (/\d/.test(data.name)) {
        setError('name', {
          type: 'manual',
          message: "le nom de la categorie ne doit pas contenir de chiffres.",
        });
        return;
      }
  //apres verirification on appel la fonction 
      handleSave(data)
    }

    const type_transaction = [
      { id: "entrees", value: "entrees" },
      { id: "sorties", value: "sorties" }
    ];

   
    return (
        <>
            <Modal show={showModal} onHide={closeModal} size="lg">
                <Modal.Header closeButton style={{ background: "rgba(67, 68, 69, 1)", color: "#FFFFFF" }}>
                    <Modal.Title > 
                    <div className="text-center">
                    <FaPlus /> Ajouter une categorie entrée ou sortie
                    </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="container">
                        {/* Client */}
                        {/* <h3 className="section-title">Commande</h3> */}
                        <div className="row section mb-4">
                          {/* categorie name */}
                          <InputLabel
                            label="Nom categorie"
                            name="name"
                            type="text"
                            register={register}
                            errors={errors}
                          />

                        {/* TYPE TRANSACTION */}
                        <InputLabel
                          label="type transaction"
                          name="type_transaction"
                          type="select"
                          register={register}
                          errors={errors}
                          options={type_transaction} // Assurez-vous que crmOptions est défini et contient les options pour la sélection
                        />
                        </div>
                        </div>
                        <div className=''>
                <div className='text-center'>
                  <button style={{
                    backgroundColor: "rgba(0, 190, 201, 1)", color: "#FFFFFF", borderRadius: "5px",
                    fontWeight: "600",
                    fontSize: "14px",
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    padding: "10px 50px",
                    border: "none",
                    fontStyle: "normal",
                    fontFamily: "Montserrat",
                    marginLeft:"4px"
                  }} 
                  disabled={isLoading}>
                    {
                      isLoading ? (
                        <div className="spinner-border text-light" role="status">
                        </div>
                      ) : 'Valider'
                    }
                  </button>
              </div>
                       </div>
                    </form>
                </Modal.Body>
            </Modal>

        </>
    )
}

export default AddCatEntree