import React, {useState, useEffect} from 'react'
import { useForm } from 'react-hook-form';
import { Modal } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import ApiController from 'redux/apiController';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from "react-redux";
import { setCurrentEntree } from "../../redux/reducers/entreesSlice";
import InputLabel from 'components/InputLabel';
import AddCatEntree from 'pages/categories/AddCatEntree';

const AddEntreeSortie = ({ showModal, closeModal, pageTypeTransaction, refresEntreeList}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isCategorySelected, setIsCategorySelected] = useState(true);
    const dispatch = useDispatch();
    const { register, handleSubmit,setError,
      formState: { errors }, reset } = useForm();
    const currentUser = useSelector(state => state.users?.currentUser)

    useEffect(() => {
        ApiController.entreesApi.getEntreesById(currentUser?.id).then(()=>{})
        dispatch(setCurrentEntree())
      },[dispatch]);
    
   
      const handleSave = (data) => {
        setIsLoading(true)
        const newData = {...data, createdBy: currentUser?.id,  categorie_entree_sortie:parseInt(data?.categorie_entree_sortie)};
        // Validation du montant minimum
        data.montant = parseInt(data.montant);
        // Logique pour définir automatiquement le type de transaction en fonction de la catégorie
    if (data.categorie_entree_sortie) {
      const categorieSelected = categoriesOption.find((item) => item.id === parseInt(data.categorie_entree_sortie));
      if (categorieSelected?.type_transaction) {
        data.type_transaction = categorieSelected.type_transaction;
      }
    }

    if (/\d/.test(data.intituele)) {
      setError('intituele', {
        type: 'manual',
        message: 'L\'intitulé ne doit pas contenir de chiffres.',
      });
      return;
    }
    if (data.montant < 1000) {
      setError('montant', { message: 'Le montant doit être supérieur ou égal à 1000' });
      setIsLoading(false);
      return;
    }
    

        ApiController.entreesApi
          .registerEntree(newData)
          .then(() => {
            closeModal();
             toast.success('Ajout entrée réussi');
             refresEntreeList()
            reset();
          })
          .catch(err => {
            console.log(err);
            setError('message', { message: "Erreur lors de l'ajout" });
            toast.error("Erreur lors de l'ajout. Veuillez vérifier les données et réessayer.");
          })
          .finally(() => {
            setIsLoading(false);
          });
      };

      const onSubmit = (data) => {
        // Validation du montant minimum
        if (/\d/.test(data.intituele)) {
          setError('intituele', {
            type: 'manual',
            message: "L'intitulé ne doit pas contenir de chiffres.",
          });
          return;
        }
        data.montant = parseInt(data.montant);
        if (data.montant < 1000) {
          setError('montant', { message: 'Le montant doit être supérieur ou égal à 1000' });
          return;
        }

         // Vérification de la catégorie sélectionnée
      if (!data.categorie_entree_sortie) {
        setError('categorie_entree_sortie', { message: 'Veuillez sélectionner une catégorie' });
        return;
      }

      // Mise à jour de l'état pour indiquer que la catégorie a été sélectionnée
      setIsCategorySelected(true);
    //apres verirification on appel la fonction 
        handleSave(data)
      }
      const categoriesOption = useSelector(state => state?.categoryEntreeSlice?.categoryEntreesList);
      let categoriesOptions = [];
      if (categoriesOption && categoriesOption?.length > 0) {
        if (pageTypeTransaction === 'entrees') {
          categoriesOptions = categoriesOption.filter(item => item.type_transaction === 'entrees');
        } else if (pageTypeTransaction === 'sorties') {
          categoriesOptions = categoriesOption.filter(item => item.type_transaction === 'sorties');
        }
      }
      
    
            // recuper les option des categories qui sont dans model
        const sourcePaiementOptions = useSelector(state => state?.entreesSlice?.listEntrees?.source_paiement);
        const compteComptableOptions = useSelector(state => state?.entreesSlice?.listEntrees?.compte_comptable);
        const lieuxOptions = useSelector(state => state?.entreesSlice?.listEntrees?.lieux);
        const crmOptions = useSelector(state => state?.entreesSlice?.listEntrees?.crm);

    return (
        <>
     <Modal show={showModal} onHide={closeModal} size="lg">
     <AddCatEntree showModal={showModal} closeModal={closeModal}/>
        <Modal.Header closeButton style={{ background: "rgba(67, 68, 69, 1)", color: "#FFFFFF" }}>
          <Modal.Title > <FaPlus /> Ajouter une transaction</Modal.Title>
        </Modal.Header>

        <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)}>
      <div className="add-commande-container">
        <div className="pay-commande-client-section">
          <div className="row section-content mb-4">
            {/* Intitulé */}
            <InputLabel
              label="Intitulé"
              name="intituele"
              type="text"
              register={register}
              errors={errors}
            />

            {/* Montant */}
            <InputLabel
              label="Montant"
              name="montant"
              type="number"
              register={register}
              errors={errors}
            />

            {/* Categorie */}
            <InputLabel
              label="Categorie"
              name="categorie_entree_sortie"
              type="select"
              register={register}
              errors={errors}
              options={categoriesOptions} // Assurez-vous que categoriesOptions est défini et contient les options pour la sélection
              />
              {errors.categorie_entree_sortie && (
                <p className="error-message">{errors.categorie_entree_sortie.message}</p>
              )}

            {/* Moyen de paiement */}
            <InputLabel
              label="Moyen de paiement"
              name="source_paiement"
              type="select"
              register={register}
              errors={errors}
              options={sourcePaiementOptions} // Assurez-vous que sourcePaiementOptions est défini et contient les options pour la sélection
            />

            {/* Compte Comptable */}
            <InputLabel
              label="Compte Comptable"
              name="compte_comptable"
              type="select"
              register={register}
              errors={errors}
              options={compteComptableOptions} // Assurez-vous que compteComptableOptions est défini et contient les options pour la sélection
            />

            {/* CRM */}
            <InputLabel
              label="CRM"
              name="crm"
              type="select"
              register={register}
              errors={errors}
              options={crmOptions} // Assurez-vous que crmOptions est défini et contient les options pour la sélection
            />

            {/* Lieux */}
            <InputLabel
              label="Lieux"
              name="lieux"
              type="select"
              register={register}
              errors={errors}
              options={lieuxOptions} // Assurez-vous que lieuxOptions est défini et contient les options pour la sélection
            />

              {/* Date */}
            <InputLabel
              label="date"
              name="date"
              type="date"
              register={register}
              errors={errors}
            />
           
          </div>
          <div className='row'>
            {/* <div className='col-md-6'></div> */}
            <div className='' style={{display:'flex', flexDirection:'row', justifyContent:'end', flexWrap:'wrap'}}>
              <button
                style={{
                  backgroundColor: "rgba(100, 116, 139, 1)",
                  color: "#FFFFFF",
                  borderRadius: "5px",
                  fontWeight: "600",
                  fontSize: "14px",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                  padding: "10px 50px",
                  border: "none",
                  fontStyle: "normal",
                  fontFamily: "Montserrat",
                  // marginLeft: "10px"
                }}
                onClick={closeModal}
                type='button'
              >Annuler</button>

              <button
                style={{
                  backgroundColor: "rgba(0, 190, 201, 1)",
                  color: "#FFFFFF",
                  borderRadius: "5px",
                  fontWeight: "600",
                  fontSize: "14px",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                  padding: "10px 50px",
                  border: "none",
                  fontStyle: "normal",
                  fontFamily: "Montserrat",
                  marginLeft: "6px"
                }}
                disabled={isLoading || !isCategorySelected}
              >
                {isLoading ? (
                  <div className="spinner-border text-light" role="status">
                  </div>
                ) : 'Valider'}
              </button>
            </div>
          </div>
        </div>
      </div>
       </form>
        </Modal.Body>
            </Modal>

        </>
    )
}

export default AddEntreeSortie