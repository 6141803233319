import { createSlice } from "@reduxjs/toolkit/"


let initialState = {
    currentUser: {},
    listUsers :[]
}
const usersSlice = createSlice({
    name:'users',
    initialState,
    reducers:{
        setCurrentUser:(state,{payload})=>{
            state.currentUser = payload
        },
        setUsersList:(state,{payload})=>{
            state.listUsers = payload
        },
        removeCurrentUser: (state) => {
            state.currentUser = {}
        },
        updateUserSlice: (state, { payload }) => {
            let user = state.listEntrees.data.filter(user => user.id !==
                payload.id)
                user = [...user, payload].reverse()
            state.listEntrees.data = user
        },
    }
})

export const { setCurrentUser,setUsersList,removeCurrentUser, updateUserSlice} = usersSlice.actions
export default usersSlice.reducer