import React, { useState } from 'react';
import DeleteEntree from '../entrees/DeleteEntree';
import moment from 'moment';
import { setCurrentEntree } from '../../redux/reducers/entreesSlice';
import { useDispatch, useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import './sortie.css'
import { SORTIE_PATH } from 'utils/navigationPaths';
import ApiController from "../../redux/apiController";
import { useForm } from "react-hook-form";


const DetailSortie = () => {
  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentEntree = useSelector((state) => state?.entreesSlice?.currentEntree);
  const date = moment(currentEntree?.date).format('YYYY-MM-DD');
  const heure = moment(currentEntree?.date).format('HH:mm');
  const currentUser = useSelector(state => state.users?.currentUser)
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  
  const [editModes, setEditModes] = useState({
    intituele: false,
    date: false,
    categorie: false,
    montant: false,
    source_paiement: false,
    compte_comptable: false,
    lieux: false,
  });

  const enableEditMode = (fieldName) => {
    setEditModes((prevEditModes) => ({ ...prevEditModes, [fieldName]: true }));
    console.log(setEditModes);
  };

  // Fonction pour désactiver le mode d'édition pour un champ donné
  const disableEditMode = (fieldName) => {
    setEditModes((prevEditModes) => ({ ...prevEditModes, [fieldName]: false }));
  };
  // delete/////////////////////////////
  const openDeleteModal = (item) => {
    dispatch(setCurrentEntree(item));
    setShowDeleteModal(true);
  };
  
  // modify///////////////////////
  const handleUpdate = (data) =>{
    ApiController.entreesApi.updateEntree(data,currentEntree?.id, currentUser.id, dispatch)
    .then((res) =>{
      // dispatch(updateEntreeSlice())
      console.log('res', res)
      toast.success("modification reuissi !")
      disableEditMode("intituele")
      disableEditMode("montant")
      disableEditMode("lieux")
    }
    ).catch((er) => console.log(er))
  }

  const handleDeleteSuccess = () => {
    navigate(SORTIE_PATH); // Rediriger vers la page d'entrée après suppression
  };

  // const source_paiement = [
  //   { id: "wave", value: "wave" },
  //   { id: "orange_money", value: "orange_money" },
  //   { id: "banque", value: "banque" }
  // ];
  const lieux = [
    { id: "bakeli_Mbour", value: "bakeli_Mbour" },
    { id: "bakeli_Thies", value: "bakeli_Thies" },
    { id: "bakeli_Dakar", value: "bakeli_Dakar" }
  ];
  return (
    <div className="container">
      <ToastContainer className="mt-5" />
      {showDeleteModal && (
        <DeleteEntree 
        showModal={showDeleteModal} 
        setShowModal={setShowDeleteModal}
        onDeleteSuccess={handleDeleteSuccess} // Passer la fonction de rappel comme prop
        />
      )}
      <div className="row rowEntree">
          <div className="col-12">
                <h4 className='entreeTitle'>Sortie / Detail</h4>
                <p className='entreeDescription'>Visualisez, modifiez ou supprimer une transaction</p>
          </div>
      </div>
      <div className='m-3'>
          <div className='row mt-3 mb-4'>
          <div className='d-flex'>
          <h6>Date de la transaction</h6>
          <span className='mx-5' style={{fontWeight:'700'}}>{date}</span>
        </div>
        <div className='d-flex'>
          <h6>Heure de la transaction</h6>
          <span className='mx-5' style={{fontWeight:'700'}}>{heure}</span>
        </div>
        <hr className='hr'/>
          </div>
          <div className='row  pb-4'>
            <div className='col-md-4 oooo col-sm-6'>
            <span className='nomme'>intitulé</span>
            <span className='donnee'>
              {editModes.intituele ? ( // Utilisez editModeIntitule pour déterminer si vous êtes en mode édition ou lecture
                <form onSubmit={handleSubmit(handleUpdate)}>
                  <input {...register("intituele")} defaultValue={currentEntree?.intituele} />
                </form>
              ) : (
                <span onClick={() => enableEditMode("intituele")}>
                  {currentEntree?.intituele ? currentEntree.intituele : "Pas de description pour ce category"}
                </span>
              )}
            </span>
            </div>
            <div className='col-md-4 col-sm-6'>
            <span className='nomme'>Categorie</span><br />
            <span className='donnee'>{currentEntree?.categorieEntreeSortie?.name}</span>
            </div>
            <div className='col-md-4 col-sm-6'>
            <span className='nomme'>Type</span><br />
            <span className='donnee'>{currentEntree?.categorieEntreeSortie?.type_transaction}</span>
            </div>
          </div>
          <div className='row pb-4 '>
          <div className='col-md-4 col-sm-6'>
            <span className='nomme'>Montant</span><br />
            <span className='donnee'>
              {editModes.montant ? ( // Utilisez editModeIntitule pour déterminer si vous êtes en mode édition ou lecture
                <form onSubmit={handleSubmit(handleUpdate)}>
                  <input {...register("montant")} defaultValue={currentEntree?.montant} />
                </form>
              ) : (
                <span onClick={() => enableEditMode("montant")}>
                  {currentEntree?.montant ? currentEntree.montant : "Pas de description pour ce category"}
                </span>
              )}
            </span>
            </div>
            <div className='col-md-4 col-sm-6'>
            <span className='nomme pb-3'>Moyen de paiement</span><br />
            <span className='donnee'>{currentEntree?.source_paiement}</span>
            </div>
            <div className='col-md-4 col-sm-6'>
            <span className='nomme'>Lieux</span><br />
            <span className='donnee'>
            {editModes.lieux ? (
              <form onBlur={handleSubmit(handleUpdate)}>
                <select {...register("lieux")} defaultValue={currentEntree?.lieux}>
                  {lieux.map((lieu) => (
                    <option key={lieu.id} value={lieu.value}>
                      {lieu.id}
                    </option>
                  ))}
                </select>
                {/* <button type="submit">Enregistrer</button>
                <button type="button" onClick={() => disableEditMode("lieux")}>Annuler</button> */}
              </form>
            ) : (
              <span onClick={() => enableEditMode("lieux")}>
                {currentEntree?.lieux ? currentEntree.lieux : "Pas de description pour ce category"}
              </span>
            )}
          </span>
            </div>
          </div>
          <div className='row'>
          <div className='col-md-4 col-sm-6'>
            <span className='nomme'>Compte comptable</span><br />
            <span className='donnee'>{currentEntree?.compte_comptable}</span>
            </div>
            <div className='col-md-4 col-sm-6'>
            <span className='nomme'>transaction effectué par</span><br />
            <span className='donnee'>{currentEntree?.user?.fullName}</span>
            </div>
          </div>
          <p className='paragrap'>Cette transaction peut contenir des erreurs. Vous pouvez directement modifier un champ ou la supprimer.</p>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      {/* Your other content */}
      <button
        style={{
          backgroundColor: '#F0443A',
          color: '#FFFFFF',
          borderRadius: '5px',
          fontWeight: '600',
          fontSize: '14px',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          padding: '10px 50px',
          border: 'none',
          fontStyle: 'normal',
          fontFamily: 'Montserrat',
        }}
        onClick={() => currentEntree && openDeleteModal(currentEntree)}
      >
        Supprimer
      </button>
          </div>
          </div>

    </div>
  );
};

export default DetailSortie;
