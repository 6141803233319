import React,{useEffect} from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import NavbarComponent from "../layouts/navbar/NavbarComponent";
import SidebarComponent from "../layouts/sidebar/SidebarComponent";
import SpliTemplateScreen from "../layouts/SplitTemplateScreen";
import { LOGIN_PATH } from "../utils/navigationPaths";
import { useSelector } from "react-redux";

function RequireAuth() {
  const location = useLocation();
  const currentUser = useSelector((state) => state?.users?.currentUser);
  const userStatus = useSelector((state) => state?.users?.currentUser?.status);
  useEffect(() => {
    // Si l'utilisateur est connecté, nous pouvons exécuter les appels AJAX ici
    // Par exemple, vous pouvez placer ici les appels Axios pour charger les données nécessaires à cette page.
    // Assurez-vous d'importer Axios ou d'utiliser la bibliothèque que vous utilisez pour les appels AJAX.

    // Exemple d'appel Axios (remplacez par votre propre appel) :
    // axios.get("/votre/api/endpoint")
    //   .then(response => {
    //     // Traitement de la réponse
    //   })
    //   .catch(error => {
    //     // Gestion des erreurs
    //   });

  }, [userStatus]);
  if (!currentUser) {
    // L'utilisateur n'est pas connecté, rediriger vers la page de connexion
    return <Navigate to={LOGIN_PATH} state={{ from: location }} replace />;
  }
  try {

    return (
      <SpliTemplateScreen>
        <NavbarComponent />
        <SidebarComponent userStatus={userStatus} />
        <Outlet />
      </SpliTemplateScreen>
    );
  } catch (error) {
    return <Navigate to={LOGIN_PATH} state={{ from: location }} replace />;
  }
}

export default RequireAuth;
