import axios from "axios"
import {
    addNewCategoryEntreeSlice, archiveCategoryEntreeSlice, setCategoryEntreesListSlice,
    updateCategoryEntreeSlice
} from "../reducers/categoryEntreeSlice.js"
import { store } from "../store"
import { CATEGORY_ENTREE_API_ROUTE, CATEGORY_ENTREE_BY_ID_API_ROUTE, CATEGORY_ENTREE_BY_USER_API_ROUTE } from "routes/apiRoutes.js"

const dispatch = store.dispatch
const categoryEntreesController = {
   
    getCategoryEntrees(userId) {
        return new Promise((resolve, reject) => {
            axios.get(CATEGORY_ENTREE_BY_USER_API_ROUTE + `${userId}`)
                .then(response => {
                    // console.log(response)
                    if (response.status === 200) {
                        
                        let data = response.data
                        dispatch(setCategoryEntreesListSlice(data))
                        resolve(data)
                    } else {
                        reject(response)
                    }
                })
                .catch(res => reject(res))
        })
    },
    addNewCategoryEntree(data) {
        return new Promise((resolve, reject) => {
            axios.post(CATEGORY_ENTREE_API_ROUTE, data)
                .then(response => {
                    // console.log(response.data.data)
                    if (response.status === 201) {
                        
                        let CategoryEntree = response.data.data
                        dispatch(addNewCategoryEntreeSlice(CategoryEntree))
                        resolve(CategoryEntree)
                    } else {
                        reject(response)
                    }
                })
                .catch(error => reject(error))
        })
    },
    updateCategoryEntree(CategoryEntreeId, data, userId) {
        return new Promise((resolve, reject) => {
            axios.put(CATEGORY_ENTREE_BY_ID_API_ROUTE + `${CategoryEntreeId}`, data)
                .then(response => {
                    //console.log(response)
                    if (response.status === 200) {
                       
                        let CategoryEntree = response.data
                        dispatch(updateCategoryEntreeSlice(CategoryEntree))
                        resolve(CategoryEntree)
                        this.getCategoryEntrees(userId)
                    } else {
                        reject(response)
                    }
                })
                .catch(error => reject(error))
        })
    },
    deleteCategoryEntree(categoryEntreeId, userId) {
        return new Promise((resolve, reject) => {
            axios.delete(`${CATEGORY_ENTREE_BY_ID_API_ROUTE + categoryEntreeId}`)
                .then(response => {
                    if (response.status === 204) {
                        resolve(response)
                        dispatch(archiveCategoryEntreeSlice(response))
                        this.getCategoryEntrees(userId)
                    } else {
                        reject(response)
                    }
                })
                .catch(error => reject(error))
        })
    },
}
export default categoryEntreesController