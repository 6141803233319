import CustomBootstrapTable from 'components/tables/CustomBootstrapTable'
import React, { useEffect, useState } from 'react'
import { AiFillPlusCircle } from 'react-icons/ai'
import { useSelector } from 'react-redux'
import ApiController from 'redux/apiController'
import AjoutUser from './AjoutUser'
import { toast,ToastContainer } from "react-toastify";
import { Spinner } from 'react-bootstrap';

const UsersPage = () => {
  
  const currentUser = useSelector(state => state.users?.currentUser)
  const listComptable = useSelector(state => state.comptableSlice?.comptablesList)
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);
  

useEffect(() => {
  setLoading(true);
  ApiController.comptablesController.getComptables(currentUser?.id).then(() => {
  
  }).catch((err) => {console.log(err)
  }).finally(() => {
    setLoading(false); // Fin du chargement, que ce soit un succès ou une erreur
  });
}, [currentUser.id])

const [buttonStates, setButtonStates] = useState({});
 // Mettez à jour cette fonction pour gérer l'archivage/désarchivage
 const handleActivate = (comptableId) => {
  ApiController.comptablesController.activeComptable(comptableId)
    .then(() => {
      refreshComptablesList();
      toast.success("Comptable activé!");
      updateButtonState(comptableId, false); // Désactiver le bouton "Activer"
    })
    .catch((err) => {
      console.log(err);
      toast.warning("Erreur d'activation!");
    });
};

const handleDeactivate = (comptableId) => {
  ApiController.comptablesController.deactiveComptable(comptableId)
    .then(() => {
      refreshComptablesList();
      toast.success("Comptable désactivé!");
      updateButtonState(comptableId, true); // Activer le bouton "Activer"
    })
    .catch((err) => {
      console.log(err);
      toast.warning("Erreur de désactivation!");
    });
};

const updateButtonState = (comptableId, isActive) => {
  setButtonStates((prevState) => ({
    ...prevState,
    [comptableId]: isActive,
  }));
};
  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };


  const columns = [
    {
      dataField: "fullName",
      text: "Nom complet ",
      headerClasses: 'custom-table-column',
    },
    {
      dataField: "email",
      text: "Email",
      headerClasses: 'custom-table-column',
    },
    {
      dataField: "phone",
      text: "Telephone ",
      headerClasses: 'custom-table-column',
    },
    {
      dataField: "status",
      text: "Status ",
      headerClasses: 'custom-table-column',
    },
    {
      dataField: 'is_active',
      text: 'Actions',
      formatter: (cell, row) => (
        <div>
          {buttonStates[row.id] ? (
            <button
              className="btn btn-success"
              onClick={() => handleActivate(row.id)}
            >
              Activer
            </button>
          ) : (
            <button
              className="btn btn-danger mx-2"
              onClick={() => handleDeactivate(row.id)}
            >
              Désactiver
            </button>
          )}
        </div>
      ),
    },
  ]
  const refreshComptablesList = () => {
    ApiController.comptablesController.getComptables(currentUser?.id)
      .then(() => {
        // console.log(res);
      })
      .catch((err) => console.log(err));
  };


  return (
    <div>
       <ToastContainer className='mt-5'/>
       <h4 className='dash m-0'>Listes des comptables </h4>
        <p className='lorem'>Vous pouvez ajouter, activer ou deactiver des comptables</p>
      <div className='row'>
        <div className='col-md-12'>
          <button className='ajoutEntree btn float-end  text-light' onClick={openModal}>
            <AiFillPlusCircle className="fs-4" /> Ajouter un Comptable</button>
        </div>
      <AjoutUser showModal={showModal} closeModal={closeModal} refreshComptablesList={refreshComptablesList}/>
      <div className="mt-3">
       {loading ? (
          <div className="text-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Chargement en cours...</span>
            </Spinner>
          </div>
        ) : (
          // Affiche la table de données si loading est faux
          listComptable?.length ? (
            <>
              <CustomBootstrapTable
                data={listComptable}
                columns={columns}
                showPaginator={true}
              />
            </>
          ) : <p>Pas de transactions disponible</p>
        )}
      </div>
      </div>
    </div>
  )
}

export default UsersPage