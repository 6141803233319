import axios from "axios";
import {
  addNewEntree,
  updateEntreeSlice,
  setEntreesList,
  archiveEntree,
} from "../reducers/entreesSlice.js";
import { store } from "../store";

const { TRANSACTIONS_API_ROUTE, ADD_TRANSACTIONS_API_ROUTE, DELETE_ENTREE_API_ROUTE, ADD_TRANSACTIONS_EXCEL_API_ROUTE, UPDATE_ENTREE_API_ROUTE, TRANSACTIONS_BY_ID_API_ROUTE, KPI_TRANSACTIONS_BY_PROPRIO_API_ROUTE } = require("../../routes/apiRoutes");

const dispatch = store.dispatch
const entreesApi = {
  getEntreesById(userId) {
    return new Promise((resolve, reject) => {
      axios
        .get(TRANSACTIONS_API_ROUTE + `${userId}`)
        .then((response) => {
          if (response.status === 200) {
            let data = response.data;
            dispatch(setEntreesList(data));
            resolve(data);
          } else {
            reject(response);
          }
        })
        .catch((res) => reject(res));
    });
  },

  registerEntree(data){
    return new Promise((resolve,reject)=>{
        axios.post(ADD_TRANSACTIONS_API_ROUTE,data)
        .then(response => {
            if(response.status ===201){
                let entree = response.data
                dispatch(addNewEntree(entree))
                resolve(entree)
            }
            
        })
        .catch(error => reject(error))
    })
},


registerEntreeExcel(data){
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append('file', data.file); // Assurez-vous d'ajuster la clé 'file' en fonction de votre API
    formData.append('createdBy', data.createdBy); 
    axios.post(ADD_TRANSACTIONS_EXCEL_API_ROUTE, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(response => {
        if (response.status === 201) {
          let entree = response.data;
          dispatch(addNewEntree(entree))
          resolve(entree);
        }
      })
      .catch(error => reject(error))
  })
},

updateEntree(data,id,userId, dispatch){
  return new Promise((resolve,reject)=>{
      axios.put(`${UPDATE_ENTREE_API_ROUTE + id}`,data)
      .then(response => {
        // console.log(response);
          if(response.status ===200){
            let entree = response.data.data
            dispatch(updateEntreeSlice(entree))
            // this.getEntreesById(userId)
            resolve(entree)
          }else{
              reject(response)
          }
      })
      .catch(error => reject(error))
  })
},

deleteEntree(id, userId){
  return new Promise((resolve,reject)=>{
      axios.delete(`${DELETE_ENTREE_API_ROUTE + id}`)
      .then(response => {
          if(response.status ===204){
            dispatch(archiveEntree(id))
              resolve(response)
            this.getEntreesById(userId)
          }else{
              reject(response)
          }
      })
      .catch(error => console.log(error))
  })
},

// get all transaction 
getAllTransaction(userId) {
  return new Promise((resolve, reject) => {
    axios
      .get(TRANSACTIONS_BY_ID_API_ROUTE + `${userId}` )
      .then((response) => {
        if (response.status === 200) {
          let data = response.data;
          dispatch(setEntreesList(data));
          resolve(data);
        } else {
          reject(response);
        }
      })
      .catch((res) => reject(res));
  });
},

// get kpi all transactions by proprietaire
getTransactionPrioprio(userId) {
  return new Promise((resolve, reject) => {
    axios
      .get(KPI_TRANSACTIONS_BY_PROPRIO_API_ROUTE + `${userId}`)
      .then((response) => {
        if (response.status === 200) {
          let data = response.data;
          dispatch(setEntreesList(data));
          resolve(data);
        } else {
          reject(response);
        }
      })
      .catch((res) => reject(res));
  });
},
};

export default entreesApi;
