import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';

const SearchBarWrapper = styled.div`
  position: relative;

  input {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 18.19865px;
    gap: 6.2px;
    width: 329.3px;
    height: 30.99px;
    background: #FFFFFF;
    border: 0.774832px solid #E7EAEE;
    border-radius: 3.09933px;
  }

  .search-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    color: #888;
    cursor: pointer;
  }
`;

const SearchBar = ({ placeholder, onSearch }) => {
  const handleSearch = (event) => {
    const searchTerm = event.target.value;
    onSearch(searchTerm);
  };
  
  return (
    <div className='col-md-12 searcch'>
    <SearchBarWrapper>
      <input
        type="text"
        placeholder={placeholder}
        onChange={handleSearch}
      />
      <FontAwesomeIcon icon={faSearch} className="search-icon" />
    </SearchBarWrapper>
    </div>
  );
};

export default SearchBar;
