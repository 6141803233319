import axios from "axios"
import {
    addNewComptableSlice, archiveComptableSlice, setComptablesListSlice,
    updateComptableSlice
} from "../reducers/comptableSlice.js"
import { store } from "../store"
import { ADD_COPMTABEL_API_ROUTE, COPMTABEL_API_ROUTE, ACTIVE_API_ROUTE, DESACTIVE_API_ROUTE, ACTIVATE_COMPTE_API_ROUTE, COMPTABLE_BY_ID_API_ROUTE, UPDATE_USER_API_ROUTE } from "routes/apiRoutes.js"

const dispatch = store.dispatch
const comptablesController = {
    getComptableById(comptableId) {
        return new Promise((resolve, reject) => {
            axios.get(COMPTABLE_BY_ID_API_ROUTE + `${comptableId}`)
                .then(response => {
                    if (response.status === 200) {
                        /**
                        * !setComptableList is from reducer.Comptable.js, just for Comptable
                        * !Don't forget to check your response content data type
                        */
                        let data = response.data
                        resolve(data)
                    }
                    else {
                        reject(response)
                    }
                })
                .catch(res => reject(res))
        })
    },
    getComptables(userId) {
        return new Promise((resolve, reject) => {
            axios.get(COPMTABEL_API_ROUTE + `${userId}`)
                .then(response => {
                    if (response.status === 200) {
                        console.log(response.data);
                        /**
                        * !setComptableList is from reducer.Comptable.js, just for Comptable
                        * !Don't forget to check your response content data type
                        */
                        let data = response.data
                        dispatch(setComptablesListSlice(data))
                        resolve(data)
                    } else {
                        reject(response)
                    }
                })
                .catch(res => reject(res))
        })
    },
    addNewComptable(data) {
        return new Promise((resolve, reject) => {
            axios.post(ADD_COPMTABEL_API_ROUTE, data)
                .then(response => {
                    if (response.status === 201) {
                        /**
                        * !addNewComptable is from reducer.Comptable.js, just for Comptable
                        * !Don't forget to check your response content data type
                        */
                        let Comptable = response.data.data
                        dispatch(addNewComptableSlice(Comptable))
                        resolve(Comptable)
                    } else {
                        reject(response)
                    }
                })
                .catch(error => reject(error))
        })
    },
    updateComptable(data, comptableId) {
        return new Promise((resolve, reject) => {
            axios.put(UPDATE_USER_API_ROUTE, `${comptableId}` ,data)
                .then(response => {
                    if (response.status === 200) {
                        let Comptable = response.data
                        dispatch(updateComptableSlice(Comptable))
                        resolve(Comptable)
                    } else {
                        reject(response)
                    }
                })
                .catch(error => reject(error))
        })
    },
    deleteComptable(comptableId) {
        return new Promise((resolve, reject) => {
            axios.delete(`${'******** PUT URL HERE ***********' + comptableId}/`)
                .then(response => {
                    if (response.status === 204) {
                        resolve(response)
                        dispatch(archiveComptableSlice(response))
                    } else {
                        reject(response)
                    }
                })
                .catch(error => reject(error))
        })
    },

    // activer un comptable
    activeComptable(comptableId) {
        return new Promise((resolve, reject) => {
          axios.put(ACTIVE_API_ROUTE + `${comptableId}`)
            .then(response => {
              if (response.status === 204) {
                resolve(response);
              } else {
                reject(response);
              }
            })
            .catch(error => reject(error));
        });
      },

    //   deactiver un comptable
      deactiveComptable(comptableId) {
        return new Promise((resolve, reject) => {
          axios.put(DESACTIVE_API_ROUTE + `${comptableId}`)
            .then(response => {
              if (response.status === 204) {
                resolve(response);
              } else {
                reject(response);
              }
            })
            .catch(error => reject(error));
        });
      },

    
      // Valider l'OTP et activer le comptable
  validateOTPAndActivate(comptableId, otpCode) {
    return new Promise((resolve, reject) => {
      axios
        .put(ACTIVATE_COMPTE_API_ROUTE + `${comptableId}`, { otp_code: otpCode }) // Envoyez l'OTP en tant que paramètre
        .then((response) => {
          if (response.status === 204) {
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => reject(error));
    });
  },
    
}
export default comptablesController