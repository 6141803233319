import React from 'react'
import '../layouts/sidebar/side.css'
import { useDispatch, useSelector } from "react-redux";
import iconDeconnect from "../img/Left icon.png"
import { useNavigate } from "react-router-dom";
import { LOGIN_PATH, PROFIL_PATH } from "utils/navigationPaths";
import { removeCurrentUser } from "redux/reducers/userSlice";

const ResourceDataMap = ({
    resourceData,
    resourceItem: ResourceItem,
    resourceName
}) => {

// Filtrer les liens en fonction du statut de l'utilisateur
  const currentUser = useSelector(state => state?.users.currentUser)

  const dispatch = useDispatch()
  const navigate = useNavigate()
     
  const handlLogout = () =>{
    
    if(currentUser){
      localStorage.clear()
      dispatch(removeCurrentUser())
      window.history.replaceState(null, "", LOGIN_PATH);
      navigate(LOGIN_PATH)
    }
  }

  const modifUser = () => {
    const userId = currentUser.id; 
    console.log(userId);
    const modifUser = PROFIL_PATH.replace(':id', userId);
    navigate(modifUser);
  }
  
    return (
        <>
            {
                resourceData.map((resource, i) => (
                    <ResourceItem key={i} {...{ [resourceName]: resource }} />
                ))
            }
                <hr className="hrProfil"/>
                <div style={{justifyContent:''}} className="prof">
                    <span className="mon-profil">Profile</span>
                    <form  onClick={() => modifUser()} style={{textDecoration:"none", listStyle:'none'}}>
                        <div className="d-flex mt-2 row">
                        {/* <div className="col-sm-3"> */}
                            {/* <img src={avatar} alt="avatare" style={{with:'10%'}}/> */}
                        {/* </div> */}
                        <div className="mx-2 col-sm-2 col-md-2">
                        <span className="profil_span"  style={{fontSize:'11px'}}> {currentUser.fullName}</span>
                        <span className="profil_span" style={{fontSize:'9px'}}> {currentUser.phone}</span>
                        <span className="profil_span"> {currentUser.email}</span>
                        </div>
                        </div>
                    </form>
                        <div className="row mt-3 mx-0">
                        {/* <div className="col-sm-3 col-md-2 col-lg-4"> */}
                        <button className="boutonDeconnect " type="button" onClick={handlLogout}> 
                        <img className="mx-2 col-sm-0" src={iconDeconnect} alt="icon"/>
                        <span className="visibles col-sm-5">Déconnexion</span>
                        </button>
                    </div>
                    {/* </div> */}

      </div>
        </>
    )
}

export default ResourceDataMap