import React from "react";
import { FaPlus } from 'react-icons/fa';

const InputLabel = ({ label, name, type, register, errors, options = [], value }) => {
  const isCategorieEntreeSortie = name === "categorie_entree_sortie";

  return (
    <div className="d-md-flex gap-10 p-2">
      <div className="w-25">
        <label htmlFor={name}>{label}</label>
      </div>
      <div className="w-100">
        {isCategorieEntreeSortie ? (
          <div className="input-with-icon" style={{display:'flex'}}>
            <select
              name={name}
              id={name}
              className={`form-control ${errors[name] ? "is-invalid" : ""}`}
              defaultValue="default"
              {...register(name, { required: `Ce champ est requis.` })}
            >
              <option value="default" disabled>
                Choisissez une {label.toLowerCase()}
              </option>
              {options.map((item) => (
                <option key={item.id} value={item.id} required>
                  {item.name}
                </option>
              ))}
            </select>
            <FaPlus className="plus-icon m-auto" />
          </div>
        ) : (
          type === "select" ? (
            <select
              name={name}
              id={name}
              className={`form-control ${errors[name] ? "is-invalid" : ""}`}
              defaultValue="default"
              {...register(name, { required: `Ce champ est requis.` })}
            >
              <option value="default" disabled>
                Choisissez une {label}
              </option>
                {options.map((item) => (
                <option key={item.id || item} value={item.id || item} required>
                {item.value || item}
              </option>
              ))}
            </select>
          ) : (
            <input
              type={type}
              name={name}
              id={name}
              className={`form-control ${errors[name] ? "is-invalid" : ""}`}
              {...register(name, {
                required: `Ce champ est requis.`,
                // Add other validation rules here
              })}
              value={value} // Ajoutez cette ligne pour définir la valeur du champ créé par l'utilisateur
            />
          )
        )}

        {errors[name] && (
          <span className="text-danger">{errors[name].message}</span>
        )}
      </div>
    </div>
  );
};

export default InputLabel;
