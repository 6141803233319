import React, { useState } from "react";
import logo from '../../img/unnamed 1.png'
import './nav.css'
import { Dropdown, Alert, Badge } from 'react-bootstrap';
import { FaBell, FaEnvelope } from 'react-icons/fa';
import { useSelector } from "react-redux";
// import { IoIosNotifications } from 'react-icons/io';

function NavbarComponent() {

  // Your existing code...

  const soldeData = useSelector((state) => state?.entreesSlice?.listEntrees);
  const solde = soldeData?.solde || 0; // Use 0 as the default value if soldeData is null or undefined
  // console.log(solde);
  const [showMessage, setShowMessage] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const handleAlert = (message) => {
    setAlertMessage(message);
    setShowMessage(true);
  };

  const handleCloseMessage = () => {
    setShowMessage(false);
  };

  // Custom close button for the Alert component
  const CustomCloseButton = ({ handleClose }) => (
    <span onClick={handleClose} style={{ cursor: 'pointer', color: '#fff', fontWeight: 'bold', fontSize: '18px' }}>
      &times;
    </span>
  );
  // const messageCount = useSelector(state => state?.messageCount);
  const [messagesCount] = useState(1);
  // Rest of your code...

  return (
    <div className="nav">
      <div className="img">
        <img src={logo} alt="logo"/>
        <h2 style={{fontWeight:'700', fontSize:'18px'}}>Fewnu Trésorerie</h2>
      </div>
      <div className="d-flex">
      {/* Show the alert badge */}
      {showMessage && (
        <Badge pill bg="danger" style={{ position: 'absolute', top: '-8px', right: '-8px' }}>
          New
        </Badge>
      )}
        <Dropdown className="">
        <Dropdown.Toggle id="alert-dropdown" className="border-secondary m-2" style={{ backgroundColor: 'transparent', border: 'none' }}>
            {solde < 0 && (
              <div className="notification-icon">
                 {messagesCount > 0 && (
                  <Badge pill bg="red" style={{ fontSize: '12px', fontWeight: 'bold', position: 'absolute', top: '5px', right: '2px', color:'red' }}>
                    {messagesCount}
                  </Badge>
                )}
              </div>
            )}
            <FaBell id="custom-icon" />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {/* Show the solde in notifications */}
            <Dropdown.Item onClick={() => {
              if (solde < 0) {
                handleAlert(`Votre solde est négatif : ${solde}`);
              } else {
                setShowMessage(false); // Réinitialiser showMessage à false si le solde n'est pas négatif
              }
            }}>
              {solde < 0 && <FaEnvelope className="badge-icon" style={{color:'red'}}/>}
               New Message
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleAlert('You have a new notification.')}>New Notification</Dropdown.Item>
            {/* Add more alert options here */}
          </Dropdown.Menu>

        </Dropdown>
      </div>

      {/* Show the alert message */}
      {showMessage && (
        <Alert
          variant="info" // Change the variant to match your custom styles
          onClose={handleCloseMessage}
          dismissible
          closeLabel={false} // Hide the default close button label
          closeVariant="white" // You can change the color of the default close button if needed
          closeComponent={CustomCloseButton} // Use the custom close butt
        >
          {alertMessage}
        </Alert>
      )}
    </div>
  );
}

export default NavbarComponent;
