import { FaHome, FaMoneyCheckAlt } from 'react-icons/fa';
import { AiFillPlusCircle,AiFillMinusCircle  } from 'react-icons/ai'
import { BiStats } from 'react-icons/bi';
import { BiCategory } from 'react-icons/bi';


export const links = [
  { path: "/accueil", icon: FaHome, label: "Dashboard", status: ["COMPTABLE"] },
  { path: "/entrees", icon: AiFillPlusCircle, label: "Entrées", status: ["COMPTABLE"]},
  { path: "/sorties", icon: AiFillMinusCircle, label: "Sorties", status: ["COMPTABLE"] },
  { path: "/categories-entree", icon: BiCategory, label: "Catégories Transactions", status: ["COMPTABLE"] },
  { path: "/statistique", icon: BiStats, label: "Statistiques", status: ["COMPTABLE"] },
  { path: "/dashboardAdmin", icon: FaHome, label: "Dahsboard", status: ["PROPRIETAIRE"] },
  { path: "/comptables", icon: FaMoneyCheckAlt, label: "Comptables", status: ["PROPRIETAIRE"] },
  { path: "/lsiteTransactions", icon: FaMoneyCheckAlt, label: "Transactions", status: ["PROPRIETAIRE"] },
  { path: "/statistique", icon: BiStats, label: "Statistiques", status: ["PROPRIETAIRE"] },
];

