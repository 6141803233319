import React, {useState, useEffect} from 'react';
import '../../home/home.css';
import { useDispatch, useSelector } from "react-redux";
import ApiController from "../../../redux/apiController";
import { LISTE_TRANSACTION_COMPTABLES_PATH, SETTING_PATH } from 'utils/navigationPaths';
import { Link } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import soldes from '../../../img/span.png';
import sorties from '../../../img/span (1).png';
import entrees from '../../../img/span1.png';
import transacrions from '../../../img/span (2).png';
import caisse from '../../../img/caisse.png';
import wave from '../../../img/wave.png';
import comptable from '../../../img/span (2).png';
import om from '../../../img/OM.png';
import banque from '../../../img/banque.png';
// import { useForm } from "react-hook-form";



const HomeAdminPage = () => {
  // const redirectTo = './accueil'
  const currentUser = useSelector(state => state.users?.currentUser)
  // const userStatus = useSelector((state) => state.users.currentUser.status);
  // const { register, handleSubmit,setValue, setError, formState: { errors }, reset } = useForm();

  const dispatch = useDispatch();
  // const navigat = useNavigate();

  const listTransactions = useSelector(state => state?.entreesSlice?.listEntrees?.data);
  // const currentEntree = useSelector(state => (state?.entreesSlice?.currentEntree));
  // montant total entree sortie solde
  // console.log(listTransactions.length)
  const solde = useSelector(state => state?.entreesSlice?.listEntrees?.solde?.toLocaleString());
  const montantEntree = useSelector(state => state?.entreesSlice?.listEntrees?.totalEntrees?.toLocaleString());
  const montantSortie = useSelector(state => state?.entreesSlice?.listEntrees?.totalSorties?.toLocaleString());
  const montantWave = useSelector(state => state?.entreesSlice?.listEntrees?.wave?.toLocaleString());
  const montantOM = useSelector(state => state?.entreesSlice?.listEntrees?.orange_money?.toLocaleString());
  const montantCaisse = useSelector(state => state?.entreesSlice?.listEntrees?.caisse?.toLocaleString());
  const montantBanque = useSelector(state => state?.entreesSlice?.listEntrees?.banque?.toLocaleString());
  const nombreTransactions = listTransactions?.length
  
// card show
  const [showAllCards] = useState(false); // État pour afficher toutes les cartes ou non
const listComptable = useSelector(state => state.comptableSlice?.comptablesList)
const listComptables = listComptable?.length
  // const navigate = useNavigate();
  useEffect(() => {
    ApiController.entreesApi.getTransactionPrioprio(currentUser?.id).then(() => {
      // console.log({res});
      // dispatch(setEntreesList(res));
      // dispatch(setCurrentEntree(res));
    });
  }, [dispatch]);

  
 
  // card data
  // Tableau de données des cartes
  const cardPadding= true
const cardsData = [
  {
    title: 'Solde total disponible',
    amount: solde,
    image: soldes,
    showCurrency: true,
    padding: cardPadding
  },
  {
    title: 'Entrées',
    amount: montantEntree,
    image: entrees,
    showCurrency: true,
    padding: cardPadding
  },
  {
    title: 'Sorties',
    amount: montantSortie,
    image: sorties,
    showCurrency: true,
    padding: cardPadding
  },
  {
    title: 'Nombre de transactions',
    amount: nombreTransactions,
    image: transacrions,
    padding: cardPadding,
    path:LISTE_TRANSACTION_COMPTABLES_PATH
},
  {
    title: 'Caisse',
    amount: montantCaisse,
    image: caisse,
    showCurrency: true,
    bgColor:'rgba(40, 199, 111, 1)',
    flexBetween: true,
    flexReverse:true
  },
  {
    title: 'Banque',
    amount: montantBanque,
    image: banque,
    showCurrency: true,
    bgColor:'rgba(27, 40, 80, 1)',
    flexBetween: true,
    flexReverse:true
    
  },
  {
    title: 'Wave',
    amount: montantWave,
    image: wave,
    showCurrency: true,
    bgColor:'rgba(29, 200, 255, 1)',
    flexBetween: true,
    flexReverse:true
  },
  {
    title: 'Orange Money',
    amount: montantOM,
    image: om,
    showCurrency: true,
    bgColor:'rgba(255, 159, 67, 1)',
    flexBetween: true,
    flexReverse:true
  },
  {
    title: 'Liste comptables',
    amount: listComptables,
    image: comptable,
    bgColor:'rgba(29, 200, 255, 1)',
    flexBetween: true,
    flexReverse:true,
    path:SETTING_PATH
  },
 
  // Ajoutez d'autres objets de carte ici
];
// couleur des dernier card 
  // const cardBackgroundColors  = ['rgba(40, 199, 111, 1)', 'rgba(27, 40, 80, 1)', 'rgba(29, 200, 255, 1)', 'rgba(255, 159, 67, 1)'];

const visibleCards = showAllCards ? cardsData : cardsData.slice(0, 10); // Affiche toutes les cartes ou seulement les 4 premières
  
//   const pagination = paginationFactory({
//     page: 1,
//     sizePerPageList: [{
//       text: '5', value: 5
//     },
//     {
//       text: '10', value: 10
//     },
//     {
//       text: '25', value: 25
//     },
//     {
//       text: 'Tous', value: 'produitsList'?.length
//     }]
//   });
  return (
    <div className='container-fluid'>
      <h4 className='dash m-0'>Dashboard</h4>
      {/* <p className='lorem'>Lorem ipsum dolor sit amet consectetur</p> */}
    <div className="col-md-12 top-5">
    <div className="row">
        {visibleCards.map((card, index) => (
          <div style={{height:'90px'}} className="col-md-3 col-sm-6 p-2" key={index} onClick={card.onClick}>
           <Link to={card.path} className='text-decoration-none'>
            <div style={{height:'100%'}} className="card cardcss bg-cherry">
              <div 
              className="card-body p-2 cardBody" 
              style={ {
                height:'100%',
                display: 'flex',
                flexDirection:card.flexReverse ? 'row-reverse' : 'row',
                justifyContent: card.flexBetween? 'space-between': 'flex-end',
                alignItems:'center', backgroundColor: card.bgColor,borderRadius: '10px' }
                }>
                <div className={`text-end ${card.padding ? 'pe-2' : null}`} >
                  <span className=" mb-0 montant-card" style={index >= cardsData.length - 4 ? { color:'#FFF' } : {}}>{card.amount}</span>
                    {card.showCurrency && <span className="currency montant-card" style={index >= cardsData.length - 4 ? { color:'#FFF' } : {}}> F</span>}
                  <div className="">
                    <span className="titre-card" style={index >= cardsData.length - 4 ? { color:'#FFF' } : {}}>{card.title}</span>
                  </div>
                </div>
                  <div className="">
                    <img className='mx-1' src={card.image} alt={card.title} />
                  </div>
              </div>
            </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
    </div>
  );
};


export default HomeAdminPage;

