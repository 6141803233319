// // SixDigitCodeInput.js
// import React from 'react';
// import styled from 'styled-components';

// const SixDigitCodeInput = ({ code, onChange, error }) => {
//   const handleChange = (e, index) => {
//     const newValue = e.target.value;
//     const newCode = [...code];
//     newCode[index] = newValue;
//     onChange(newCode);
//   };

//   return (
//     <Container>
//     <div className="six-digit-code-input mt-4 m-auto d-block">
//       {code.map((value, index) => (
//         <DigitInput
//           key={index}
//           value={value}
//           onChange={(e) => handleChange(e, index)}
//           type="text"
//           maxLength="1"
//           className={`digit-input ${error ? 'border-danger' : ''}`}
//         />
//       ))}
//       {error && <p className="custom-color-danger">{error}</p>}
//     </div>
//     </Container>
//   );
// };

// const DigitInput = styled.input`
//   /* Vos styles ici */
//   width: 40px;
//   height: 40px;
//   font-size: 18px;
//   margin: 4px;
//   text-align: center;
//   border: 1px solid #ccc;
//   border-radius: 4px;

 
//   ${({ error }) => error && `
//     border-color: #ff0000;
//   `}
// `;
// const Container = styled.div`
//   margin: auto; /* Appliquer la marge automatique */
//   text-align: center; /* Centrer le contenu à l'intérieur */
// `;
// export default SixDigitCodeInput;


import React, { useRef } from 'react';
import styled from 'styled-components';

const SixDigitCodeInput = ({ code, onChange, error }) => {
  const inputRefs = [useRef(), useRef(), useRef(), useRef(), useRef(), useRef()];

  const handleChange = (e, index) => {
    const newValue = e.target.value;
    const newCode = [...code];
    newCode[index] = newValue;
    onChange(newCode);

    // Déplacer le focus vers le champ suivant (si possible)
    if (index < inputRefs.length - 1 && newValue !== '') {
      inputRefs[index + 1].current.focus();
    }
  };

  return (
    <Container>
      <div className="six-digit-code-input mt-4">
        {code.map((value, index) => (
          <DigitInput
            key={index}
            ref={inputRefs[index]}
            value={value}
            onChange={(e) => handleChange(e, index)}
            type="number"
            maxLength="1"
            className={`digit-input ${error ? 'border-danger' : ''}`}
          />
        ))}
        {error && <p className="custom-color-danger">{error}</p>}
      </div>
    </Container>
  );
};

const DigitInput = styled.input`
  /* Vos styles ici */
  width: 40px;
  height: 40px;
  font-size: 18px;
  margin: 4px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 4px;

 
  ${({ error }) => error && `
    border-color: #ff0000;
  `}
`;

const Container = styled.div`
  margin: auto; /* Appliquer la marge automatique */
  text-align: center; /* Centrer le contenu à l'intérieur */
`;

export default SixDigitCodeInput;
