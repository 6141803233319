import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import './entree.css'
import { ENTREE_PATH } from 'utils/navigationPaths';


const Historique = () => {
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const currentEntree = useSelector((state) => state?.entreesSlice?.currentEntree);
  const date = moment(currentEntree?.date).format('YYYY-MM-DD');
  
  // const [ setShowDeleteModal] = useState(false);

  // const openDeleteModal = (item) => {
  //   dispatch(setCurrentEntree(item));
  //   setShowDeleteModal(true);
  // };

  // const handleDeleteSuccess = () => {
  //   navigate(ENTREE_PATH); // Rediriger vers la page d'entrée après suppression
  // };
  
  return (
    <div className="container">
      {/* <ToastContainer className="mt-5" />
      {showDeleteModal && (
        <DeleteEntree 
        showModal={showDeleteModal} 
        setShowModal={setShowDeleteModal}
        onDeleteSuccess={handleDeleteSuccess} // Passer la fonction de rappel comme prop
        />
      )} */}
      <div className="row rowEntree">
          <div className="col-12">
                <h4 className='entreeTitle'>historique</h4>
                <p className='entreeDescription'>historique de cette transaction</p>
          </div>
      </div>
      <div className='m-3'>
          <div className='row mt-3 mb-4'>
          <div className='d-flex'>
          <h6>Date de la transaction</h6>
          <span className='mx-5' style={{fontWeight:'700'}}>{date}</span>
        </div>
          </div>
       
          <div className='row'>
          <div className='col-md-4 col-sm-6'>
            <span className='nomme'>date de creation</span><br />
            <span className='donnee'>{currentEntree?.user?.created_at}</span>
            </div>
          <div className='col-md-4 col-sm-6'>
            <span className='nomme'>date de modification</span><br />
            <span className='donnee'>{currentEntree?.user?.updated_at}</span>
            </div>
           
          </div>
          <p className='paragrap'>Cette transaction a été modifiée par : <span className='donnees'>{currentEntree?.user?.fullName}.</span></p>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      {/* Your other content */}
      <button
        style={{
          backgroundColor: '#F0443A',
          color: '#FFFFFF',
          borderRadius: '5px',
          fontWeight: '600',
          fontSize: '14px',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          padding: '10px 50px',
          border: 'none',
          fontStyle: 'normal',
          fontFamily: 'Montserrat',
        }}
            onClick={() => navigate(ENTREE_PATH)}
      >
        RETOUR
      </button>
          </div>
          </div>

    </div>
  );
};

export default Historique;
