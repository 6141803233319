import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from "react-redux";
import './entree.css'
import { AiFillPlusCircle, AiOutlineDownload, AiOutlineUpload } from 'react-icons/ai'
import CustomBootstrapTable from "../../components/tables/CustomBootstrapTable";
import ApiController from "../../redux/apiController";
import { setCurrentEntree, setEntreesList } from "../../redux/reducers/entreesSlice";
import { toast,ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useForm } from "react-hook-form";
import { BsEyeFill } from 'react-icons/bs';
import { RiDeleteBinLine } from 'react-icons/ri';
import { FaHistory } from 'react-icons/fa'
import moment from 'moment';
import DeleteEntree from './DeleteEntree';
import * as XLSX from 'xlsx';
import { DETAIL_ENTREE_PATH, HISTORIQUE_PATH } from 'utils/navigationPaths';
import { useNavigate } from "react-router-dom";
import fichier from '../../img/Featured icon.png'
import { Spinner } from 'react-bootstrap';
import AddEntreeSortie from './AddEntreeSortie';
import DateFilter from 'components/filtrEntreDeuxDates';
import filterFactory, { dateFilter, textFilter, selectFilter  } from 'react-bootstrap-table2-filter';



const EntreePage = () => {
  const { register, handleSubmit, setValue } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedFormat, setSelectedFormat] = useState(''); // Format d'exportation sélectionné
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const listTransactions = useSelector(state => state?.entreesSlice?.listEntrees?.data) || [];
  console.log(listTransactions);
  const currentEntree = useSelector(state => (state?.entreesSlice?.currentEntree));
  const listTransactionsEntree = listTransactions?.filter(item => item.categorieEntreeSortie?.type_transaction === "entrees");
  const currentUser = useSelector(state => state?.users?.currentUser)

  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [filteredData, setFilteredData] = useState(listTransactionsEntree); // Créez un état pour stocker les données filtrées
  
  const openDeleteModal = (item) => {
    dispatch(setCurrentEntree(item))
    setShowDeleteModal(true)
  }
  
  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  // detail fonction ///////////////////////////////////////////
  const detailEntree = (item) => {
    dispatch(setCurrentEntree(item))
    const transactionId = item.id; // ou tout autre moyen d'obtenir l'ID de la commande
    const detailPath = DETAIL_ENTREE_PATH.replace(':id', transactionId);
    navigate(detailPath);
  }
  // historique ///////////////////////////////////////////
  const historiqueEntree = (item) => {
    dispatch(setCurrentEntree(item))
    const transactionId = item.id; // ou tout autre moyen d'obtenir l'ID de la commande
    const historiqueEntree = HISTORIQUE_PATH.replace(':id', transactionId);
    navigate(historiqueEntree);
  }
  // modification //////////////////////////////////////////////
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedColumn, setSelectedColumn] = useState(null);
  const [editMode, setEditMode] = useState(false);
  
  const closeRowForm = () => {
    setEditMode(false);
  };
  
  const openRowForm = (row, column) => {   
    setSelectedRow(row);
    setSelectedColumn(column);
    setValue('intituele', row.intituele);
    setValue('date', row.date);
    setValue('categorie_entree_sortie', row.categorie_entree_sortie);
    setValue('montant', row.montant);
    setValue('lieux', row.lieux);
    dispatch(setCurrentEntree(row))

    setEditMode(true);
  
  };
  const handleUpdate = (data) => {
    // Vérifier si l'utilisateur courant est l'auteur de l'entrée
    if (currentEntree && currentEntree.createdBy === currentUser.id) {
      ApiController.entreesApi.updateEntree(data, currentEntree?.id, currentUser.id, dispatch)
        .then(() => {
          toast.success("Modification réussie !");
        })
        .catch((e) => {
          console.log(e);
          toast.warning("Erreur lors de la modification !");
        });
    } else {
      toast.warning("Vous n'êtes pas autorisé à modifier cette entrée !");
      closeRowForm()
    }
  };

  // recuper les option des categories qui sont dans model
  // /const categoriesOption = useSelector(state => state?.entreesSlice?.listEntrees?.distinct_categories_entree);

  const categoriesOption = useSelector(state => state?.categoryEntreeSlice?.categoryEntreesList);
  let categoriesOptions = [];
  if (categoriesOption && categoriesOption?.length > 0) {
    categoriesOptions = categoriesOption.filter(item => item.type_transaction === 'entrees');
    // console.log(categoriesOptions);
  }

  const source_paiement = [
    { id: "wave", value: "wave" },
    { id: "orange_money", value: "orange_money" },
    { id: "banque", value: "banque" }
  ];
  const lieux = [
    { id: "bakeli_Mbour", value: "bakeli_Mbour" },
    { id: "bakeli_Thies", value: "bakeli_Thies" },
    { id: "bakeli_Dakar", value: "bakeli_Dakar" }
  ];
  // fin modification


 
  useEffect(() => {
    setIsLoading(true)
    ApiController.entreesApi.getAllTransaction(currentUser?.id).then((res) => {
      dispatch(setEntreesList(res));
    }).catch((error) => {
      console.error('Error fetching data:', error);
    })
    .finally(() => {
      setIsLoading(false); // Fin du chargement, que ce soit un succès ou une erreur
    })
  }, [dispatch,currentUser?.id]);


  // fonction enregistrement fichier excel
  const handleSaveExcel = (file) => {
    const newData = {
      file: file,
      createdBy:currentUser.id
    };
  
    ApiController.entreesApi
      .registerEntreeExcel(newData)
      .then(() => {
        toast.success('Ajout fichier excel réussi');
      })
      .catch(() => {
        toast.error("Erreur lors de l'ajout");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
   
  // importation excel
  const importFromExcel = (e) => {
    const file = e.target.files[0];
  
    const reader = new FileReader();
    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const workbook = XLSX.read(bstr, { type: 'binary' });
      const worksheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[worksheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
  
      // Validation des données et gestion des erreurs
      const errorMessages = [];
      const formattedData = jsonData.map((row, index) => {
        const [intituele, montant, categorie_entree_sortie, source_paiement, compte_comptable, crm, createdBy, lieux, date] = row;
  
        // Vérifiez si les champs requis sont présents
        if (!intituele || !montant || !categorie_entree_sortie || !source_paiement || !compte_comptable || !crm || !createdBy || !lieux || !date) {
          errorMessages.push(`Ligne ${index + 1}: Tous les champs doivent être remplis.`);
          return null;
        }
  
        // Autres validations spécifiques peuvent être ajoutées ici
        // Par exemple, vérification du format de date, validation de montant, etc.
  
        return {
          intituele,
          montant,
          categorie_entree_sortie,
          source_paiement,
          compte_comptable,
          crm,
          createdBy,
          lieux,
          date,
        };
      });
  
      // Gestion des erreurs
      if (errorMessages.length > 0) {
        errorMessages.forEach((message) => {
          toast.error(message);
        });
        return;
      }
  
      // Filtrer les lignes avec des données manquantes
      const filteredData = formattedData.filter((row) => row !== null);
  
      // Enregistrer les données si elles ne sont pas vides
      if (filteredData.length > 0) {
        handleSaveExcel(file, currentUser);
      } else {
        toast.warning("Aucune donnée valide à enregistrer.");
      }
    };
  
    reader.readAsBinaryString(file);
  };
  

// -------------------------  Exportation fichier excel -----------------------------

// export en format csv
  const exportToCSV = () => {
    const csvData = filteredData.map(item => ({
      Date: moment(item.date).format("DD/MM/YYYY"),
      // Date: moment(item.date),
      Intitulé: item.intituele,
      Catégorie: item.categorieEntreeSortie.name,
      "Moyen de paiement": item.source_paiement,
      "Compte Comptable": item.compte_comptable,
      Entrées: item.categorieEntreeSortie.type_transaction === 'entrees' ? item.montant : '',
      CRM: item.crm ? 'oui' : 'non',
      Par: item.user ? item.user.fullName : 'non défini',
      Lieu: item.lieux
    }));

    const worksheet = XLSX.utils.json_to_sheet(csvData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Entrées');
    XLSX.writeFile(workbook, 'entrees.csv');

    setSelectedFormat('');
  };
// export en format excel
  const exportToExcel = () => {
    const excelData = filteredData.map(item => ({
      Date: moment(item.date).format("DD/MM/YYYY"),
      "Intitulé": item.intituele,
      Catégorie: item.categorieEntreeSortie.name,
      "Moyen de paiement": item.source_paiement,
      "Compte Comptable": item.compte_comptable,
      Entrées: item.categorieEntreeSortie.type_transaction === 'entrees' ? item.montant : '',
      CRM: item.crm ? 'oui' : 'non',
      Par: item.user ? item.user.fullName : 'non défini',
      Lieu: item.lieux
    }));
    
    const worksheet = XLSX.utils.json_to_sheet(excelData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Entrées');
    XLSX.writeFile(workbook, 'entrees.xlsx');

    setSelectedFormat('');
  };

  const handleExport = () => {
    if (selectedFormat === 'csv') {
      exportToCSV();
    } else if (selectedFormat === 'excel') {
      exportToExcel();
    }
  };

  // exemplaire
  
const handleExportExemple = () => {
  // Créer un exemple de données pour le format Excel
  const exempleData = [
    {
      Intitulé: "Exemple Intitulé",
      Montant: "5000",
      Catégorie: "payement loyer",
      "Moyen de paiement": "wave",
      "Compte Comptable": "caisse",
      Type_transaction: "entrees",
      CRM: "oui",
      Lieu: "bakeli_Mbour",
      Date: "01-01-2023",
    },
    // Ajoutez plus d'exemples de données ici si nécessaire
  ];

  const worksheet = XLSX.utils.json_to_sheet(exempleData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Exemple Entrées');
  XLSX.writeFile(workbook, 'exemple_entrees.xlsx');
};
  // ------------------------- fin importation ---------------------------------------
  
  
  const columns = [
    {
      dataField: "intituele",
      text: "Intitulé ",
      headerClasses: 'custom-table-column',
      formatter: (cell, row) => {
        return (
          <div>
            <img className='mx-1' style={{width:"34px", height:"34px"}} src={fichier} alt={row.intituele} width="50" height="50" />
            {
            selectedRow === row && selectedColumn === "intituele" && editMode ? (
              <form onSubmit={handleSubmit(handleUpdate)}>
              <input
            {...register("intituele")}
              />
              {/* <button type="submit">Enregistrer</button> */}
            </form>
            ):      <span onClick={() => openRowForm(row, "intituele")}> {row.intituele ? row.intituele : "Pas de description pour ce category"}</span>

           }

          </div>
        );
      },
      filter: textFilter({
        placeholder: "Filtrer par intitulé", // Texte du champ de filtrage
        style: {
          width: '100px', 
          padding: '5px', 
          fontSize: '9px !importatnt',
          marginLeft: '2px',
        },
      }),
    },
    {
      dataField: "date",
      text: "Date",
      headerClasses: 'custom-table-column',
      filter: dateFilter({
        className: 'custom-date-filter',
        placeholder: 'Sélectionnez une date',
        getFilter: (filter) => {
          // Vérifiez si filter.date est une instance de Date
          if (filter.date instanceof Date) {
            filter.date = filter.date.toISOString().substr(0, 10); // Formatez au format "YYYY-MM-DD"
          }
        },
      }),
    formatter: (cell, row) => {
      // Vérifiez si row.date est une chaîne (par exemple, si elle vient de l'API)
      if (typeof row.date === 'string') {
        // Formatez la date au format "YYYY-MM-DD" pour l'élément input
        const formattedDate = row.date.substr(0, 10);
        return (
          <div>
            {
              selectedRow === row && selectedColumn === "date" && editMode ? (
                <form onBlur={handleSubmit(handleUpdate)}>
                  <input
                    type='date'
                    {...register("date")}
                    defaultValue={formattedDate}
                  />
                </form>
              ) : (
                <span onClick={() => openRowForm(row, "date")}> {formattedDate ? formattedDate : "Pas de description pour cette date"}</span>
              )
            }
            </div>
          );
        } else if (row.date instanceof Date) {
          // Formatez la date au format "YYYY-MM-DD" pour l'élément input
          const formattedDate = row.date.toISOString().substr(0, 10);
    
          return (
            <div>
              {
                selectedRow === row && selectedColumn === "date" && editMode ? (
                  <form onBlur={handleSubmit(handleUpdate)}>
                    <input
                      type='date'
                      {...register("date")}
                      defaultValue={formattedDate}
                    />
                  </form>
                ) : (
                  <span onClick={() => openRowForm(row, "date")}> {formattedDate ? formattedDate : "Pas de description pour cette date"}</span>
                )
              }
            </div>
          );
        } else {
          return (
            <div>
              {
                selectedRow === row && selectedColumn === "date" && editMode ? (
                  <form onBlur={handleSubmit(handleUpdate)}>
                    <input
                      type='date'
                      {...register("date")}
                    />
                  </form>
                ) : (
                  <span onClick={() => openRowForm(row, "date")}> {row.date ? row.date : "Pas de description pour cette date"}</span>
                )
              }
            </div>
          );
        }
      }
    },
    
    {
      dataField: "categorie_entree_sortie",
      text: "Catégorie",
      headerClasses: 'custom-table-column',
      formatter: (cell, row) => {
        return row.categorieEntreeSortie ? row.categorieEntreeSortie.name : "Non défini";
      },
      filter: selectFilter({
        options: categoriesOptions, // Utilisez les options que vous avez obtenues
        placeholder: "Sélectionnez une catégorie", // Texte du champ de filtrage
        style: {
          width: '100px', 
          padding: '5px', 
          fontSize: '9px',
        },
      }),
      editorRenderer: (editorProps, value, row) => {
        return (
          <div>
            {selectedRow === row && selectedColumn === "categorieEntreeSortie" && editMode ? (
              <form onSubmit={handleSubmit(handleUpdate)}>
                <select {...register("categorie_entree_sortie")}>
                  {categoriesOption && categoriesOption.length > 0 ? (
                    categoriesOption.map((option, id) => (
                      <option key={id} value={option.value}>
                        {option}
                      </option>
                    ))
                  ) : (
                    <option value="">Aucune option de catégorie disponible</option>
                  )}
                </select>
                <button type="submit">Enregistrer</button>
              </form>
            ) : (
              <span onClick={() => openRowForm(row, "categorieEntreeSortie")}>
                {row.categorieEntreeSortie ? row.categorieEntreeSortie.name : "Non défini"}
              </span>
            )}
          </div>
        );
      },
    },    
    {
      dataField: "montant",
      text: "Montant",
      headerClasses: 'custom-table-column',
      filter: textFilter({
        placeholder: "Filtrer par montant",
        style: {
          width: '100px', 
          padding: '5px', 
          fontSize: '9px', 
        },
        filterValue: (cell, row) => row.categorie_entree_sortie.toString(), // Convertissez la valeur numérique en chaîne pour le filtrage
      }),
      formatter: (cell, row) => {
        const commande = row;
        if (commande.categorieEntreeSortie?.type_transaction === 'entrees') {
          if (selectedRow === commande && selectedColumn === "montant" && editMode) {
            return (
              <form
                onSubmit={(e) => {
                  e.preventDefault(); // Empêche la soumission du formulaire qui provoque le rechargement de la page
                  handleSubmit(handleUpdate)(e); // Appelez votre gestionnaire de soumission ici
                }}
              >
                <input {...register("montant")} />
              </form>
            );
          } else {
            return (
              <span onClick={() => openRowForm(commande, "montant")} style={{color:'rgba(34, 253, 150, 1)'}}>
                {commande.montant.toLocaleString() ? commande.montant.toLocaleString()  : "Pas de description"} fcfa
              </span>
            );
          }
        } else {
          return null;
        }
      },
    },
    
    {
      dataField: "source_paiement",
      text: "Moyen de paiement",
      headerClasses: 'custom-table-column col-2',
      formatter: (cell, row) => {
        return (
          <div>
            {selectedRow === row && selectedColumn === "source_paiement" && editMode ? (
              <form onSubmit={handleSubmit(handleUpdate)}>
                <select {...register("source_paiement")} onBlur={handleSubmit(handleUpdate)}>
                  {source_paiement && source_paiement.length > 0 ? (
                    source_paiement.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))
                  ) : (
                    <option value="">Aucune option de transactions disponible</option>
                  )}
                </select>
              </form>
            ) : (
              <span onClick={() => openRowForm(row, "source_paiement" )} style={{ cursor: 'pointer'}}>{row.source_paiement}</span>
            )}
          </div>
        );
      },
      filter: selectFilter({
        options: source_paiement, // Utilisez les options que vous avez obtenues
        placeholder: "Sélectionnez une paiement", // Texte du champ de filtrage
        style: {
          width: '100px', 
          padding: '5px', 
          fontSize: '9px',
        },
      }),
    },
    {
      dataField: "lieux",
      text: "Lieu",
      headerClasses: 'custom-table-column col-2',
      formatter: (cell, row) => {
        return (
          <div>
            {selectedRow === row && selectedColumn === "lieux" && editMode ? (
              <form onSubmit={handleSubmit(handleUpdate)}>
                <select {...register("lieux")} onBlur={handleSubmit(handleUpdate)}>
                {lieux && lieux.length > 0 ? (
                  lieux.map((option) => (
                    <option key={option.id} value={option.id}>{option.value}</option>
                  ))
                ) : (
                  <option value="">Aucune option de transactions disponible</option>
                )}

                </select>
                {/* <button type="submit">Enregistrer</button> */}
              </form>
            ) : (
              <span onClick={() => openRowForm(row, "lieux" )} style={{ cursor: 'pointer'}}>{row.lieux}</span>
            )}
          </div>

        );
      },
      filter: selectFilter({
        options: lieux, // Utilisez les options que vous avez obtenues
        placeholder: "Sélectionnez une catégorie", // Texte du champ de filtrage
        style: {
          width: '100px', 
          padding: '5px', 
          fontSize: '9px',
        },
      }),
    },
    {
      dataField: "createdBy",
      text: "Par",
      headerClasses: 'custom-table-column col-1',
      formatter: (cell, row) => {
        if (row.user) {
          return row.user.fullName;
        } else {
          return "non defini";
        }
      },
      filter: textFilter({
        placeholder: "Filtrer par auteur", // Texte du champ de filtrage
        style: {
          width: '100px', 
          padding: '5px', 
          fontSize: '9px',
          marginLeft: '2px',
        },
      }),
    },
    {
        dataField: "action",
        text: "Actions",
        headerClasses: 'custom-table-column col-1',
        formatter: (cell, row) => {
          return (
            <>
              <div className="d-flex align-items-center">
                <BsEyeFill className=" btn-icon   me-2" size={17}
                   onClick={() => detailEntree(row)}  
                   style={{ cursor: 'pointer' }} />

                  <RiDeleteBinLine className="" size={17}
                   style={{ cursor: 'pointer' }}
                   onClick={() => openDeleteModal(row)}
                   /> 

                  <FaHistory  className=" mx-2" size={17}
                   style={{ cursor: 'pointer' }}
                   onClick={() => historiqueEntree(row)}
                   
                   
                   />
               
              </div>
            </>
          );
        },
    },
  ];
  
  const refresEntreeList = () => {
    ApiController.entreesApi.getAllTransaction(currentUser?.id)
      .then(() => {
      })
      .catch((err) => console.log(err));
  };

  // ...fitre entre deux date
  const handleDateChange = (newStartDate, newEndDate) => {
    setStartDate(newStartDate);
    setEndDate(newEndDate);
    // Mettez à jour les données filtrées en fonction des dates sélectionnées
    // Vous pouvez utiliser le même code de filtrage que dans votre composant précédent
    const updatedFilteredData = listTransactionsEntree.filter((item) => {
      const itemDate = new Date(item.date);
    
      if (!startDate && !endDate) {
        return true; // Si les dates de début et de fin ne sont pas définies, ne pas appliquer de filtrage
      }
    
      if (startDate && endDate) {
        return itemDate >= new Date(startDate) && itemDate <= new Date(endDate);
      }
    
      if (startDate) {
        return itemDate >= new Date(startDate);
      }
    
      if (endDate) {
        return itemDate <= new Date(endDate);
      }
    
      return true; // Par défaut, ne pas filtrer si aucune condition n'est remplie
    });
    
    setFilteredData(updatedFilteredData);
  };
// ...fin filtre entree deux date

  return (
    
    <div className="">
       <ToastContainer className='mt-5'/>
      {/* <AddEntreeSortie showModal={showModal} closeModal={closeModal} pageTypeTransaction="entrees" /> */}
      <AddEntreeSortie showModal={showModal} closeModal={closeModal} pageTypeTransaction="entrees" refresEntreeList={refresEntreeList}/>
      <DeleteEntree showModal={showDeleteModal} setShowModal={setShowDeleteModal}/>
        <div className="row rowEntree">
            <div className="col-12">
                <h4 className='entreeTitle'>Entrées</h4>
                {/* <p className='entreeDescription'>Lorem ipsum dolor sit amet consectetur</p> */}
            </div>
          </div>
                <div className="btns-entree">
                  <div className='btn-excel '>
                    <button className='mt-2 btn border' onClick={handleExport}>
                      <AiOutlineDownload />
                      
                      <select value={selectedFormat} onChange={e => setSelectedFormat(e.target.value)} style={{ marginLeft: '10px', border: 'none' }}>
                        <option value="">Exporter Excel / CSV</option>
                        <option value="excel">Excel</option>
                        <option value="csv">CSV</option>
                      </select>
                    </button>

                    <label className='mt-2 mx-1 btn border fileInput' htmlFor='fileInput'>
                      <AiOutlineUpload />
                      Importer Excel
                      <input
                        type='file'
                        id='fileInput'
                        accept='.csv, .xlsx, .xls'
                        onChange={importFromExcel}
                        style={{ display: 'none' }}
                      />
                    </label>
                    <button className=' mt-2 btn border' onClick={handleExportExemple}>
                      <AiOutlineDownload />
                      télécaharger l'éxamplaire Excel
                    </button>
                </div>
                    <div className='mt-2' >
                        <button className='ajoutEntree btn  text-light' onClick={openModal}>
                        <AiFillPlusCircle className="fs-4 " /> Ajouter une entrée</button>
                    </div>
            
                </div>
                {/* filtre */}
                <div className='mt-4'>
                  <DateFilter onDateChange={handleDateChange} />
                </div>
                <div className="mt-4">
                {isLoading ? (
                  <div className="text-center">
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Chargement en cours...</span>
                    </Spinner>
                  </div>
                ) : (
                  // Affiche la table de données si loading est faux
                  listTransactionsEntree?.length ? (
                    <>
                      <CustomBootstrapTable
                        keyField="id"
                        data={filteredData}
                        columns={columns}
                        filter={filterFactory()} // Activez le filtre global
                        // striped
                        // hover
                        condensed
                        wrapperClasses="table-wrapper" // Ajoutez cette classe
                      />
                    </>
                  ) : <p>Pas de transactions disponibles</p>
                )}
              </div>
          </div>
  )
}

export default EntreePage