
import users from './users';
import entreesApi from './entreesApi'
import categoryEntreesController from './categoryEntreeApi';
import comptablesController from './comptableApi';


const ApiController = {
   users,
   entreesApi,
   categoryEntreesController,
   comptablesController
}
export default ApiController