import React, { useState } from 'react'
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { FaPlus } from 'react-icons/fa';
import { FiAlertTriangle } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import ApiController from 'redux/apiController';


const AjoutUser = ({showModal, closeModal, refreshComptablesList }) => {
    const {register,handleSubmit,setError,formState:{errors},reset} = useForm()
  const [isLoading] = useState(false)
    const currentUser = useSelector(state => state.users?.currentUser)
  // const [loading, setLoading] = useState(true);

    const handeleSave = (data) => {
        // Vérifier si l'email et le numéro de téléphone existent déjà dans la base de données
        ApiController.comptablesController.getComptables(currentUser.id)
          .then((comptables) => {
            const existingComptableWithEmail = comptables.find(comptable => comptable.email === data.email);
            const existingComptableWithPhone = comptables.find(comptable => comptable.phone === data.phone);
      
            if (existingComptableWithEmail) {
              setError('email', { message: 'Cet utilisateur avec cet email existe déjà' });
            }
      
            if (existingComptableWithPhone) {
              setError('phone', { message: 'Cet utilisateur avec ce numéro existe déjà' });
            }
      
            if (!existingComptableWithEmail && !existingComptableWithPhone) {
              const newData = { ...data, createdBy: currentUser.id };
              ApiController.comptablesController.addNewComptable(newData)
                .then(() => {
                  closeModal();
                  toast.success("Ajout réussi !");
                  refreshComptablesList();
                  reset()
                })
                .catch((err) => {
                    console.log(err)
                    toast.error("Ajout non réussi ");
                });
                
            }
          })
          .catch((err) => console.log(err));
      };

    const verificate  = (data) => {
        const {fullName,phone,email} = data
        if(fullName === ''){
            setError('firstName',{message:'Veuillez mettre le nom complet de l\'utilisateur'})
        }else if(email === ''){
            setError('email',{message:'L\'email n\'esst pas valid'})
        }else if(phone === ''){
            setError('phone',{message:'Le numéro n\'esst pas valid'})
        }else{
            handeleSave(data)
        }
    };


  return (
    <>
        <Modal show={showModal} onHide={closeModal} size="lg">
                <Modal.Header closeButton style={{ background: "rgba(67, 68, 69, 1)", color: "#FFFFFF" }}>
                    <Modal.Title style={{ marginLeft: "270px" }}> 
                    <div className="text-center">
                    <FaPlus /> Ajouter un Comptable
                    </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <form onSubmit={handleSubmit(verificate)} className=''>
                    <div>
                        <input {...register("fullName",{required:'Veuillez saisir le nom complet'})} type="text" placeholder='Nom complet' className='form-control px-2'/>
                        {errors.fullName && <p className='text-danger'><FiAlertTriangle/> {errors.fullName.message}</p>}

                        <input {...register("email",{required:'Veuillez saisir votre nom'})} type="email" placeholder='Email' className=' form-control mt-3 px-2'/>
                        {errors.email && <p className='text-danger'><FiAlertTriangle/> {errors.email.message}</p>}

                        <input {...register("phone",{required:'Veuillez saisir votre numéro'})} type="number" placeholder='Numéro de téléphone' className=' form-control mt-3 px-2'/>
                        {errors.phone && <p className='text-danger'><FiAlertTriangle/> {errors.phone.message}</p>}

                        <input {...register("adresse",{required:'Veuillez saisir votre adresse'})} type="text" placeholder='Adresse' className='form-control mt-3 px-2'/>
                        {/* {errors.adresse && <p className='text-danger'><FiAlertTriangle/> {errors.adresse.message}</p>} */}
                        

                        {/* <input {...register("password",{required:'Veuillez saisir un mot de passe'})} type="password" placeholder='Mot de passe' className=' form-control mt-3 px-2' />
                        {errors.password && <p className='text-danger'><FiAlertTriangle/> {errors.password.message}</p>} */}

                    

                        <button disabled={isLoading} type='submit' style={{ background: "rgba(67, 68, 69, 1)", color: "#FFFFFF" }} className="login-btn row m-0 p-0 mt-5 align-items-center font-Montserrat">
                            <span className='col-2'></span>
                            <span className='col-8'>Enregistrer</span>
                            <span className='col-2'>
                                <div style={{width:'20px',height:"20px"}} className={isLoading?"spinner-border fs-6 mt-1":"d-none"} role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </span>
                        </button>  
                        {/* <button
                style={{
                  backgroundColor: "rgba(0, 190, 201, 1)",
                  color: "#FFFFFF",
                  borderRadius: "5px",
                  fontWeight: "600",
                  fontSize: "14px",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                  padding: "10px 50px",
                  border: "none",
                  fontStyle: "normal",
                  fontFamily: "Montserrat",
                  marginLeft: "6px"
                }}
                type='submit'
                disabled={isLoading}
              >
                {isLoading ? (
                  <div className="spinner-border text-light" role="status">
                  </div>
                ) : 'Valider'}
              </button>                       */}
                    </div>
                </form>

                </Modal.Body>

        </Modal>
    </>
  )
}

export default AjoutUser