import moment from 'moment';
import 'react-tabs/style/react-tabs.css';
import 'moment/locale/fr';

export const generateChartDataMois = (listTransactions, selectedYear) => {
  const data = {
    labels: [],
    datasets: [
      {
        label: 'entrées',
        data: [],
        backgroundColor: 'rgba(34, 253, 150, 1)',
        borderColor: 'rgba(34, 253, 150, 1)',
        fill: false,
        tension: 0.1,
        borderRadius: 10,
        barThickness: 15,
      },
      {
        label: 'sorties',
        data: [],
        backgroundColor: 'rgba(221, 27, 27, 1)',
        borderColor: 'rgba(221, 27, 27, 1)',
        fill: false,
        tension: 0.1,
        borderRadius: 10,
        barThickness: 15,
      },
    ],
  };

  const availableYears = Array.from(new Set(listTransactions.map((transaction) => moment(transaction.date).format('YYYY'))));
  // Récupérer l'année actuelle si aucune année sélectionnée n'est fournie
  const currentYear = selectedYear || availableYears[0];

  // Générer la liste de tous les mois de l'année
  const monthsOfYear = moment.months().map((month) => `${month} ${currentYear}`);
 // Filtrer les transactions par année sélectionnée
 const filteredTransactions = listTransactions.filter((transaction) => {
  const transactionYear = moment(transaction.date).format('YYYY');
  return transactionYear === selectedYear;
});
  // Regrouper les transactions par mois en filtrant par année sélectionnée
  const groupedTransactions = {};
  filteredTransactions.forEach((transaction) => {
    const transactionYear = moment(transaction.date).format('YYYY');
    if (transactionYear === currentYear) {
      const month = moment(transaction.date).format('MMMM YYYY');
      if (!groupedTransactions[month]) {
        groupedTransactions[month] = {
          entrees: 0,
          sorties: 0,
        };
      }
      if (transaction.categorieEntreeSortie?.type_transaction === 'entrees') {
        groupedTransactions[month].entrees += transaction.montant;
      } else if (transaction.categorieEntreeSortie?.type_transaction === 'sorties') {
        groupedTransactions[month].sorties += transaction.montant;
      }
    }
  });

  moment.locale('fr');

  monthsOfYear.forEach((month) => {
    const formattedMonth = moment(month, 'MMMM YYYY').format('MMMM YYYY');
    data.labels.push(formattedMonth);
    data.datasets[0].data.push(groupedTransactions[month]?.entrees || 0);
    data.datasets[1].data.push(groupedTransactions[month]?.sorties || 0);
  });

  return data;
};

