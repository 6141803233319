import moment from 'moment';
import 'moment/locale/fr';

export const generateChartDataTrimestre = (listTransactions, selectedYear) => {
    const data = {
      labels: [],
      datasets: [
        {
          label: 'Entrées',
          data: [],
          backgroundColor: 'rgba(34, 253, 150, 1)',
          borderColor: 'rgba(34, 253, 150, 1)',
          fill: false,
          tension: 0.1,
          borderRadius: 10,
          barThickness: 15,
        },
        {
          label: 'Sorties',
          data: [],
          backgroundColor: 'rgba(221, 27, 27, 1)',
          borderColor: 'rgba(221, 27, 27, 1)',
          fill: false,
          tension: 0.1,
          borderRadius: 10,
          barThickness: 15,
        },
      ],
    };
  // Filtrer les transactions par année sélectionnée
  const filteredTransactions = listTransactions.filter((transaction) => {
    const transactionYear = moment(transaction.date).format('YYYY');
    return transactionYear === selectedYear;
  });
    // Regrouper les transactions par trimestre
    const groupedTransactions = {};
    filteredTransactions.forEach((transaction) => {
      const quarter = moment(transaction.date).quarter();
      const year = moment(transaction.date).year();
      const key = `${quarter}-${year}`;
      if (!groupedTransactions[key]) {
        groupedTransactions[key] = {
          entrees: 0,
          sorties: 0,
        };
      }
      if (transaction.categorieEntreeSortie?.type_transaction === 'entrees') {
        groupedTransactions[key].entrees += transaction.montant;
      } else if (transaction.categorieEntreeSortie?.type_transaction === 'sorties') {
        groupedTransactions[key].sorties += transaction.montant;
      }
    });
  
    moment.locale('fr');
  
    const sortedQuarters = Object.keys(groupedTransactions).sort((a, b) => {
      const [quarterA, yearA] = a.split('-');
      const [quarterB, yearB] = b.split('-');
      return moment(`${yearA}-${quarterA}`, 'YYYY-Q').toDate() - moment(`${yearB}-${quarterB}`, 'YYYY-Q').toDate();
    });
  
    sortedQuarters.forEach((quarter) => {
      const [quarterValue, yearValue] = quarter.split('-');
      const formattedQuarter = `T${quarterValue} ${yearValue}`;
      data.labels.push(formattedQuarter);
      data.datasets[0].data.push(groupedTransactions[quarter].entrees);
      data.datasets[1].data.push(groupedTransactions[quarter].sorties);
    });
  
    return data;
  };
  

  