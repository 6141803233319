import { store } from "../store"
import axios from 'axios'

const { REGISTER_API_ROUTE, LOGIN_API_ROUTE, UPDATE_USER_API_ROUTE, USER_API_ROUTE } = require("../../routes/apiRoutes")
const { setCurrentUser, setUsersList, updateUserSlice } = require("../reducers/userSlice")


const dispatch = store.dispatch

const users = {
    getUsersList(){
        return new Promise((resolve,reject)=>{
            axios.get(USER_API_ROUTE)
            .then(response => {
                // console.log(response);
                if(response.status ===200){
                    let users = response.data
                    dispatch(setUsersList(users))
                    resolve(users)
                }else{
                    reject(response)
                }
            })
            .catch(error => reject(error))
        })
    },
    registerUser(data){
        return new Promise((resolve,reject)=>{
            axios.post(REGISTER_API_ROUTE,data)
            .then(response => {
                if(response.status ===200){
                    let user = response.data
                    resolve(user)
                }else{
                    reject(response)
                }
            })
            .catch(error => reject(error))
        })
    },
    loginUser(data){
        return new Promise((resolve,reject)=>{
            axios.post(LOGIN_API_ROUTE,data)
            .then(response => {
                if(response.status === 200){
                    let user = response.data
                    let userData = {
                        id:user.id,
                        firstName:user.firstName,
                        lastName:user.lastName,
                        phone:user.phone,
                        email:user.email,
                        token:user.token,
                    }
                    localStorage.setItem('currentUser',JSON.stringify(userData))
                    
                    dispatch(setCurrentUser(user))
                    resolve(user)
                }else{
                    reject(response)
                }
            })
            .catch(err => reject(err))
        })
    },
    updateUser(data, userId) {
        return new Promise((resolve, reject) => {
            axios.put(`${UPDATE_USER_API_ROUTE + userId}`,data)
                .then(response => {
                    console.log(response)
                    if (response.status === 200) {
                        let data = response.data
                        dispatch(updateUserSlice(data))
                        resolve(data)
                        this.getUsersList(userId)
                    } else {
                        reject(response)
                    }
                })
                .catch(error => reject(error))
        })
    },
}

export default users