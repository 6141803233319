import React, { useEffect, useState } from 'react'
import './sortie.css'
import { AiFillPlusCircle, AiOutlineDownload, AiOutlineUpload } from 'react-icons/ai'
import moment from 'moment';
import { BsEyeFill } from 'react-icons/bs';
import { RiDeleteBinLine } from 'react-icons/ri';
import { useDispatch, useSelector } from "react-redux"
import ApiController from "../../redux/apiController";
import CustomBootstrapTable from "../../components/tables/CustomBootstrapTable";
import { useForm } from "react-hook-form";
import { toast,ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { setCurrentEntree,setEntreesList } from "../../redux/reducers/entreesSlice";
import DeleteEntree from '../entrees/DeleteEntree';
import { DETAIL_SORTIE_PATH, HISTORIQUE_PATH } from 'utils/navigationPaths';
import { useNavigate } from "react-router-dom";
import fichier from '../../img/Featured icon.png';
import * as XLSX from 'xlsx';
import { Spinner } from 'react-bootstrap';
import AddEntreeSortie from 'pages/entrees/AddEntreeSortie';
import DateFilter from 'components/filtrEntreDeuxDates';
import { FaHistory } from 'react-icons/fa';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';


const SortiePage = () => {
  const currentEntree = useSelector(state => (state?.entreesSlice?.currentEntree));
  const { register, handleSubmit, setValue } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
 
  const listTransactions = useSelector(state => state?.entreesSlice?.listEntrees?.data) || [];
  const listTransactionsSortie = listTransactions?.filter(item => item.categorieEntreeSortie?.type_transaction === "sorties")
  // console.log({TransactionsSortie:listTransactionsSortie});

  // const [startDate, setStartDate] = useState('');
  // const [endDate, setEndDate] = useState('');
  const [filteredData, setFilteredData] = useState(listTransactionsSortie); // Créez un état pour stocker les données filtrées

  const currentUser = useSelector(state => state?.users?.currentUser)
  const dispatch = useDispatch();

  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const openDeleteModal = (item) => {
    dispatch(setCurrentEntree(item))
    setShowDeleteModal(true)
  }

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
// detail
  const detailSortie = (item) => {
    dispatch(setCurrentEntree(item))
    const transactionId = item.id; // ou tout autre moyen d'obtenir l'ID de la commande
    const detailSortie = DETAIL_SORTIE_PATH.replace(":id", transactionId);
    navigate(detailSortie);
  }
  // historique ///////////////////////////////////////////
  const historiqueSortie = (item) => {
    dispatch(setCurrentEntree(item))
    const transactionId = item.id; // ou tout autre moyen d'obtenir l'ID de la commande
    const historiqueSortie = HISTORIQUE_PATH.replace(':id', transactionId);
    navigate(historiqueSortie);
  }

   // modification //////////////////////////////////////////////
   const [selectedRow, setSelectedRow] = useState(null);
   const [selectedColumn, setSelectedColumn] = useState(null);
   const [editMode, setEditMode] = useState(false);
   const [selectedFormat, setSelectedFormat] = useState(''); // Format d'exportation sélectionné

   const closeRowForm = () => {
    setEditMode(false);
  };

   const openRowForm = (row, column) => {   
     setSelectedRow(row);
     setSelectedColumn(column);
     setValue('intituele', row.intituele);
     setValue('date', row.date);
     setValue('categories', row.categories);
     setValue('montant', row.montant);
     setValue('lieux', row.lieux);
     dispatch(setCurrentEntree(row))
 
     setEditMode(true);
   
     // console.log(row)
   };

   const handleUpdate = (data) => {
    // Vérifier si l'utilisateur courant est l'auteur de l'entrée
    if (currentEntree && currentEntree.createdBy === currentUser.id) {
      ApiController.entreesApi.updateEntree(data, currentEntree?.id, currentUser.id, dispatch)
        .then(() => {
          toast.success("Modification réussie !");
        })
        .catch((e) => {
          console.log(e);
          toast.warning("Erreur lors de la modification !");
        });
    } else {
      toast.warning("Vous n'êtes pas autorisé à modifier cette entrée !");
      closeRowForm()
    }
  };
  
 
   // recuper les option des categories qui sont dans model

  const categoriesOption = useSelector(state => state?.categoryEntreeSlice?.categoryEntreesList);
  let categoriesOptions = [];
  if (categoriesOption && categoriesOption?.length > 0) {
    categoriesOptions = categoriesOption.filter(item => item.type_transaction === 'sorties');
    console.log(categoriesOptions);
  }
  const source_paiement = [
    { id: "wave", value: "wave" },
    { id: "orange_money", value: "orange_money" },
    { id: "banque", value: "banque" }
  ];
  const lieux = [
    { id: "bakeli_Mbour", value: "bakeli_Mbour" },
    { id: "bakeli_Thies", value: "bakeli_Thies" },
    { id: "bakeli_Dakar", value: "bakeli_Dakar" }
  ];
  // fin modification
 

  useEffect(() => {
    setIsLoading(true)
    ApiController.entreesApi.getAllTransaction(currentUser?.id).then((res) => {
      dispatch(setEntreesList(res));
    }).catch((error) => {
      console.error('Error fetching data:', error);
    })
    .finally(() => {
      setIsLoading(false); // Fin du chargement, que ce soit un succès ou une erreur
    })
  }, [dispatch,currentUser?.id]);

    const handleSaveExcel = (file) => {
      setIsLoading(true)
      const newData = {
        file: file,
        createdBy:currentUser.id
      };
    
      ApiController.entreesApi
        .registerEntreeExcel(newData)
        .then(() => {
          toast.success('Ajout fichier excel réussi');
        })
        .catch((err) => {
          console.log(err);
          toast.error("Erreur lors de l'ajout");
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    
  
    // importation excel
    const importFromExcel = (e) => {
      const file = e.target.files[0]; // Sélectionnez un fichier
      // console.log(file);
      const reader = new FileReader(); // Un objet FileReader est créé pour lire le contenu du fichier
      reader.onload = (evt) => { // Cette fonction sera appelée lorsque la lecture du fichier sera terminée
        const bstr = evt.target.result; // Le contenu du fichier est lu et stocké sous forme de chaîne binaire (bstr)
        const workbook = XLSX.read(bstr, { type: 'binary' }); // XLSX.read est utilisé pour analyser la chaîne binaire et obtenir un objet workbook représentant le classeur Excel
        const worksheetName = workbook.SheetNames[0]; // On récupère le nom de la première feuille de calcul du classeur
        const worksheet = workbook.Sheets[worksheetName]; // La feuille de calcul correspondante est extraite du classeur
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 }); // XLSX.utils.sheet_to_json est utilisée pour convertir la feuille de calcul en un tableau JSON
        // console.log("File data:", jsonData);
        // Traitement des données
        const formattedData = jsonData.map((row) => { // Les données sont traitées et formatées
          const [intitule, montant, categorie_entree_sortie, source_paiement, compte_comptable, crm,createdBy, lieux, date] = row;
          
          // Vérifier si les champs requis sont présents
          if (!intitule || !montant || !categorie_entree_sortie || !source_paiement || !compte_comptable || !crm ||!createdBy || !lieux || !date ) {
            return 
            // console.log(intitule, montant, categories, source_paiement, compte_comptable, type_transaction, crm,createdBy, lieux, date);
          }
    
          return {
            intitule, montant, categorie_entree_sortie, source_paiement, compte_comptable, crm,createdBy, lieux, date
            // Ajoutez les autres propriétés en fonction de votre fichier Excel
          };
        });
    
        // Filtrer les lignes avec des données manquantes
        const filteredData = formattedData.filter((row) => row !== null); // Les données formatées sont filtrées pour éliminer les lignes qui sont null
    
        // Enregistrer les données si elles ne sont pas vides
        if (filteredData.length > 0) {
          handleSaveExcel(file, currentUser);
          // console.log({handleSaveExcel:handleSaveExcel(file)});
        } else {
          // console.log('Aucune donnée à enregistrer.');
        }
        // console.log({jsonData:jsonData});
      };
      reader.readAsBinaryString(file);
    };

    //  -------------------------  Exportation fichier excel -----------------------------

    // export en format csv
  const exportToCSV = () => {
    const csvData = filteredData.map(item => ({
      Date: moment(item.date).format("DD/MM/YYYY"),
      // Date: moment(item.date),
      Intitulé: item.intituele,
      Catégorie: item.categorieEntreeSortie.name,
      "Moyen de paiement": item.source_paiement,
      "Compte Comptable": item.compte_comptable,
      Entrées: item.categorieEntreeSortie.type_transaction === 'entrees' ? item.montant : '',
      CRM: item.crm ? 'oui' : 'non',
      Par: item.user ? item.user.fullName : 'non défini',
      Lieu: item.lieux
    }));

    const worksheet = XLSX.utils.json_to_sheet(csvData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Entrées');
    XLSX.writeFile(workbook, 'entrees.csv');

    setSelectedFormat('');
  };
// export en format excel
  const exportToExcel = () => {
    const excelData = filteredData.map(item => ({
      Date: moment(item.date).format("DD/MM/YYYY"),
      "Intitulé": item.intituele,
      Catégorie: item.categorieEntreeSortie.name,
      "Moyen de paiement": item.source_paiement,
      "Compte Comptable": item.compte_comptable,
      Entrées: item.categorieEntreeSortie.type_transaction === 'entrees' ? item.montant : '',
      CRM: item.crm ? 'oui' : 'non',
      Par: item.user ? item.user.fullName : 'non défini',
      Lieu: item.lieux
    }));
    
    const worksheet = XLSX.utils.json_to_sheet(excelData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Entrées');
    XLSX.writeFile(workbook, 'entrees.xlsx');

    setSelectedFormat('');
  };

  const handleExport = () => {
    if (selectedFormat === 'csv') {
      exportToCSV();
    } else if (selectedFormat === 'excel') {
      exportToExcel();
    }
  };
    
      // exemplaire
      
    const handleExportExemple = () => {
      // Créer un exemple de données pour le format Excel
      const exempleData = [
        {
          Intitulé: "Exemple Intitulé",
          Montant: "5000",
          Catégorie: "payement loyer",
          "Moyen de paiement": "wave",
          "Compte Comptable": "caisse",
          Type_transaction: "entrees",
          CRM: "oui",
          Lieu: "bakeli_Mbour",
          Date: "01-01-2023",
        },
        // Ajoutez plus d'exemples de données ici si nécessaire
      ];
    
      const worksheet = XLSX.utils.json_to_sheet(exempleData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Exemple Entrées');
      XLSX.writeFile(workbook, 'exemple_entrees.xlsx');
    };
      // ------------------------- fin importation ---------------------------------------
      
  
  const columns = [
    {
      dataField: "intituele",
      text: "Intitulé ",
      headerClasses: 'custom-table-column',
      formatter: (cell, row) => {
        return (
          <div>
            <img className='mx-1' style={{width:"34px", height:"34px"}} src={fichier} alt={row.intituele} width="50" height="50" />
            {
            selectedRow === row && selectedColumn === "intituele" && editMode ? (
              <form onSubmit={handleSubmit(handleUpdate)}>
              <input
            {...register("intituele")}
              />
              {/* <button type="submit">Enregistrer</button> */}
            </form>
            ):      <span onClick={() => openRowForm(row, "intituele")}> {row.intituele ? row.intituele : "Pas de description pour ce category"}</span>

           }

          </div>
        );
      },
      filter: textFilter({
        placeholder: "Filtrer par intitulé", // Texte du champ de filtrage
        style: {
          width: '100px', 
          padding: '5px', 
          fontSize: '9px',
          // marginLeft: '10px',
        },
      }),
    },
    {
      dataField: "date",
      text: "Date",
      headerClasses: 'custom-table-column',
      formatter: (cell, row) => {
        const formattedDate = row.date ? moment(row.date).format("DD/MM/YYYY") : "";
        return (
          <div>
            {
              selectedRow === row && selectedColumn === "date" && editMode ? (
              <form onBlur={handleSubmit(handleUpdate)}>
              <input
              type='date'
            {...register("date")}
              />
              {/* <button type="submit">Enregistrer</button> */}
            </form>
            ):      <span onClick={() => openRowForm(row, "date")}> {formattedDate ? formattedDate : "Pas de description pour ce category"}</span>

           }
          </div>
        )
      }
    },
    {
      dataField: "categorie_entree_sortie",
      text: "Catégorie  ",
      headerClasses: 'custom-table-column',
      formatter: (cell, row) => {
          if (row.categorieEntreeSortie) {
            return row.categorieEntreeSortie.name;
          } else {
            return "non defini";
          }
        // return (
        //   <div>
        //     {selectedRow === row && selectedColumn === "categorieEntreeSortie." && editMode ? (
        //       <form onSubmit={handleSubmit(handleUpdate)}>
        //         <select {...register("categories")}>
        //           {categoriesOption && categoriesOption.length > 0 ? (
        //             categoriesOption?.map((option, id) => (
        //               <option key={id} value={option}>{option}</option>
        //             ))
        //           ) : (
        //             <option value="">Aucune option de catégorie disponible</option>
        //           )}
        //         </select>
        //         <button type="submit">Enregistrer</button>
        //       </form>
        //     ) : (
        //       <span onClick={() => openRowForm(row, "categories" )}>{row.categories}</span>
        //     )
        //     }
        // </div>

        //     );
          },
      filter: selectFilter({
        options: categoriesOptions, // Utilisez les options que vous avez obtenues
        placeholder: "Sélectionnez une catégorie", // Texte du champ de filtrage
        style: {
          width: '100px', 
          padding: '5px', 
          fontSize: '9px',
        },
      }),

    },
    {
      dataField: "montant",
      text: "Montant",
      headerClasses: 'custom-table-column col-2',
        formatter: (cell, row) => {
        const commande = row;
        if (commande.categorieEntreeSortie?.type_transaction === 'sorties') {
          if (selectedRow === commande && selectedColumn === "montant" && editMode) {
            return (
              <form onSubmit={handleSubmit(handleUpdate)}>
                <input {...register("montant")} />
                {/* <button type="submit">Enregistrer</button> */}
              </form>
            );
          } else {
            return (
              <span onClick={() => openRowForm(commande, "montant")} style={{color:'rgba(221, 27, 27, 1)'}}>
                {commande.montant.toLocaleString() ? commande?.montant.toLocaleString()  : "Pas de description"} fcfa
              </span>
            );
          }
        } else {
          return null;
        }
      },
      filter: textFilter({
        placeholder: "Filtrer par montant",
        style: {
          width: '100px', 
          padding: '5px', 
          fontSize: '9px', 
        },
        filterValue: (cell, row) => row.categorie_entree_sortie.toString(), // Convertissez la valeur numérique en chaîne pour le filtrage
      }),
    },
    {
      dataField: "source_paiement",
      text: "Moyen de paiement",
      headerClasses: 'custom-table-column col-2',
      formatter: (cell, row) => {
        return (
          <div>
            {selectedRow === row && selectedColumn === "source_paiement" && editMode ? (
              <form onSubmit={handleSubmit(handleUpdate)}>
                <select {...register("source_paiement")} onBlur={handleSubmit(handleUpdate)}>
                {source_paiement && source_paiement.length > 0 ? (
                  source_paiement.map((option) => (
                    <option key={option.id} value={option.id}>{option.value}</option>
                  ))
                ) : (
                  <option value="">Aucune option de transactions disponible</option>
                )}

                </select>
                {/* <button type="submit">Enregistrer</button> */}
              </form>
            ) : (
              <span onClick={() => openRowForm(row, "source_paiement" )} style={{ cursor: 'pointer'}}>{row.source_paiement}</span>
            )}
          </div>

        );
      },
      filter: selectFilter({
        options: source_paiement, // Utilisez les options que vous avez obtenues
        placeholder: "Sélectionnez une paiement", // Texte du champ de filtrage
        style: {
          width: '100px', 
          padding: '5px', 
          fontSize: '9px',
        },
      }),
    },
    {
      dataField: "createdBy",
      text: "Par",
      headerClasses: 'custom-table-column col-1',
      formatter: (cell, row) => {
        if (row.user) {
          return row.user.fullName;
        } else {
          return "non defini";
        }
      },
    },
    {
      dataField: "lieux",
      text: "Lieu",
      headerClasses: 'custom-table-column col-2',
      formatter: (cell, row) => {
        return (
          <div>
            {selectedRow === row && selectedColumn === "lieux" && editMode ? (
              <form onSubmit={handleSubmit(handleUpdate)}>
                <select {...register("lieux")} onBlur={handleSubmit(handleUpdate)}>
                {lieux && lieux.length > 0 ? (
                  lieux.map((option) => (
                    <option key={option.id} value={option.id}>{option.value}</option>
                  ))
                ) : (
                  <option value="">Aucune option de transactions disponible</option>
                )}

                </select>
                {/* <button type="submit">Enregistrer</button> */}
              </form>
            ) : (
              <span onClick={() => openRowForm(row, "lieux" )} style={{ cursor: 'pointer'}}>{row.lieux}</span>
            )}
          </div>

        );
      },
      filter: selectFilter({
        options: lieux, // Utilisez les options que vous avez obtenues
        placeholder: "Sélectionnez un lieu", // Texte du champ de filtrage
        style: {
          width: '100px', 
          padding: '5px', 
          fontSize: '9px',
          marginLeft: '12px'
        },
      }),
    },
    {
        dataField: "action",
        text: "Actions",
        headerClasses: 'custom-table-column col-1',
        formatter: (cell, row) => {
          return (
            <>
              <div className="d-flex align-items-center">
                <BsEyeFill className=" btn-icon   me-2" size={17}
                   onClick={() => detailSortie(row)}  
                   style={{ cursor: 'pointer' }} />

                
                  {/* <RiEdit2Line className=" me-2" size={17}
                   style={{ cursor: 'pointer' }}/>  */}
               
                  <RiDeleteBinLine className="" size={17}
                   style={{ cursor: 'pointer' }}
                   onClick={() => openDeleteModal(row)}
                   /> {/* Icône de suppression */}

                   <FaHistory  className=" mx-2" size={17}
                   style={{ cursor: 'pointer' }}
                   onClick={() => historiqueSortie(row)}
                   />
               
              </div>
            </>
          );
        },
    },
  ];

  const refresEntreeList = () => {
    ApiController.entreesApi.getTransactionPrioprio(currentUser?.id)
      .then(() => {
      })
      .catch((err) => console.log(err));
  };

  //  ...fitre entre deux date
  const handleDateChange = (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
    // Mettez à jour les données filtrées en fonction des dates sélectionnées
    // Vous pouvez utiliser le même code de filtrage que dans votre composant précédent
    const updatedFilteredData = listTransactionsSortie.filter((item) => {
      const itemDate = new Date(item.date);
    
      if (!startDate && !endDate) {
        return true; // Si les dates de début et de fin ne sont pas définies, ne pas appliquer de filtrage
      }
    
      if (startDate && endDate) {
        return itemDate >= new Date(startDate) && itemDate <= new Date(endDate);
      }
    
      if (startDate) {
        return itemDate >= new Date(startDate);
      }
    
      if (endDate) {
        return itemDate <= new Date(endDate);
      }
    
      return true; // Par défaut, ne pas filtrer si aucune condition n'est remplie
    });
    
    setFilteredData(updatedFilteredData);
  };
// ...fin filtre entree deux date

  return (
    <>
      <ToastContainer className='mt-5'/>
      <AddEntreeSortie showModal={showModal} closeModal={closeModal} pageTypeTransaction="sorties" refresEntreeList={refresEntreeList}/>
      <DeleteEntree showModal={showDeleteModal} setShowModal={setShowDeleteModal}/>
        <div className="row rowsortie">
            <div className="col-12">
                <h4 className='sortieTitle'>Sorties</h4>
                {/* <p className='sortieDescription'>Lorem ipsum dolor sit amet consectetur</p> */}
            </div>
          </div>
          <div className="btns-entree">
                  <div className='btn-excel '>

                    <button className='mt-2 btn border' onClick={handleExport}>
                      <AiOutlineDownload />
                      
                      <select value={selectedFormat} onChange={e => setSelectedFormat(e.target.value)} style={{ marginLeft: '10px', border: 'none' }}>
                        <option value="">Exporter Excel / CSV</option>
                        <option value="excel">Excel</option>
                        <option value="csv">CSV</option>
                      </select>
                    </button>

                    <label className='mt-2 mx-1 btn border fileInput' htmlFor='fileInput'>
                      <AiOutlineUpload />
                      Importer Excel
                      <input
                        type='file'
                        id='fileInput'
                        accept='.csv, .xlsx, .xls'
                        onChange={importFromExcel}
                        style={{ display: 'none' }}
                      />
                    </label>
                    <button className=' mt-2 btn border' onClick={handleExportExemple}>
                      <AiOutlineDownload />
                      telecahrger le format Excel
                    </button>
                </div>
                    <div className='mt-2' >
                        <button className='ajoutEntree btn  text-light' onClick={openModal}>
                        <AiFillPlusCircle className="fs-4 " /> Ajouter une sortie</button>
                    </div>
            
                </div>
              <div className='mt-4'>
                  <DateFilter onDateChange={handleDateChange} />
                </div>
            <div className="mt-4">
            {isLoading ? (
                <div className="text-center">
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Chargement en cours...</span>
                  </Spinner>
                </div>
            ) : (
            // Affiche la table de données si loading est faux
            listTransactionsSortie?.length ? (
              <>
                <CustomBootstrapTable
                  keyField="id"
                  data={filteredData}
                  columns={columns}
                  filter={filterFactory()} // Activez le filtre global
                  // striped
                  // hover
                  condensed
                  wrapperClasses="table-wrapper" // Ajoutez cette classe
                />
              </>
            ) : <p>Pas de transactions disponible</p>
          )}
        </div>
    </>
  )
}

export default SortiePage