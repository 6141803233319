import React, {useState, useEffect} from 'react';
import '../../home/home.css';
import SearchBar from 'components/SearchBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from "react-redux";
import CustomBootstrapTable from "../../../components/tables/CustomBootstrapTable";
import ApiController from "../../../redux/apiController";
import { setEntreesList, filterEntreeBySearch } from "../../../redux/reducers/entreesSlice";
import moment from 'moment';
import fichier from '../../../img/Featured icon.png'
import 'react-toastify/dist/ReactToastify.css';
import { Spinner } from 'react-bootstrap';
import DateFilter from 'components/filtrEntreDeuxDates';



const ListeTransComptable = () => {
  const currentUser = useSelector(state => state.users?.currentUser)
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();


  const listTransactions = useSelector(state => state?.entreesSlice?.listEntrees?.data);

  const [setStartDate] = useState('');
  const [setEndDate] = useState('');
  const [filteredData, setFilteredData] = useState(listTransactions); // Créez un état pour stocker les données filtrées

  useEffect(() => {
    setLoading(true);
    ApiController.entreesApi.getTransactionPrioprio(currentUser?.id).then((res) => {
      dispatch(setEntreesList(res));
    }) .catch((error) => {
      console.error('Error fetching data:', error);
    })
    .finally(() => {
      setLoading(false); // Fin du chargement, que ce soit un succès ou une erreur
    });
  }, [dispatch,currentUser?.id]);
  
  const handleSearch = (searchValue) => {
    dispatch(filterEntreeBySearch(searchValue))
  };

  
// ...fitre entre deux date
const handleDateChange = (startDate, endDate) => {
  setStartDate(startDate);
  setEndDate(endDate);
  // Mettez à jour les données filtrées en fonction des dates sélectionnées
  // Vous pouvez utiliser le même code de filtrage que dans votre composant précédent
  const updatedFilteredData = listTransactions.filter((item) => {
    const itemDate = new Date(item.date);
  
    if (!startDate && !endDate) {
      return true; // Si les dates de début et de fin ne sont pas définies, ne pas appliquer de filtrage
    }
  
    if (startDate && endDate) {
      return itemDate >= new Date(startDate) && itemDate <= new Date(endDate);
    }
  
    if (startDate) {
      return itemDate >= new Date(startDate);
    }
  
    if (endDate) {
      return itemDate <= new Date(endDate);
    }
  
    return true; // Par défaut, ne pas filtrer si aucune condition n'est remplie
  });
  
  setFilteredData(updatedFilteredData);
};

  const columns = [
    {
      dataField: "intituele",
      text: "Intitulé ",
      headerClasses: 'custom-table-column',
      formatter: (cell, row) => {
        return (
          <div>
            <img className='mx-1' style={{width:"34px", height:"34px"}} src={fichier} alt={row.intituele} width="50" height="50" />
            {
              <span > {row.intituele ? row.intituele : "Pas de description pour ce category"}</span>
            }
          </div>
        );
      }
    },
    {
      dataField: "date",
      text: "Date",
      headerClasses: 'custom-table-column',
      formatter: (cell, row) => {
        const formattedDate = row.date ? moment(row.date).format("DD/MM/YYYY") : "";
        return (
          <div>
            {
              formattedDate
           }
          </div>
        )
      }
    },
    {

        dataField: "categorie_entree_sortie",
        text: "Categorie",
        formatter: (cell, row) => {
          if (row.categorieEntreeSortie) {
            return row.categorieEntreeSortie.name;
          } else {
            return "non defini";
          }
        },

      },
    {
      dataField: "source_paiement",
      text: "Moyen de paiement",
      headerClasses: 'custom-table-column col-2',
    },
    {
      dataField: "categorie_entree_sortie",
      text: "Type",
      headerClasses: 'custom-table-column col-2',
      formatter: (cell, row) => {
        const commande = row;
        if (commande.categorieEntreeSortie?.type_transaction === 'entrees') {
          return <p style={{color:'rgba(34, 253, 150, 1)'}}>credité</p>;
        }else if (commande.categorieEntreeSortie?.type_transaction === 'sorties') {
          return <p style={{color:'rgba(221, 27, 27, 1)'}}>debité</p>;
        }
        return null;
      }
      
    },
    {
      dataField: "montant",
      text: "Montant",
      headerClasses: 'custom-table-column col-2', 
      formatter: (cell, row) => {
        const commande = row;
        if (commande.categorieEntreeSortie?.type_transaction === 'entrees') {
            return (
              <span style={{color:'rgba(34, 253, 150, 1)'}}>
                {commande.montant.toLocaleString() ? commande.montant.toLocaleString()  : "Pas de description"} fcfa
              </span>
            );
      }
      else {
        
          return (
            <span style={{color:'rgba(221, 27, 27, 1)'}}>
              {commande.montant.toLocaleString() ? commande.montant.toLocaleString()  : "Pas de description"} fcfa
            </span>
          );
          }
        // return null
    }
    },
    {
      dataField: "createdBy",
      text: "Par",
      headerClasses: 'custom-table-column col-1',
      formatter: (cell, row) => {
        if (row.user) {
          return row.user.fullName;
        } else {
          return "non defini";
        }
      },
    },
    {
      dataField: "lieux",
      text: "Lieu",
      headerClasses: 'custom-table-column col-2',
     
    },
  
  ];


  return (
    <div className='container-fluid'>
      <h4 className='dash m-0'>Listes des transactions des comptables </h4>
      {/* <p className='lorem'>Lorem ipsum dolor sit amet consectetur</p> */}
    <div className="col-md-12 top-5">
    <div className="col-12 mt-3">
      <div className='searcch'>
          <div className=''>
          <SearchBar placeholder="Recherche par nom, montant..." onSearch={handleSearch} />
          </div>
          <div className='buton'>
          <button className='buton'>
            <FontAwesomeIcon icon={faFilter} className="filter-icon" />
            <span className='filtre'>Filtre</span>
          </button>
        </div>
      </div>
      <div className='mt-4'>
          <DateFilter onDateChange={handleDateChange} />
                </div>
      {/* table--------------- */}
         {/* Affichage du loader si loading est true */}
         <div className='mt-4'>
         {loading ? (
          <div className="text-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Chargement en cours...</span>
            </Spinner>
          </div>
        ) : (
          // Affiche la table de données si loading est faux
          listTransactions?.length ? (
            <>
              <CustomBootstrapTable
                data={filteredData}
                columns={columns}
                showPaginator={true}
              />
            </>
          ) : <p>Pas de transactions disponible</p>
        )}
        </div>
    </div>
    </div>
    </div>
  );
};


export default ListeTransComptable;
