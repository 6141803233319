import React, { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import './profil.css';
import profil from '../../img/profil.png';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import ApiController from '../../redux/apiController';
import { toast } from 'react-toastify';

const Profil = () => {
  const [visiblePassword, setVisiblePassword] = useState(false);
  const currentUser = useSelector(state => state.users?.currentUser);
  // const currentComptable = useSelector(state => state.comptableSplice?.currentComptable);
  // console.log(currentUser);
  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit, formState: { errors }, setValue } = useForm({
    defaultValues: {
      fullName: currentUser?.fullName || '',
      phone: currentUser?.phone || '',
      adresse: currentUser?.adresse || '',
      email: currentUser?.email || '',
      password: currentUser?.password || '',
    }
  });

  useEffect(() => {
    if (currentUser) {
      setValue('fullName', currentUser.fullName || '');
      setValue('phone', currentUser.phone || '');
      setValue('adresse', currentUser.adresse || '');
      setValue('email', currentUser.email || '');
      setValue('password', currentUser.password || '');
    }
  }, [currentUser, setValue]);

  const onSubmit = (data) => {
    setIsLoading(true); // Activer la loader
    ApiController.users?.updateUser(data, currentUser.id)
    .then(res => {
      console.log({res});
      if (res.status === 200) {
        toast.success('Votre profil a été modifié avec succès');
      } else {
        toast.error('Erreur lors de la modification du profil');
      }
    })
    .catch(error => {
      console.log(error.response); // Affiche la réponse détaillée du serveur
      toast.error('Une erreur s"est produite lors de la modification du profil');
    })
    .finally(() => {
      setIsLoading(false);
    });
  
  }

  return (
    <div className="container">
      <ToastContainer className="mt-5" />
      <div className="row">
        <div className="col-12">
          <h4 className="sortieTitle">Profil</h4>
          <p className="sortieDescription">Gérer votre profil</p>
        </div>
      </div>
      <div className="rounded bordure">
        <div className="row">
          <div className="" >
            <div className="panel">
              <img className="pic rounded-circle" src={profil} alt="profil" />
            </div>
            <div className="name">
              <small style={{fontSize:"15px"}}>{currentUser?.fullName} {currentUser?.status}</small>
            </div>
              <p className="profilDescription">Mettez à jour votre photo et vos détails personnels</p>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-md-6">
              <label className="labels">Prénom Nom</label>
              <input
                type="text"
                className="form-control inputes"
                name="fullName"
                {...register('fullName')}
              />
              {errors.fullName && <span className="error">Ce champ est requis.</span>}
            </div>
            <div className="col-md-6">
              <label className="labels">Téléphone</label>
              <input
                type="number"
                className="form-control inputes"
                name="phone"
                {...register('phone')}
              />
              {errors.phone && <span className="error">Ce champ est requis.</span>}
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-6">
              <label className="labels">E-mail</label>
              <input
                type="email"
                className="form-control inputes"
                name="email"
                {...register('email')}
              />
              {errors.email && <span className="error">Ce champ est requis.</span>}
            </div>
            <div className="col-md-6">
              <label className="labels">Adresse</label>
              <input
                type="text"
                className="form-control inputes"
                name="adresse"
                {...register('adresse')}
              />
              {errors.adresse && <span className="error">Ce champ est requis.</span>}
            </div>
          </div>
          <div className="row mt-4">
           
            <div className="col-md-6">
              <label className="labels">mot de passe</label>
              <input
                type="password"
                className="form-control inputes"
                name="password"
                {...register('password')}
              />
              {errors.password && <span className="error">Ce champ est requis.</span>}
            </div>
          </div>
          <div
            style={{
              position: 'relative',
              left: '43%',
              transform: 'translateY(-100%)',
              height: 30.5,
              width: 50,
              backgroundColor: 'transparent',
              color: '#333',
              display: 'grid',
              placeItems: 'center',
              cursor: 'pointer',
              border: 'none'
            }}
            className=" form-control px-2"
            onClick={() => setVisiblePassword((v) => !v)}
          >
            {visiblePassword ? (
              <FiEye color="#333" />
            ) : (
              <FiEyeOff color="#333" />
            )}
          </div>
          {/* Buttons */}
          <div style={{ display: 'flex', justifyContent: 'flex-end',flexWrap: "wrap", marginTop: "15px" }}>
            <button
              type="button"
              style={{
                backgroundColor: '#637381',
                color: '#FFFFFF',
                borderRadius: '5px',
                fontWeight: '600',
                fontSize: '14px',
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                padding: '10px 50px',
                border: 'none',
                fontStyle: 'normal',
                fontFamily: 'Montserrat',
                margin: '1px',
                width:"auto"
              }}
            //   onClick={() => <Navigate to={back}  />}
            >
              Annuler
            </button>
            <button
              type="submit"
              style={{
                backgroundColor: '#01ADBA',
                color: '#FFFFFF',
                borderRadius: '5px',
                fontWeight: '600',
                fontSize: '14px',
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                padding: '10px 40px',
                border: 'none',
                fontStyle: 'normal',
                fontFamily: 'Montserrat',
                margin:"1px",
                width:"auto"
              }}
              disabled={isLoading}
            >
              {isLoading ? (
                <div className="spinner-border text-light" role="status">
                </div>
              ) : (
                'Soumettre'
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Profil;
