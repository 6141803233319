import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { generateChartDataMois } from '../../components/graphes/grapheEntreeSortieMois';
import 'react-tabs/style/react-tabs.css';
import { generateChartDataSemaine } from '../../components/graphes/grapheEntreeSortieSemain';
import { generateChartDataTrimestre } from '../../components/graphes/grapheEntreeSortieTrimestre';
import { generateChartDataParCategorieSortie } from 'components/graphes/statistiqueParCategorieSortie';
import { generateChartDataParCategorieEntree } from 'components/graphes/statistiqueParCategoriEntree';
import { Bar,Pie } from 'react-chartjs-2'; // Nous n'utilisons que le composant "Bar" de react-chartjs-2
import 'chartjs-plugin-datalabels';
import { Chart, registerables  } from 'chart.js';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import './statistique.css'
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { generateChartDataPeriode } from 'components/graphes/grapheParPeriodde';
import moment from 'moment';
import { generateChartDataSemestre } from 'components/graphes/grapheEntreeSortieTrimestre copy';
import { generateChartDatacategoriePourcentage } from 'components/graphes/grapheHorizontalCategorie';
// eslint-disable-next-line no-unused-vars
import { logDOM } from '@testing-library/react';

Chart.register(...registerables );
Chart.register(ChartDataLabels);

const Statistique = () => {
  const [chartDataMois, setChartDataMois] = useState(null);
  const [chartDataSemaine, setChartDataSemaine] = useState(null);
  const [chartDataTrimestre, setChartDataTrimestre] = useState(null);
  const [chartDataSemestre, setChartDataSemestre] = useState(null);
  const [chartDataCategorieSortie, setChartDataCategorieSortie] = useState(null);
  const [chartDataCategorieEntree, setChartDataCategorieEntree] = useState(null);
  const [chartDataCategoriePourcentage, setChartDataCategoriePourcentage] = useState(null);
  const [chartType, setChartType] = useState('sorties');



  // periode par defaut
  const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
  const endOfMonth = moment().endOf('month').format('YYYY-MM-DD');

  const [selectedYear, setSelectedYear] = useState(moment().format('YYYY'));
  const [selectedMonth, setSelectedMonth] = useState(moment().format('MMMM'));
  const [chartDataPeriode, setChartDataPeriode] = useState(null);
  // const listTransactions = useSelector((state) => state?.entreesSlice?.listEntrees?.data);
  const listTransactions = useSelector((state) => state?.entreesSlice?.listEntrees?.data);
  const [startDate, setStartDate] = useState(startOfMonth);
  const [endDate, setEndDate] = useState(endOfMonth);

  useEffect(() => {
    if (listTransactions) {
      const generatedDataMois = generateChartDataMois(listTransactions, selectedYear);
      setChartDataMois(generatedDataMois);

      const generatedDataSemaine = generateChartDataSemaine(listTransactions, selectedYear);
      setChartDataSemaine(generatedDataSemaine);

      const generatedDataTrimestre = generateChartDataTrimestre(listTransactions, selectedYear);
      setChartDataTrimestre(generatedDataTrimestre);

      const generatedDataSemestre = generateChartDataSemestre(listTransactions, selectedYear);
      setChartDataSemestre(generatedDataSemestre);

      const generatedDataParCategorieSortie = generateChartDataParCategorieSortie(listTransactions, selectedYear);
      setChartDataCategorieSortie(generatedDataParCategorieSortie);

      const generatedDataParCategorieEntree = generateChartDataParCategorieEntree(listTransactions, selectedYear);
      setChartDataCategorieEntree(generatedDataParCategorieEntree);

      const generatedDataPeriode = generateChartDataPeriode(listTransactions, startDate, endDate);
      setChartDataPeriode(generatedDataPeriode);
      // console.log({generatedDataPeriode});


      const generatedDataParCategorieP = generateChartDatacategoriePourcentage(listTransactions, selectedYear, chartType, selectedMonth);
      setChartDataCategoriePourcentage(generatedDataParCategorieP);
      // console.log(generatedDataParCategorieP);

      // Les données de la période sont déjà générées lorsque l'utilisateur soumet le formulaire
    }

  }, [listTransactions, startDate, endDate, selectedYear, chartType, selectedMonth]);


  const handleSubmit = (e) => {
    e.preventDefault();
    // Générer les données pour la période spécifiée
     // Mettre à jour les données du graphe lorsque l'utilisateur soumet le formulaire
     const generatedDataPeriode = generateChartDataPeriode(listTransactions, startDate, endDate);
     setChartDataPeriode(generatedDataPeriode);
  };

  const generateLegend = () => {
    return (
      <ul className="chart-legend d-flex">
        <li><span className="square-entrees"></span> Entrées</li>
        <li className='mx-4'><span className="square-sorties "></span> Sorties</li>
      </ul>
    );
  };
  
  const options = {
    // ... Autres options du graphe ...
    plugins: {
      legend: {
        display: true,
        position: 'right',
      },
      datalabels: {
        color: '#000000', // Couleur du texte des étiquettes
        anchor: 'top', // Alignement de l'étiquette
        align: 'end', // Alignement de l'étiquette
       
      },
    },
  };

  const handleChartTypeChange = (e) => {
    // Update the selected chart type when the dropdown value changes
    setChartType(e.target.value);
  };

  return (
    <div className="container-fluid">
    {/* ... */}
    <div className="col-12 top-5">
      {listTransactions?.length ? (
        <>
          <div className="row">
          <div className="col-12">
                <h4 className='sortieTitle'>Les Statistiques des transactions</h4>
                 <p className='statDescription'>Votre résumé des transactions et votre activité actuelle.</p>
          </div>
            <div className="col-12 chart-container">
              <Tabs className='longueur-graphe'>
                <TabList className=" list-tab">
                  <div className='custom-tab-list'>
                    <Tab>Par Semaine</Tab>
                    <Tab>Par Mois</Tab>
                    <Tab>Par Trimestre</Tab>
                    <Tab>Par Semestre</Tab>
                    <Tab>Par Categorie / Entrée</Tab>
                    <Tab>Par Categorie / Sortie</Tab>
                  </div>
                  <div className='selected-date'>
                    <select  value={selectedYear} onChange={(e) => setSelectedYear(e.target.value)}>
                            <option value=""></option>
                            <option value="2023">2023</option>
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                            <option value="2026">2026</option>
                            <option value="2027">2027</option>
                            <option value="2028">2028</option>
                            <option value="2029">2029</option>
                            <option value="2030">2030</option>
                            {/* Ajoutez d'autres années si nécessaire */}
                    </select>
                  </div>
                </TabList>

                <TabPanel className="chart-tab-panel">
                  {chartDataSemaine ? (
                    <Bar data={chartDataSemaine}  />
                  ) : (
                    <p>Chargement du graphique...</p>
                  )}
                </TabPanel>
                
                <TabPanel>
                  {chartDataMois ? (
                    <Bar data={chartDataMois} options={generateLegend()}/>
                  ) : (
                    <p>Chargement du graphique...</p>
                  )}
                </TabPanel>

                <TabPanel>
                  {chartDataTrimestre ? (
                    <Bar data={chartDataTrimestre}  />
                  ) : (
                    <p>Chargement du graphique...</p>
                  )}
                </TabPanel>

                <TabPanel>
                  {chartDataSemestre ? (
                    <Bar data={chartDataSemestre}  />
                  ) : (
                    <p>Chargement du graphique...</p>
                  )}
                </TabPanel>

                <TabPanel>
                  {chartDataCategorieEntree? (
                    <Bar data={chartDataCategorieEntree}  />
                  ) : (
                    <p>Chargement du graphique...</p>
                  )}
                </TabPanel>

                <TabPanel>
                  {chartDataCategorieSortie? (
                    <Bar data={chartDataCategorieSortie}  />
                  ) : (
                    <p>Chargement du graphique...</p>
                  )}
                </TabPanel>
              </Tabs>

              {/* par periode  */}
              <div className='row mt-3'>
               <div className='col-md-6'>
                <form onSubmit={handleSubmit} style={{fontSize:"15px", marginBottom:"5px",  display: 'flex'}} >
                  <div className='me-2'>
                  <label className='d-flex'>Date de début: </label>
                  <input className="custom-select" type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                  </div>
                  <div>
                  <label className='d-flex'>Date de fin:</label>
                    <input className="custom-select" type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                    </div>
                  {/* <button type="submit">Afficher le graphe</button> */}
                </form>
                <h6 className='p-3 bar'>Situation Financière</h6>
                {chartDataPeriode ? (
                   <div className="chartContainerPie">
                    <Pie data={chartDataPeriode} options={options} className=''/>
                  </div>
                ) : (
                  <p>Chargement du graphique...</p>
                )}
              </div>
              <div className='col-md-6'>
                <form onSubmit={handleSubmit} style={{fontSize:"15px", marginBottom:"5px",  display: 'flex'}} >
                  <div className='me-2'>
                  <label className='d-flex'>Date de début: </label>
                  {/* <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} /> */}
                  </div>
                  {/* <div>
                  <label className='d-flex'>Date de fin:</label>
                    <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                    </div> */}
                  {/* <button type="submit">Afficher le graphe</button> */}
                </form>
                <div className='select-container'>
                  <select className="custom-select" value={chartType} onChange={handleChartTypeChange}>
                      <option value="sorties">Sorties</option>
                      <option value="entrees">Entrées</option>
                    </select>
                    <select className="custom-select" value={selectedMonth} onChange={(e) => setSelectedMonth(e.target.value)}>
                      <option value=""></option>
                      {moment.months().map((month, index) => (
                        <option key={index} value={month}>
                          {month}
                        </option>
                      ))}
                    </select>
                  </div>
                <h6 className='p-3 bar'>Situation Financière</h6>
                {chartDataCategoriePourcentage ? (
                        <Bar data={chartDataCategoriePourcentage} 
                        options={{ indexAxis: 'y',
                        scales: {
                          x: {
                            position: 'top', // Place l'axe des abscisses en haut du graphe
                            min: 0,
                            max: 100, // Fixe l'axe des abscisses à 100%
                            ticks: {
                              callback: (value) => `${value}%`, // Ajoute le symbole de pourcentage à gauche des chiffres
                            },
                          },
                          y: {
                            beginAtZero: true, // Commence l'axe des ordonnées à 0
                          },
                        },
                         plugins: {
                          legend: {
                            display: true,
                            position: 'bottom',
                          },
                          datalabels: {
                            color: '#000000',
                            anchor: 'end',
                            align: 'end',
                            formatter: (value) => `${value}%`, // Ajouter le symbole pourcentage
                            padding: {
                              left: 10, // Ajouter un espace à gauche des étiquettes de données pour le symbole pourcentage
                            },
                          },
                        }, }} />
                      ) : (
                        <p>Chargement du graphique...</p>
                      )}
              </div>
              </div>
              </div>
          </div>
        </>
      ) : (
        <p>Pas de transactions disponibles</p>
      )}
    </div>
    {/* ... */}
  </div>
  );
};

export default Statistique;
